import faceIconHappySrc from "shared/assets/Icons/face-happy.svg";
import faceIconNeutralSrc from "shared/assets/Icons/face-neutral.svg";
import faceIconSadSrc from "shared/assets/Icons/face-sad.svg";
import faceIconVeryHappySrc from "shared/assets/Icons/face-very-happy.svg";
import faceIconVerySadSrc from "shared/assets/Icons/face-very-sad.svg";
import { Chart as ChartJS } from "chart.js";

const moodImages = [faceIconVerySadSrc, faceIconSadSrc, faceIconNeutralSrc, faceIconHappySrc, faceIconVeryHappySrc].map((src) => {
  const image = new Image();
  image.src = src;
  return image;
});

const getImageForMood = (mood: number): HTMLImageElement | undefined => (mood > 0 && mood <= moodImages.length ? moodImages[mood - 1] : undefined);

export const yAxisMoodImagePlugin = {
  id: "yAxisMoodImagePlugin",
  afterDraw: function (chart: ChartJS) {
    const imageSize = 30;
    const xPosition = chart.scales.x.left - (imageSize + 15);
    chart.scales.y.ticks.forEach((tick, index) => {
      const mood = getImageForMood(tick.value);
      if (mood) {
        const yPosition = chart.scales.y.getPixelForTick(index) - imageSize / 2;
        chart.ctx.drawImage(mood, xPosition, yPosition, imageSize, imageSize);
      }
    });
  },
};
