import Spinner from "@brighthr/component-spinner";
import { ReactElement } from "react";

export type ViewLoadingProps = {
  label?: string;
  heightClass?: string;
};

const defaultLabel = "processing request";

export const ViewLoading = ({ label = defaultLabel, heightClass }: ViewLoadingProps): ReactElement => {
  const defaultHeight = "h-screen";
  return (
      <div className={`flex ${heightClass ?? defaultHeight} items-center justify-center`}>
        <Spinner ariaLabel={label} className="stroke-primary-700" size="xxl" />
      </div>
  );
};

export default ViewLoading;
