import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const toastNotify = (message: string, type: "info" | "success" | "warning" | "error") => {
  toast(message, { type, toastId: `${message}-${type}`, className: "w-full" });
};

export const Toaster = () => {
  return (
    <ToastContainer
      theme="colored"
      position={"top-left"}
      pauseOnHover={false}
      pauseOnFocusLoss={false}
      draggable={false}
      limit={3}
      autoClose={3000}
    />
  );
};
