import { ComponentType } from "react";
import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";
import { TrackerProgressDonut } from "UIPalette/TrackerProgress/TrackerProgress";
import { TrackerType } from "core/state/Trackers/TrackerType";
import { useNavigate } from "react-router-dom";
import { useTrackerContext } from "core/state/Trackers/TrackerContext";

export type TrackerProgressTileProps = {
  trackerType: TrackerType;
  caption: string;
  trackerPagePath: string;
  Icon: ComponentType<React.SVGProps<SVGSVGElement>>;
  wrapInCard?: boolean;
  size?: "sm" | "lg" | "md";
};

type SizeWidths = { [K in Exclude<TrackerProgressTileProps["size"], undefined>]: string };

const sizeWidth: SizeWidths = {
  sm: "",
  md: "w-[5rem]",
  lg: "",
};

export const TrackerProgressTile = ({ trackerType, Icon, caption, trackerPagePath, wrapInCard, size = "lg" }: TrackerProgressTileProps) => {
  const navigate = useNavigate();
  const { getCurrentValue, getGoal } = useTrackerContext();

  const current = getCurrentValue(trackerType);
  let goal = getGoal(trackerType);
  const percentage = current.percentageOf(goal);

  function renderBody() {
    const body = (
      <div className="flex flex-col items-center">
        <div className={`flex-grow ${sizeWidth[size]}`}>
          <TrackerProgressDonut TrackerIcon={Icon} percentage={percentage} size={size} />
        </div>
        <div className="text-brand-aa">{caption}</div>
      </div>
    );
    if (wrapInCard ?? true) {
      return <RoundedCard>{body}</RoundedCard>;
    }
    return body;
  }

  return <button onClick={() => navigate("/health-hub/" + trackerPagePath)}>{renderBody()}</button>;
};
