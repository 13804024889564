import React from "react";

interface ClientLogoImageProps {
  className?: string;
  clientCode: string;
  imageFilename: string;
  alt?: string;
}

const ClientLogoImage = (props: ClientLogoImageProps) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL ?? "";
  return <img className={`w-100 m-auto ${props.className}`} src={`${baseUrl}Images/${props.clientCode}/${props.imageFilename}`} alt={props.alt} />;
};

export default ClientLogoImage;
