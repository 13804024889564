import { TrackerType } from "./TrackerType";

export default abstract class TrackerSync {
  public static async requestDataSync() {
    await window.flutter_inappwebview?.callHandler("syncHealthData");
  }

  public static async isSyncEnabled(trackerType: TrackerType) {
    var syncedTrackers: string[] = await window.flutter_inappwebview?.callHandler("getSyncedHealthTrackers");
    return syncedTrackers.some((pt) => pt.toLowerCase() === TrackerType[trackerType].toLowerCase());
  }
}
