import { t } from "i18next";
import { ReactComponent as HealthHubIcon } from "shared/assets/Icons/health-hub.svg";
import { ReactComponent as FilledHealthHubIcon } from "shared/assets/Icons/health-hub-filled.svg";
import { useNavigate } from "react-router-dom";

export const BottomNavHealthHubButton = ({ active }: { active: boolean }) => {
  const navigateHook = useNavigate();

  return (
    <button
      className="flex flex-col items-center text-brand font-bold text-[14pt] mx-1"
      aria-label={t("navigation.healthHubNavigationLabel")}
      onClick={() => {
        navigateHook("/health-hub");
      }}
    >
      {active ? <FilledHealthHubIcon /> : <HealthHubIcon />}
      <p className="mt-1">{t("navigation.healthHubButton")}</p>
    </button>
  );
};
