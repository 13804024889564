import { UserTrackerValue } from "shared/request/myHealthyAdvantageApi";
import { colours } from "../../../../../../../../../theme";
import { userLocale } from "shared/core/locale";

export enum ChartDayOffsets {
  week = -6,
  month = -30,
  year = -364,
}

function* datesFromRange(start: Date, end: Date): Generator<Date> {
  const current = new Date(start);
  const last = new Date(end);

  while (current <= last) {
    yield new Date(current);
    current.setDate(current.getDate() + 1);
  }
}

const group = function <T>(arr: T[], size: number): T[][] {
  return arr.reduce((result: T[][], value: T, index: number) => {
    const groupIndex = Math.floor(index / size);
    result[groupIndex] = result[groupIndex] || [];
    result[groupIndex].push(value);
    return result;
  }, []);
};

export const groupAndTakeFirst = function <T>(arr: T[], size: number): T[] {
  return group(arr, size).map((group) => group[0]);
};

export const groupAndAverage = function (arr: (number | null)[], size: number): (number | null)[] {
  return group(arr, size).map((group) => {
    const sum = group.reduce((acc, value) => {
      if (acc == null && value == null) {
        return null;
      }
      return (acc ?? 0) + (value ?? 0);
    }, null);

    const nonNullGroupSize = group.map<number>((value) => (value === null ? 0 : 1)).reduce((acc, value) => acc + value, 0);

    return sum == null ? null : sum / nonNullGroupSize;
  });
};

export function generateChartData(data: UserTrackerValue[], dateRange: { startDate: Date; endDate: Date }, view: ChartDayOffsets) {
  const formatDate = (date: Date): string => {
    switch (view) {
      case ChartDayOffsets.week:
        return date.toLocaleString(userLocale, { weekday: "short" });
      case ChartDayOffsets.year:
        const yearMonth = date.toLocaleString(userLocale, { month: "short" });
        const year = date.toLocaleString(userLocale, { year: "2-digit" });
        return `${yearMonth} ${year}`;
      default:
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        return `${day}-${month}`;
    }
  };

  let labels: string[] = [];
  let values: (number | null)[] = [];

  const range = Array.from(datesFromRange(dateRange.startDate, dateRange.endDate));
  labels = range.map((date) => formatDate(date));
  values = range.map((date) => data.find((value) => date.isSameDateAs(value.date))).map((found) => (found !== undefined ? found.value : null));

  if (view === ChartDayOffsets.year) {
    labels = groupAndTakeFirst(labels, 30);
    values = groupAndAverage(values, 30);
  }

  return {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: function (context: { chart: any }) {
          const { chart } = context;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }
          return getGradient(ctx, chartArea);
        },
        borderRadius: Number.MAX_VALUE,
        borderSkipped: false,
        tension: 0.2,
      },
    ],
  };
}

function getGradient(ctx: { createLinearGradient: Function }, chartArea: { right: number; left: number; bottom: number; top: number }) {
  let width, height, gradient;

  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (!gradient || width !== chartWidth || height !== chartHeight) {
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, colours["brand"]);
    gradient.addColorStop(1, colours["m-brand"]);
  }

  return gradient;
}
