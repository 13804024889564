import { MiniHealthCheckSummary } from "shared/request/myHealthyAdvantageApi";
import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";
import { TitleCard } from "UIPalette/TitleCard/TitleCard";
import { ReactComponent as RightChevron } from "shared/assets/Icons/right.svg";
import "shared/extensions/string";
import { useTranslation } from "react-i18next";

export type MiniHealthChecksListProps = {
  title: string;
  checks: MiniHealthCheckSummary[];
  showQuestionCount: boolean;
  handleClick: (planId: string) => void;
};

export const MiniHealthChecksList = ({ title, checks, showQuestionCount, handleClick }: MiniHealthChecksListProps) => {
  const { t } = useTranslation();
  return (
    <TitleCard title={title}>
      {checks.map((check) => {
        return (
          <div key={`check-${check.id}`} className="p-1">
            <button className="text-left w-full" onClick={() => handleClick(check.id)}>
              <RoundedCard>
                <div>
                  <h4 className="cardTitle inline-block">{check.name}</h4>
                  <ul className="inline-block pl-10">
                    <li>
                      {showQuestionCount
                        ? t("miniHealthChecks.numberOfQuestions", { questionCount: check.questionCount })
                        : t("miniHealthChecks.progressCompleted")}
                    </li>
                  </ul>
                </div>
                <div className="flex items-center mb-4">
                  <p className="mr-4 grow">{check.description}</p>
                  <div>
                    <RightChevron className="text-grey" height="24" width="12" />
                  </div>
                </div>
              </RoundedCard>
            </button>
          </div>
        );
      })}
    </TitleCard>
  );
};
