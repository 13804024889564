import { Link, useLocation } from "react-router-dom";

import { Hideable } from "shared/UI/Hideable/Hideable";
import { useBreadcrumbContext } from "UIPalette/AppLayout/Components/Breadcrumbs/BreadcrumbContext";
import { useTranslation } from "react-i18next";

interface ParsedSegment {
  path: string;
  displayText: string;
}

const Breadcrumbs = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { breadcrumbTitle } = useBreadcrumbContext();

  const separator = "/";
  const upperCaseWords = t("breadcrumbs.upperCaseWords", { returnObjects: true });
  const lowerCaseWords = t("breadcrumbs.lowerCaseWords", { returnObjects: true });

  const pathSegments = pathname?.split(separator).filter((segment) => segment.trim().length > 0) ?? [];
  const parsedSegments = pathSegments.map((segment, index) => {
    return {
      path: getSegmentPath(index),
      displayText: toDisplayText(segment),
    } as ParsedSegment;
  });

  if (breadcrumbTitle) {
    replaceLastSegment(breadcrumbTitle);
  }

  function replaceLastSegment(text: string) {
    parsedSegments[parsedSegments.length - 1].displayText = toDisplayText(text);
  }

  function isLastSegment(index: number): boolean {
    return index === parsedSegments.length - 1;
  }

  function isFirstSegment(index: number): boolean {
    return index === 0;
  }

  function getSegmentPath(index: number): string {
    return separator + pathSegments.slice(0, index + 1).join(separator);
  }

  function toDisplayText(segment: any) {
    if (isNaN(segment)) {
      return segment
        .split("-")
        .map((word: string) => {
          if (lowerCaseWords.includes(word.toLowerCase())) {
            return word.toLowerCase();
          }
          if (upperCaseWords.includes(word.toUpperCase())) {
            return word.toUpperCase();
          }
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
    }
    return "";
  }

  function renderSegment(segment: ParsedSegment, index: number) {
    const isFirst = isFirstSegment(index);

    if (isLastSegment(index)) {
      return (
        <span data-testid="last-breadcrumb" className={`breadcrumb ${isFirst ? "first-breadcrumb" : ""}`} key={segment.path}>
          {segment.displayText}
        </span>
      );
    }

    return (
      <span key={segment.path}>
        <Link to={segment.path} className={`breadcrumb ${isFirst ? "first-breadcrumb" : ""}`}>
          {segment.displayText}
        </Link>
        <span className="mx-1 breadcrumb">{separator}</span>
      </span>
    );
  }

  return (
    <div className="hidden md:block text-[14pt] font-bold">
      <Hideable hidden={parsedSegments.length <= 1}>
        <div data-testid="breadcrumbs">{parsedSegments.map((segment, index) => renderSegment(segment, index))}</div>
      </Hideable>
    </div>
  );
};

export default Breadcrumbs;
