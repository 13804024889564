import { WisdomModule } from "core/Modules";
import { useUserContext } from "core/state/UserContext";
import { Navigate, Outlet } from "react-router-dom";

type ProtectedModuleRouteProps = {
  wisdomModule: WisdomModule;
};

export const ProtectedModuleRoute = ({ wisdomModule }: ProtectedModuleRouteProps) => {
  const { isPermitted } = useUserContext();

  if (!isPermitted(wisdomModule)) {
    return <Navigate to={"/home"} />;
  }

  return <Outlet />;
};
