import { useEffect, useState } from "react";
import { loadAudio, pauseAudio, playAudio, seekAudio, setAudioSeekState } from "core/javascriptChannels";
import { AudioPlayerControlBar } from "./AudioPlayerControlBar";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import { t } from "i18next";

type MediaItem = {
  src: string;
  title: string;
};

export const MobileAudioPlayer = ({ mediaItem }: { mediaItem: MediaItem }) => {
  const [isInitialised, setIsInitialised] = useState(false);
  const [playTime, setPlayTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isSeeking, setIsSeeking] = useState(false);
  const [error, seterror] = useState(false);
  const hasAudioFinished = (playTime: number, duration: number) => playTime >= duration && duration > 0;

  const updateProgressCallback = (currentProgress: number) => {
    setPlayTime(currentProgress);
    setIsInitialised(true);
    if (currentProgress >= duration) {
      setIsPlaying(false);
    }
  };

  if (window.updateAudioProgress === undefined && duration > 0) {
    window.updateAudioProgress = updateProgressCallback;
  }

  useEffect(() => {
    async function load() {
      var audioPlayerState = await loadAudio(mediaItem);

      if (audioPlayerState === undefined) {
        seterror(true);
        return;
      }
      setDuration(audioPlayerState.durationInSeconds);
      setPlayTime(audioPlayerState.positionInSeconds);

      const playing = audioPlayerState.isPlaying && !hasAudioFinished(audioPlayerState.positionInSeconds, audioPlayerState.durationInSeconds);
      setIsPlaying(playing);
      setIsInitialised(true);
    }

    load();

    return () => (window.updateAudioProgress = undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function updateSeekAfterValueSet() {
      if (isSeeking === false && isInitialised) {
        seekAudio(playTime);
      }
    }

    updateSeekAfterValueSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSeeking]);

  return (
    <>
      <div className="pt-5" key={mediaItem.src}>
        <AudioPlayerControlBar
          disabled={duration === 0}
          position={duration > 0 ? playTime / duration : 0}
          duration={duration}
          isPlaying={isPlaying}
          isLoading={!isInitialised}
          play={async () => {
            setIsPlaying(true);

            if (hasAudioFinished(playTime, duration)) {
              setPlayTime(0);
              await seekAudio(0);
            }
            await playAudio();
          }}
          pause={async () => {
            setIsPlaying(false);
            await pauseAudio();
          }}
          onSeekMouseDown={() => {
            setIsSeeking(true);
            setAudioSeekState(true);
          }}
          onSeekMouseUp={() => {
            setIsSeeking(false);
            setAudioSeekState(false);
          }}
          onSeekChange={(position) => {
            const currentPlayTime = Math.ceil(duration * position);
            setPlayTime(Math.min(duration, currentPlayTime));
          }}
        />
      </div>
      {error && <ErrorAlert content={t("viewArticle.player.loadingError")} />}
    </>
  );
};
