import "./youTubeVideo.css";
export const YouTubeVideo = (id:any) => {
  return (
    <iframe
      className="youTubeVideo"
      src={`https://www.youtube.com/embed/${id.id}`}
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    ></iframe>
  );
};
