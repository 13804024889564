export enum ReportingEventType {
  Registrations = 1,
  Login = 2,
  SupportRequestPhone = 3,
  SupportRequestLiveChat = 4,
  SupportRequestVideoCall = 5,
  SupportRequestRequestCallback = 6,
  MiniHealthCheck = 7,
  Trackers = 8,
  FourWeekPlans = 9,
  Preferences = 10,
  MoodTracker = 11
}