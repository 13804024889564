import { useListFourWeekPlansFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { useTranslation } from "react-i18next";
import { FourWeekPlanList } from "UIPalette/FourWeekPlanList/FourWeekPlanList";
import { useUserContext } from "core/state/UserContext";
import WisdomModules from "core/Modules";
import { ViewStateWrapper } from "UIPalette/ViewStateWrapper/ViewStateWrapper";

export type TrackerFourWeekPlansProps = {
  planIdsFilter: string[];
};

export const TrackerFourWeekPlans = ({ planIdsFilter }: TrackerFourWeekPlansProps) => {
  const { t } = useTranslation();
  const { data, isLoading, error, mutate } = useListFourWeekPlansFunctionRequest({ planIds: planIdsFilter });
  const { isPermitted } = useUserContext();

  if (!isPermitted(WisdomModules.fourWeekPlan)) {
    return null;
  }

  const plans = (data && data.active.concat(data.available)) ?? [];

  return (
    <ViewStateWrapper loading={isLoading} error={!!error} errorMessage={t("healthHub.fourWeekPlans.error")} onRetry={mutate}>
      <FourWeekPlanList title={t("healthHub.fourWeekPlans.heading")} plans={plans} />
    </ViewStateWrapper>
  );
};
