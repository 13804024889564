import { useEffect, useState } from "react";
import { ILiveAgentApi } from "./liveagent";

export type LiveChatInitFunction = (api: ILiveAgentApi) => void;

export type UseLiveChatResult = {
  isChatAvailable: boolean;
  chatButtonId: string;
  api: ILiveAgentApi | undefined;
};

export const useLiveChatApi = (initialiseChat: LiveChatInitFunction): UseLiveChatResult => {
  const scriptUrl = process.env.REACT_APP_LIVE_CHAT_SCRIPT_URL!;
  const initScriptUrl = process.env.REACT_APP_LIVE_CHAT_INIT_SCRIPT_URL!;
  const deploymentId = process.env.REACT_APP_LIVE_CHAT_DEPLOYMENT_ID!;
  const organisationId = process.env.REACT_APP_LIVE_CHAT_ORGANISATION_ID!;
  const buttonId = process.env.REACT_APP_LIVE_CHAT_BUTTON_ID!;

  const salesforceLiveChatScriptId = "salesforceLiveChatScript";
  const salesforceLiveChatScriptStateTrackerId = "salesforceLiveChatScriptStateTracker";
  const existingScript = document.getElementById(salesforceLiveChatScriptId);
  if (!existingScript) {
    const trackerHiddenParent = document.createElement("div");
    trackerHiddenParent.className = "hidden";
    const tracker = document.createElement("div");
    tracker.id = salesforceLiveChatScriptStateTrackerId;
    tracker.innerText = "Chat active";
    tracker.style.display = "none";
    trackerHiddenParent.appendChild(tracker);
    document.body.appendChild(trackerHiddenParent);

    const script = document.createElement("script");
    script.id = salesforceLiveChatScriptId;
    script.async = true;
    script.src = scriptUrl;
    script.onload = () => {
      salesforceLiveChatScriptLoaded();
    };
    document.body.appendChild(script);
  }

  const salesforceLiveChatScriptLoaded = () => {
    const { liveagent } = window;
    if (liveagent) {
      liveagent.enableLogging();
      liveagent.init(initScriptUrl, deploymentId, organisationId);

      if (!window._laq) {
        window._laq = [];
      }

      window._laq.push(() => {
        initialiseChat(liveagent);
        liveagent.showWhenOnline(buttonId, document.getElementById(salesforceLiveChatScriptStateTrackerId)!);
      });
    }
  };

  const calculateIfChatIsAvailable = (): boolean => {
    const salesforceLiveChatScriptStateTracker = document.getElementById(salesforceLiveChatScriptStateTrackerId);
    return salesforceLiveChatScriptStateTracker?.style.display === "";
  };

  const [isChatAvailable, setIsChatAvailable] = useState(calculateIfChatIsAvailable());

  useEffect(() => {
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function () {
        setIsChatAvailable(calculateIfChatIsAvailable());
      });
    });
    const salesforceLiveChatScriptStateTracker = document.getElementById(salesforceLiveChatScriptStateTrackerId);
    if (salesforceLiveChatScriptStateTracker) {
      observer.observe(salesforceLiveChatScriptStateTracker, { attributes: true, attributeFilter: ["style"] });
    }

    return () => observer.disconnect();
  }, []);

  return { isChatAvailable, chatButtonId: buttonId, api: window.liveagent };
};
