import { ClickableRoundedCard } from "../ContactUs/Components/ContactCard/ClickableRoundedCard";
import { t } from "i18next";
import { Icon } from "UIPalette/Icon/Icon";
import { ReactComponent as BreathingExercisesIcon } from "shared/assets/Icons/breathing-exercises-sm.svg";
import { useNavigate } from "react-router-dom";

export function BreathingExercisesCard() {
  const navigate = useNavigate();
  return (
    <ClickableRoundedCard
      title={t("home.breathingExercises.title")}
      icon={<Icon icon={<BreathingExercisesIcon width="24" height="24" />} bgColour="bg-sky" hasDropShadow={false} />}
      description={t("home.breathingExercises.description")}
      handleClick={() => navigate("/resources/breathing-exercises")}
      showChevron
    />
  );
}
