import { Icon } from "UIPalette/Icon/Icon";
import { ReactElement } from "react";
import { ReactComponent as RightChevron } from "shared/assets/Icons/right.svg";
import classNames from "classnames";

export type CardBodyProps = {
  title: string;
  description: string;
  showChevron: boolean;
  icon: ReactElement;
  iconBackgroundColour?: string;
  iconForegroundColour?: string;
};

export const CardBody = ({ title, icon, description, showChevron, iconBackgroundColour, iconForegroundColour }: CardBodyProps) => {
  return (
    <>
      {title.isNullOrEmpty() ? null : <h4 className="font-semibold text-black">{title}</h4>}
      <div className="flex items-center">
        <Icon icon={icon} bgColour={iconBackgroundColour} fgColour={iconForegroundColour} hasDropShadow={false} />
        <p className={classNames("ml-4", "mr-4", "grow", { "pr-8": showChevron })}>{description}</p>
        {showChevron ? <RightChevron className="w-8 text-grey" height="14" width="6" /> : null}
      </div>
    </>
  );
};

CardBody.defaultProps = { title: "", showChevron: true };
