export type ComboboxEntry = {
  text: string;
  value: string;
};

type OptionsProps = {
  options: ComboboxEntry[] | undefined;
  defaultValue: string;
};

export const Options = ({ options, defaultValue }: OptionsProps) => {
  return (
    <>
      <option value={undefined}>{defaultValue}</option>
      {options &&
        options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
    </>
  );
};
