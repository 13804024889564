export enum TrackerType {
  Mood = 0,
  Sleep = 1,
  Steps = 2,
  Activity = 3,
  Water = 4,
  BreathingExercises = 5,
}

export function toTrackerType(trackerIdentifier: string): TrackerType {
  const trackerType = Object.keys(TrackerType).find((type) => type.toLowerCase() === trackerIdentifier);
  if (trackerType === undefined) {
    throw new Error(`Could not find a TrackerType using tracker identifier of: ${trackerIdentifier}`);
  }
  return TrackerType[trackerType as keyof typeof TrackerType];
}