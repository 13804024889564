import { ChangeEvent } from "react";
import { DivisionResponseModel, useListDivisionsFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { useTranslation } from "react-i18next";
import { NoValueSelected } from "shared/constants/SelectConstants";
import { Select } from "shared/UI/Inputs/Select/Select";

type DivisionSelectProps = {
  onChange: (e: ChangeEvent<HTMLSelectElement>, division?: DivisionResponseModel) => void;
  label: string;
  value?: string;
};

export const DivisionSelect = ({ onChange, label, value }: DivisionSelectProps) => {
  const divisionsFunctionRequest = useListDivisionsFunctionRequest();
  const { data } = divisionsFunctionRequest;
  const { t } = useTranslation();

  const handleChangeDivision = (e: ChangeEvent<HTMLSelectElement>) => {
    const division = data?.divisions?.find((division) => division.id === e.target.value);
    onChange(e, division);
  };

  return (
    <Select onChange={(e) => handleChangeDivision(e)} label={label} value={value}>
      <option key={0} value={NoValueSelected}>
        {t("division.defaultDivision")}
      </option>
      {data?.divisions?.map((d) => (
        <option key={d.id} value={d.id}>
          {d.name}
        </option>
      ))}
    </Select>
  );
};
