import ReactPlayer from "react-player";
import cn from "classnames";
import { useRef, useState } from "react";
import { MediaPlayerState } from "./MediaPlayerState";

export type VideoPlayerProps = {
  link?: string | undefined;
};

export const VideoPlayer = ({ link }: VideoPlayerProps) => {
  const playerRef = useRef<ReactPlayer>(null);
  const [playerState, setPlayerState] = useState<MediaPlayerState>({ playing: false, seeking: false, duration: 0, played: 0 });

  return (
    <div className={cn("w-full h-[50vh] pt-5")}>
      <ReactPlayer
        ref={playerRef}
        width="100%"
        height="100%"
        url={link}
        playing={playerState.playing}
        controls={true}
        onPlay={() => {
          setPlayerState({ ...playerState, playing: true });
        }}
        onPause={() => setPlayerState({ ...playerState, playing: false })}
        onEnded={() => setPlayerState({ ...playerState, playing: false })}
        onDuration={(duration) => setPlayerState({ ...playerState, duration })}
        onProgress={(progress) => {
          if (!playerState.seeking) {
            setPlayerState({ ...playerState, played: progress.played });
          }
        }}
        onError={(e) => console.log("onError", e)}
      />
    </div>
  );
};
