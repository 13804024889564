import { useState } from "react";

type scriptState = "loading" | "ready" | "failed";

export const useLoadExternalScript = (url: string) => {
  const [state, setState] = useState<scriptState>("ready");
  let script = document.querySelector(`script[src="${url}"]`) as HTMLScriptElement | undefined;

  if (url !== "") {
    if (!script) {
      setState("loading");
      script = document.createElement("script");
      script.src = url;
      script.async = true;
      script.onload = () => setState("ready");
      script.onerror = () => {
        document.removeChild(script!);
        setState("failed");
      };
      document.body.appendChild(script);
    }
  }

  return state;
};
