import classNames from "classnames";
import { share } from "core/javascriptChannels";
import { useTranslation } from "react-i18next";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { toastNotify } from "shared/UI/Toaster/Toaster";

export const FileDownloader = ({ url, title }: { url: string; title: string }) => {
  const { t } = useTranslation();
  const isMobileApp = window.flutter_inappwebview != null;

  const downloadFile = () => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  };

  const shareFile = async () => {
    const shareSuccessful = await share(url, title);
    if (!shareSuccessful) {
      toastNotify(t("viewArticle.downloader.error"), "error");
    }
  };

  return (
    <StylableButton
      className={classNames("w-full lg:w-1/3 pt-12 left-0 m-auto")}
      outlineButton
      fullWidth
      text={isMobileApp ? t("viewArticle.downloader.shareDownload") : t("viewArticle.downloader.download")}
      color="primary"
      onClick={async () => (isMobileApp ? shareFile() : downloadFile())}
    />
  );
};
