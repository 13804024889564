import "./Leaderboard.css";
import "shared/extensions/date";

import { Content, DateOnly, LeaderboardParticipant, useLeaveLeaderboardFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { getNow, getTimeLeft, millisecondsInADay } from "core/helpers/time";
import { useRef, useState } from "react";

import { ContextMenu } from "@brighthr/component-contextmenu";
import { ContextMenuListItem } from "@brighthr/component-contextmenu";
import { CurvedTopBanner } from "UIPalette/CurvedTopBanner/CurvedTopBanner";
import { Hideable } from "shared/UI/Hideable/Hideable";
import { InformationModal } from "./components/InformationModal/InformationModal";
import { InviteModal } from "./components/InviteModal/InviteModal";
import { LeaveModal } from "./components/LeaveModal/LeaveModal";
import { LooseObject } from "shared/core/LooseObject";
import { ReactComponent as MoreCircle } from "shared/assets/Icons/more-circle.svg";
import { PageHeader } from "UIPalette/PageHeader/PageHeader";
import { ReactComponent as RightChevron } from "shared/assets/Icons/right.svg";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { UserState } from "core/state/userState";
import classNames from "classnames";
import { t } from "i18next";
import { toastNotify } from "shared/UI/Toaster/Toaster";
import { useNavigate } from "react-router-dom";

type LeaderboardParticipantWithPosition = LeaderboardParticipant & { position: string };

export type LeaderboardDisplayProps = {
  id: string;
  name: string;
  endDate: DateOnly;
  participants: LeaderboardParticipant[];
  description: Content | undefined;
  isTemplateLoading: boolean;
  code: string;
};

export const LeaderboardDisplay = ({ name, endDate, participants, description, isTemplateLoading, code }: LeaderboardDisplayProps) => {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const navigate = useNavigate();
  const { trigger } = useLeaveLeaderboardFunctionRequest({});

  const currentUserPlace = useRef<HTMLTableRowElement | undefined>();
  const numberOfMembers = t("leaderboards.display.subTitle", { count: participants.length });
  const timeLeft = getTimeLeft(getNow(), Date.fromDateOnly(endDate)!);

  const isLeaderboardFinished = getNow().getTime() >= Date.fromDateOnly(endDate)!.getTime() + millisecondsInADay;

  const displayParticipants = getParticipantsWithPosition(participants);

  const currentUserId = UserState.get()?.id;
  const currentUser = displayParticipants.find((participant) => participant.id === currentUserId);

  const PositionNote = () => {
    const placementLabel = isLeaderboardFinished
      ? t("leaderboards.display.finalPlaceLabel", { place: currentUser!.position })
      : t("leaderboards.display.currentPlaceLabel", { place: currentUser!.position });

    return (
      <button className="mb-4 w-full" onClick={() => currentUserPlace?.current?.scrollIntoView?.()}>
        <div className="flex flex-row w-full">
          <div className="flex-grow" role="note">
            {placementLabel}
          </div>
          <div className="flex items-center">
            <RightChevron className="text-grey mt-0" height="12" width="12" />
          </div>
        </div>
      </button>
    );
  };

  async function leaveLeaderboard() {
    try {
      await trigger({ body: { leaderboardCode: code! } });
      navigate("/leaderboards");
    } catch {
      toastNotify(t("leaderboards.leave.error"), "error");
    }
  }

  return (
    <>
      <CurvedTopBanner>
        <div className="flex flex-row justify-between">
          <PageHeader title={name} subtitle={`${numberOfMembers} - ${timeLeft}`} backRoute={-1} className="flex-grow" />
          <Hideable hidden={isLeaderboardFinished}>
            <div className="pt-1 md:pt-0 md:flex md:items-center md:pb-5">
              <ContextMenu
                aria-label={t("leaderboards.display.ariaContextMenu")}
                customTriggerLayout={<MoreCircle className="w-7 h-7 md:h-10 md:w-10" />}
                menuPosition="left"
                contextName="actions"
              >
                <ContextMenuListItem
                  className="[&_span]:text-[14pt] [&_span]:font-bold py-2 pl-2"
                  text={t("leaderboards.display.informationButton")}
                  key={1}
                  onClick={() => {
                    setShowInfoModal(true);
                  }}
                />
                <ContextMenuListItem
                  className="[&_span]:text-[14pt] [&_span]:font-bold py-2 pl-2"
                  text={t("leaderboards.display.leaveButton")}
                  key={2}
                  onClick={() => {
                    setShowLeaveModal(true);
                  }}
                />
              </ContextMenu>
            </div>
          </Hideable>
        </div>
      </CurvedTopBanner>
      <div className="flex flex-grow flex-col h-full">
        <div className="flex-grow">
          <PositionNote />
          <table className="scores-table w-full">
            <thead>
              <tr>
                <th>{t("leaderboards.display.tableHeadings.position")}</th>
                <th>{t("leaderboards.display.tableHeadings.name")}</th>
                <th>{t("leaderboards.display.tableHeadings.score")}</th>
              </tr>
            </thead>
            <tbody>
              {displayParticipants.map((participant) => {
                const isCurrentUserRow = participant.id === currentUserId;

                const rowProps: LooseObject = isCurrentUserRow ? { ref: currentUserPlace } : {};
                rowProps["key"] = participant.id;

                return (
                  <tr {...rowProps} className={classNames({ userRow: isCurrentUserRow })}>
                    <td>{participant.position}</td>
                    <td>{participant.name}</td>
                    <td>{participant.score}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Hideable hidden={isLeaderboardFinished}>
          <StylableButton
            className="invite-button"
            text={t("leaderboards.display.inviteButton")}
            color="primary"
            onClick={() => setShowInviteModal(true)}
          />
        </Hideable>
        <Hideable hidden={!showInfoModal}>
          <InformationModal isTemplateLoading={isTemplateLoading} title={name} description={description} onClose={() => setShowInfoModal(false)} />
        </Hideable>
        <Hideable hidden={!showLeaveModal}>
          <LeaveModal onConfirm={() => leaveLeaderboard()} onClose={() => setShowLeaveModal(false)} />
        </Hideable>
        <Hideable hidden={!showInviteModal}>
          <InviteModal
            code={code}
            onClose={() => {
              setShowInviteModal(false);
            }}
          />
        </Hideable>
      </div>
    </>
  );
};

function sortParticipants(participants: LeaderboardParticipant[]) {
  return participants.sort((a, b) => (a.score < b.score ? 1 : -1));
}

function withPosition(participants: LeaderboardParticipant[]) {
  const result: LeaderboardParticipantWithPosition[] = [];
  let lastScore: number | undefined = undefined;
  let lastPosition: string | undefined = undefined;

  for (let i = 0; i < participants.length; ++i) {
    let position = (i + 1).asOrdinal();
    if (participants[i].score !== lastScore) {
      lastScore = participants[i].score;
      lastPosition = position;
    }

    result.push({ id: participants[i].id, name: participants[i].name, score: lastScore, position: lastPosition! });
  }
  return result;
}

function getParticipantsWithPosition(participants: LeaderboardParticipant[]): LeaderboardParticipantWithPosition[] {
  const participantsInDescendingScoreOrder = sortParticipants(participants);
  return withPosition(participantsInDescendingScoreOrder);
}
