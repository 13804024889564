import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";
import { ReactComponent as RightChevron } from "shared/assets/Icons/right.svg";
import { Icon } from "UIPalette/Icon/Icon";
import { ReactElement } from "react";
import { LearningMaterials } from "UIPalette/LearningMaterials/LearningMaterials";
import { useMatchMedia } from "shared/UI/Hooks/useMatchMedia";
import { ResponsiveBreakpoints } from "shared/UI/ResponsiveBreakpoints";
import { Hideable } from "shared/UI/Hideable/Hideable";
import { WellKnownLearningMaterialCategory } from "UIPalette/LearningMaterials/wellKnownLearningMaterials";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export type ArticleCategoryListProps = {
  heading: string;
  description: string;
  category: WellKnownLearningMaterialCategory;
  icon: ReactElement;
  iconBackgroundColour: string;
  iconForegroundColour: string;
  ariaLabel: string;
};

export const ArticleCategoryList = ({
  heading,
  description,
  category,
  icon,
  iconBackgroundColour,
  iconForegroundColour,
  ariaLabel,
}: ArticleCategoryListProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const largerDisplay = useMatchMedia(ResponsiveBreakpoints.Tablet, false);
  const smallerDisplay = !largerDisplay;
  return (
    <RoundedCard>
      <button
        className="text-left w-full mb-4"
        onClick={() => {
          navigate(`/resources/${category.path}/`);
        }}
      >
        <h4 className="cardTitle">{heading}</h4>

        <div className="flex items-center">
          <Icon icon={icon} bgColour={iconBackgroundColour} fgColour={iconForegroundColour} hasDropShadow={false} />

          <p className="mx-4 grow">{description}</p>
          <div>
            <RightChevron className="text-grey" height="24" width="12" />
          </div>
        </div>
      </button>
      <Hideable hidden={smallerDisplay}>
        <div className={"mt-2"}>
          <LearningMaterials
            categoryIds={[category.id]}
            maxResults={4}
            resultsPerPage={4}
            navigateBasePath={`/resources/${category.path}/`}
            errorMessage={t("resources.articileLibraryErrorButton")}
            ariaLabel={ariaLabel}
          />
        </div>
      </Hideable>
    </RoundedCard>
  );
};
