import { BottomNavContactUsButton } from "./BottomNavContactUsButton";
import { BottomNavHealthHubButton } from "./BottomNavHealthHubButton";
import { BottomNavHomeButton } from "./BottomNavHomeButton";
import { BottomNavMyAccountButton } from "./BottomNavMyAccountButton";
import { BottomNavResourcesButton } from "./BottomNavResourcesButton";
import { useLocation } from "react-router-dom";

export const BottomNavBar = () => {
  const location = useLocation();
  const path = location.pathname;

  return (
    <nav role="navigation" className="overflow-clip bg-white h-20 w-full sticky bottom-0 z-OVERLAY shadow-inner lg:hidden mt-auto">
      <div className="flex flex-row justify-evenly items-center h-full w-full text-sm">
        <BottomNavHomeButton active={path.startsWith("/home") || path === "/"} />
        <BottomNavHealthHubButton active={path.startsWith("/health-hub")} />
        <BottomNavResourcesButton active={path.startsWith("/resources")} />
        <BottomNavContactUsButton active={path.startsWith("/contact-us")} />
        <BottomNavMyAccountButton active={path.startsWith("/account")} />
      </div>
    </nav>
  );
};
