import { t } from "i18next";
import { ReactComponent as ContactUsIcon } from "shared/assets/Icons/contact-us-outline.svg";
import { ReactComponent as FilledContactUsIcon } from "shared/assets/Icons/contact-us-filled.svg";
import { useNavigate } from "react-router-dom";

export const BottomNavContactUsButton = ({ active }: { active: boolean }) => {
  const navigateHook = useNavigate();

  return (
    <button
      className="flex flex-col items-center text-brand font-bold text-[14pt] mx-1"
      aria-label={t("navigation.contactUsNavigationLabel")}
      onClick={() => {
        navigateHook("/contact-us");
      }}
    >
      {active ? <FilledContactUsIcon /> : <ContactUsIcon />}
      <p className="mt-1">{t("navigation.contactUsButton")}</p>
    </button>
  );
};
