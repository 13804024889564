import classNames from "classnames";
import { PropsWithChildren } from "react";
import { ReactComponent as Cross } from "../../assets/Icons/cross.svg";
import "./BurgerMenu.css";

export type BurgerMenuProps = {
  heading?: string;
  isOpen: boolean;
  onCloseClick: VoidFunction;
  onCloseFinished?: VoidFunction;
  className?: string;
  closeButtionAriaLabel?: string;
} & PropsWithChildren;

export const BurgerMenu = ({ heading, isOpen, onCloseClick, onCloseFinished, className, closeButtionAriaLabel, children }: BurgerMenuProps) => {
  return (
    <div
      onTransitionEnd={() => {
        if (!isOpen) {
          onCloseFinished?.();
        }
      }}
      className={classNames(
        "fixed top-20 w-[80%] md:w-[40%] h-[calc(100%-10rem)] lg:h-full burger-menu bg-white z-50 left-0 px-10 py-6 overflow-y-auto",
        {
          className: !!className,
          "open-menu": isOpen === true,
          "closed-menu": isOpen === false,
        }
      )}
    >
      <div className={classNames("flex justify-end w-full pb-2", { "justify-between": !!heading })}>
        {heading && <h3 className="self-start">{heading}</h3>}
        <button onClick={onCloseClick} className="self-start pt-1 pr-4" aria-label={closeButtionAriaLabel}>
          <Cross className="h-5 text-primary-700" />
        </button>
      </div>
      {children}
    </div>
  );
};
