import { Modal, ModalBody } from "shared/UI/Modal/Modal";

import Button from "@brighthr/component-button";
import { useTranslation } from "react-i18next";

export type LeaveModalProps = {
  onConfirm: Function;
  onClose: Function;
};

export const LeaveModal = ({ onConfirm, onClose }: LeaveModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal title={t("leaderboards.leave.title")} headerSize="base" width="xs" close={() => onClose()}>
      <ModalBody>
        {t("leaderboards.leave.description")}
        <div className="flex flex-row w-full justify-between pt-4">
          <Button text={t("leaderboards.leave.closeButton")} outlineButton color="primary" onClick={() => onClose()} />
          <Button text={t("leaderboards.leave.confirmButton")} color="primary" onClick={() => onConfirm()} />
        </div>
      </ModalBody>
    </Modal>
  );
};
