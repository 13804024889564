import { BreathingExercise, useListBreathingExercisesFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { BreathingExerciseCard } from "../BreathingExerciseCard/BreathingExerciseCard";
import { BreathingExercisesPaginator } from "../BreathingExercisesPaginator/BreathingExercisesPaginator";
import { useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ViewStateWrapper } from "UIPalette/ViewStateWrapper/ViewStateWrapper";
import { useMatchMedia } from "shared/UI/Hooks/useMatchMedia";
import { ResponsiveBreakpoints } from "shared/UI/ResponsiveBreakpoints";
import classNames from "classnames";

export type BreathingExerciseSelectorProps = {
  onStartExercise: (exercise: BreathingExercise, duration: number) => void;
};

export const BreathingExerciseSelector = ({ onStartExercise }: BreathingExerciseSelectorProps) => {
  const { t } = useTranslation();
  const isLargerScreen = useMatchMedia(ResponsiveBreakpoints.Tablet, false);
  const { data, isLoading, error, mutate } = useListBreathingExercisesFunctionRequest(
    {},
    { revalidateOnMountIfStale: false, showErrorIfStale: false }
  );
  const ref = useRef<HTMLDivElement>(null);
  const [showPaginator, setShowPaginator] = useState(false);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  useLayoutEffect(() => {
    const showPaginatorIfOverflow = () => {
      if (ref.current) {
        const containerOverflows = ref.current.clientWidth < ref.current.scrollWidth;
        setShowPaginator(containerOverflows);
      }
    };

    window.addEventListener("resize", showPaginatorIfOverflow);
    showPaginatorIfOverflow();
    return () => window.removeEventListener("resize", showPaginatorIfOverflow);
  }, [ref, data]);

  function ensurePaginatorShowsCorrectPageAfterScroll() {
    const isFullyScrolledLeft = (e: HTMLDivElement) => e.scrollLeft === 0;
    const isFullyScrolledRight = (e: HTMLDivElement) => e.scrollLeft + e.clientWidth === e.scrollWidth;

    if (ref.current) {
      const scrollPosition = ref.current.scrollLeft;
      const pageContainers = ref.current.children;
      let scrollIndicatorIndex = 0;
      if (isFullyScrolledLeft(ref.current)) {
        scrollIndicatorIndex = 0;
      } else if (isFullyScrolledRight(ref.current)) {
        scrollIndicatorIndex = pageContainers.length - 1;
      } else {
        const offsetsFromLeft = [];
        let cumulativeWidth = 0;
        for (let i = 0; i < pageContainers.length; i++) {
          const pageContainer = pageContainers[i];
          offsetsFromLeft.push(cumulativeWidth);
          cumulativeWidth += pageContainer.clientWidth;
        }

        let minDistanceToLeft = offsetsFromLeft[offsetsFromLeft.length - 1];
        for (let i = 0; i < offsetsFromLeft.length; i++) {
          const offsetFromLeft = offsetsFromLeft[i];
          const distanceFromLeft = Math.abs(offsetFromLeft - scrollPosition);
          if (distanceFromLeft < minDistanceToLeft) {
            scrollIndicatorIndex = i;
            minDistanceToLeft = distanceFromLeft;
          }
        }
      }

      if (scrollIndicatorIndex !== currentPageIndex) {
        setCurrentPageIndex(scrollIndicatorIndex);
      }
    }
  }

  return (
    <ViewStateWrapper
      loading={isLoading}
      error={!!error}
      errorMessage={t("resources.breathingExercisesError")}
      onRetry={mutate}
      isFullScreenView={true}
    >
      <div>
        <div
          ref={ref}
          className={classNames(
            "w-[100vw] flex overflow-x-auto lg:justify-center scroll-smooth h-[60vh] md:h-[50vh] w-[calc(100%+3rem)] md:w-[calc(100%+10rem)] ml-[-1.5rem] md:ml-[-5rem] first:pl-[1.5rem] md:first:pl-[5rem]",
            {
              "no-scrollbar": !isLargerScreen,
            }
          )}
          onScroll={ensurePaginatorShowsCorrectPageAfterScroll}
        >
          {data?.map((exercise, index) => {
            return (
              <div id={`page_${index}`} key={index} className="p-2 pb-4 w-80 flex-shrink-0">
                <BreathingExerciseCard exercise={exercise} onStartExercise={onStartExercise} />
              </div>
            );
          })}
        </div>
        {showPaginator && (
          <div className="pt-3">
            <BreathingExercisesPaginator count={data?.length ?? 0} index={currentPageIndex} setIndex={setCurrentPageIndex} />
          </div>
        )}
      </div>
    </ViewStateWrapper>
  );
};
