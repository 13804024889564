import ViewLoading from "shared/UI/Spinners/ViewLoading";
import { useTranslation } from "react-i18next";
import { useCurrentUserFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { Navigate, useNavigate } from "react-router-dom";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import { DestinationState } from "shared/core/state/destinationState";
import "shared/extensions/string";
import { useAuth } from "../../core/auth/useAuth";
import { appInsightsLogger } from "core/monitoring/AppInsights";
import { ClientCodeState } from "shared/core/state/clientCodeState";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { useEffect } from "react";
import { useUserContext } from "../../core/state/UserContext";

export const Auth = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  appInsightsLogger.info("useAuth - Auth.tsx navigating");
  const auth = useAuth();
  const { user, saveUser, isAdditionalInfoComplete } = useUserContext();
  const { data: userResponse, isLoading, error } = useCurrentUserFunctionRequest({}, { shouldFetch: !auth.isLoading });

  useEffect(() => {
    if (userResponse) {
      saveUser(userResponse);
    }
  }, [userResponse, saveUser]);

  const hasError = ClientCodeState.get() === undefined || error !== undefined;
  if (hasError) {
    return (
      <>
        <ErrorAlert title={t("signup.error.title")} content="" />
        <StylableButton className="bright-button" text={t("signup.error.button")} onClick={() => navigate("/")} />
      </>
    );
  }

  const isAuthOrApiCallLoading = auth.isLoading || isLoading;
  if (isAuthOrApiCallLoading || !user) {
    return <ViewLoading label={t("signup.loading")} />;
  }

  if (!isAdditionalInfoComplete()) {
    return <Navigate to="/additional-info" />;
  }

  return <Navigate to={DestinationState.get()} />;
};
