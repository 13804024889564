import { CheckboxProps } from "@brighthr/component-checkbox";
import Checkbox from "@brighthr/component-checkbox";

export const CheckBox = (props: CheckboxProps) => {
  return (
    <div className="bright-checkbox">
      <Checkbox {...props}></Checkbox>
    </div>
  );
};
