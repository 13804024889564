import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";
import { CardBody, CardBodyProps } from "./CardBody";
import { PropsWithChildren } from "react";

export type ClickableContactCardProps = {
  handleClick: Function;
  disabled: boolean;
  className?: string;
  iconBackgroundColour?: string;
  iconForegroundColour?: string;
} & CardBodyProps &
  PropsWithChildren;

export const ClickableRoundedCard = ({ handleClick, disabled, className, children, ...rest }: ClickableContactCardProps) => {
  return (
    <RoundedCard>
      <button className={`text-left w-full ${className}`} disabled={disabled} onClick={() => handleClick()}>
        <CardBody {...rest} />
      </button>
      {children}
    </RoundedCard>
  );
};

ClickableRoundedCard.defaultProps = { showChevron: true, disabled: false };
