import { TrackerType } from "core/state/Trackers/TrackerType";
import { TrackerProgressCard } from "../../../components/TrackerProgressCard";
import { ReactComponent as Steps } from "shared/assets/Icons/steps.svg";
import { useTrackerContext } from "core/state/Trackers/TrackerContext";
import { TrackerThresholdPercentages } from "core/state/Trackers/TrackerThresholdPercentages";
import { t } from "i18next";

export const StepsTrackerProgressCard = () => {
  const { getGoal, getCurrentValue } = useTrackerContext();
  const goal = getGoal(TrackerType.Steps);
  const current = getCurrentValue(TrackerType.Steps);
  const percent = current.percentageOf(goal, 0);

  function getProgressMessage(percentage: number): string {
    switch (true) {
      case percentage >= 100:
        return t(`healthHub.trackers.progress.complete`);
      case percentage > TrackerThresholdPercentages.High:
        return t("healthHub.trackers.steps.progress.high", { current, percent });
      case percentage >= TrackerThresholdPercentages.Medium:
        return t("healthHub.trackers.steps.progress.medium", { current, percent });
      default:
        return t("healthHub.trackers.steps.progress.low", { current, percent });
    }
  }

  return (
    <TrackerProgressCard
      trackerType={TrackerType.Steps}
      percentage={percent}
      heading={t("healthHub.trackers.steps.progress.heading")}
      goalHeading={t("healthHub.trackers.steps.goal.heading")}
      goalDescription={t("healthHub.trackers.steps.goal.description")}
      goalIncrement={1000}
      goalUnit={t("healthHub.trackers.steps.goal.unit")}
      progressMessage={getProgressMessage(percent)}
      Icon={Steps}
    />
  );
};
