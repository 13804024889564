import { RetryButton } from "UIPalette/Buttons/RetryButton";
import { PropsWithChildren } from "react";
import ViewLoading from "shared/UI/Spinners/ViewLoading";

type ViewStateWrapperProps = {
  loading: boolean;
  error: boolean;
  isFullScreenView?: boolean;
  onRetry: VoidFunction;
  errorMessage?: string;
} & PropsWithChildren;

export const ViewStateWrapper = ({ loading, error, isFullScreenView = false, onRetry, errorMessage, children }: ViewStateWrapperProps) => {
  if (error) {
    return <RetryButton text={errorMessage} onClick={onRetry} className={isFullScreenView ? "pt-72" : ""} />;
  }

  if (loading) {
    return <ViewLoading heightClass={isFullScreenView ? "pt-72" : "h-full"} />;
  }

  return <>{children}</>;
};
