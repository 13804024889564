import { PropsWithChildren } from "react";

export type HideableProps = {
  hidden: boolean;
  className?: string;
};

export const Hideable = ({ hidden, className, children }: HideableProps & PropsWithChildren) => {
  if (hidden) return <></>;

  return className ? <div className={className}>{children}</div> : <>{children}</>;
};
