import { AppRoutes } from "core/AppRoutes";
import { CurvedTopBanner } from "UIPalette/CurvedTopBanner/CurvedTopBanner";
import { DatoContentPage } from "UIPalette/Components/DatoContentPage/DatoContentPage";
import { PageHeader } from "UIPalette/PageHeader/PageHeader";
import { ResponsiveBreakpoints } from "shared/UI/ResponsiveBreakpoints";
import { useMatchMedia } from "shared/UI/Hooks/useMatchMedia";

interface AccountDatoPageProps {
  heading: string;
  slug: string;
  pageTitle?: string;
}

export const AccountDatoPage = ({ heading, slug, pageTitle }: AccountDatoPageProps) => {
  const largerDisplay = useMatchMedia(ResponsiveBreakpoints.Desktop, false);
  return (
    <>
      <CurvedTopBanner>
        <PageHeader title={heading} backRoute={largerDisplay ? AppRoutes.home : AppRoutes.account} />
      </CurvedTopBanner>
      <div className={"col-span-full lg:mx-[10%]"}>
        <DatoContentPage slug={slug} pageTitle={pageTitle} />
      </div>
    </>
  );
};
