import { useTranslation } from "react-i18next";
import { Hideable } from "shared/UI/Hideable/Hideable";
import { useState } from "react";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { useTrackerContext } from "core/state/Trackers/TrackerContext";
import { AddWaterIntakeModal } from "../AddWaterIntakeModal/AddWaterIntakeModal";
import { TrackerType } from "core/state/Trackers/TrackerType";
import { trackAddHealthData, trackCancelHealthData, trackSaveHealthData } from "core/monitoring/Events";

export const AddWaterIntakeCard = () => {
  const { getCurrentValue, setCurrentValue: setProgress } = useTrackerContext();
  const [showAddData, setShowAddData] = useState(false);
  const { t } = useTranslation();

  function save(millilitres: number) {
    trackSaveHealthData(TrackerType.Water);
    setProgress(TrackerType.Water, millilitres);
    setShowAddData(false);
  }

  const handleAddData = () => {
    trackAddHealthData(TrackerType.Water);
    setShowAddData(true);
  };

  const handleCloseModal = () => {
    trackCancelHealthData(TrackerType.Water);
    setShowAddData(false);
  };

  return (
    <>
      <StylableButton text={t("healthHub.trackers.buttons.addData")} color="primary" fullWidth={true} onClick={handleAddData} />
      <Hideable hidden={!showAddData}>
        <AddWaterIntakeModal millilitres={getCurrentValue(TrackerType.Water)} onClose={handleCloseModal} onSave={save} />
      </Hideable>
    </>
  );
};
