import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";
import { ReactComponent as SleepIcon } from "shared/assets/Icons/sleep.svg";
import { ReactComponent as MoodIcon } from "shared/assets/Icons/face-neutral.svg";
import { ReactComponent as WaterIcon } from "shared/assets/Icons/water.svg";
import { ReactComponent as StepsIcon } from "shared/assets/Icons/steps.svg";
import { ReactComponent as ActivityIcon } from "shared/assets/Icons/activity.svg";
import { ReactComponent as BreathingIcon } from "shared/assets/Icons/breathing.svg";
import { ReactComponent as RightChevron } from "shared/assets/Icons/right.svg";
import { Icon } from "UIPalette/Icon/Icon";
import { Tracker } from "shared/request/myHealthyAdvantageApi";
import { useNavigate } from "react-router-dom";
import { AppRoute, AppRoutes } from "core/AppRoutes";

export type TrackerCardProps = {
  tracker: Tracker;
};

export const TrackerCard = ({ tracker }: TrackerCardProps) => {
  const navigate = useNavigate();
  let pathToComponent: AppRoute = AppRoutes.healthHub;

  const getIcon = () => {
    switch (tracker.ident) {
      case "mood":
        return <MoodIcon width="30" height="30" title={tracker.name} className="text-d-violet" />;
      case "water":
        pathToComponent = AppRoutes.waterTracker;
        return <WaterIcon width="30" height="30" title={tracker.name} className="text-d-violet" />;
      case "steps":
        pathToComponent = AppRoutes.stepsTracker;
        return <StepsIcon width="30" height="30" title={tracker.name} className="text-d-violet" />;
      case "activity":
        pathToComponent = AppRoutes.activityTracker;
        return <ActivityIcon width="30" height="30" title={tracker.name} className="text-d-violet" />;
      case "breathingexercises":
        pathToComponent = AppRoutes.breathingExercises;
        return <BreathingIcon width="30" height="30" title={tracker.name} className="text-d-violet" />;
      case "sleep":
        pathToComponent = AppRoutes.sleepTracker;
        return <SleepIcon width="30" height="30" className="text-d-violet" />;
    }

    throw new Error(`Unknown tracker type ${tracker.ident}`);
  };

  return (
    <button
      className="text-left w-full"
      onClick={() => {
        navigate(pathToComponent);
      }}
    >
      <RoundedCard>
        <div className="flex items-center">
          <Icon icon={getIcon()} bgColour="bg-xl-lilac" hasDropShadow={false} />
          <p className="mx-4 grow">{tracker.description}</p>
          <div>
            <RightChevron className="text-grey" height="24" width="12" />
          </div>
        </div>
      </RoundedCard>
    </button>
  );
};
