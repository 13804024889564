import React from "react";
import { DateOnly } from "shared/request/myHealthyAdvantageApi";
import { useTranslation } from "react-i18next";
import "shared/extensions/date";

type StartedOnDateProps = {
  startDate: DateOnly | undefined;
};

export const StartedOnDate = ({ startDate }: StartedOnDateProps) => {
  const { t } = useTranslation();
  if (startDate === undefined) return <></>;

  const startDateString = Date.fromDateOnly(startDate)
    ?.toLocaleDateString("en-GB", {
      weekday: "short",
      month: "long",
      day: "numeric",
    })
    .replace(",", "");

  return <span>{t("fourWeekPlans.dailyProgress.startedOn", { startDate: startDateString })}</span>;
};
