import { StructuredText, StructuredTextDocument } from "react-datocms";

import { Content } from "shared/request/myHealthyAdvantageApi";
import { YouTubeVideo } from "../YouTubeVideo/YouTubeVideo";

interface DatoContentProps {
  content?: Content | undefined;
  className: string;
}

export const DatoContent = ({ content, className }: DatoContentProps) => {
  return (
    <div className={className}>
      <StructuredText
        data={content as StructuredTextDocument}
        renderBlock={({ record }) => {
          if (record.type === "video" && record.videoType === "YouTube") {
            return <YouTubeVideo id={record.videoId} />;
          }
          return null;
        }}
      />
    </div>
  );
};

DatoContent.defaultProps = { className: "" };
