export abstract class DestinationState {
  public static get(): string {
    return sessionStorage.getItem("destination") ?? "/";
  }

  public static set(value: string) {
    sessionStorage.setItem("destination", value);
  }

  public static clear() {
    sessionStorage.removeItem("destination");
  }
}
