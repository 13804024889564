import cn from "classnames";

export type BreathingExercisesPaginatorProps = {
  count: number;
  index: number;
  setIndex: (pageIndex: number) => void;
};

export const BreathingExercisesPaginator = ({ count, index, setIndex }: BreathingExercisesPaginatorProps) => {
  return (
    <>
      {count > 1 && (
        <div className="flex flex-row justify-center items-center pt-2">
          {[...Array(count)].map((_, pageIndex) => {
            return (
              <a
                key={`pager_${pageIndex}`}
                href={`#page_${pageIndex}`}
                onClick={() => setIndex(pageIndex)}
                className={cn("rounded-full mx-3 w-3 h-3", {
                  "bg-d-grey": pageIndex === index,
                  "bg-l-grey": pageIndex !== index,
                })}
              >
                <span className="sr-only">Page {pageIndex + 1}</span>
              </a>
            );
          })}
        </div>
      )}
    </>
  );
};
