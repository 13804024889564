import classNames from "classnames";
import { PropsWithChildren } from "react";
import "./TitleCard.css";
import { useTranslation } from "react-i18next";
import { ReactComponent as RightChevron } from "shared/assets/Icons/right.svg";

export type TitleCardProps = PropsWithChildren & {
  title: string;
  radiusStyle: string;
  onTitleClick?: Function;
};

export const TitleCard = ({ title, radiusStyle, onTitleClick, children }: TitleCardProps) => {
  const { t } = useTranslation();

  return (
    <div className={classNames("title-card", radiusStyle, "p-2")}>
      {onTitleClick && (
        <button className="text-left w-full" aria-label={t("titleCard.titleAriaButton", { title })} onClick={() => onTitleClick()}>
          <h3 className="px-3 flex flex-row">
            <span className="flex-grow">{title}</span>
            <span className="mt-1.5 mr-2.5">
              <RightChevron className="text-white" height="24" width="12" />
            </span>
          </h3>
        </button>
      )}

      {!onTitleClick && (
        <h3 className="px-3 flex flex-row">
          <span className="flex-grow">{title}</span>
        </h3>
      )}

      {children}
    </div>
  );
};

TitleCard.defaultProps = { radiusStyle: "rounded-card" };
