export type WellKnownLearningMaterialCategory = {
  id: string;
  path: string;
};

export class WellKnownLearningMaterialCategories {
  static readonly brightTV: WellKnownLearningMaterialCategory = { id: "131646347", path: "bright-tv" };
  static readonly podcasts: WellKnownLearningMaterialCategory = { id: "131646459", path: "podcasts" };
  static readonly audioAndVideo: WellKnownLearningMaterialCategory = { id: "170998707", path: "audio-and-video" };
  static readonly articles: WellKnownLearningMaterialCategory = { id: "170998708", path: "articles" };
  static readonly newsletters: WellKnownLearningMaterialCategory = { id: "197429286", path: "newsletters" };
}

export class WellKnownLearningMaterialSubCategories {
  static readonly improvingYourPhysicalHealth: string = "174918121";
  static readonly recipes: string = "174918120";
  static readonly sleep: string = "131645122";
}
