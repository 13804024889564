import { LearningMaterials } from "UIPalette/LearningMaterials/LearningMaterials";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useUserContext } from "core/state/UserContext";

export const PersonalisedNewsFeed = () => {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const interestCategoryIds = (user?.interests ?? []).map((x) => x.categoryId);
  const useHasAnyInterests = interestCategoryIds.length > 0;

  return (
    <div>
      <h2>{t("home.personalisedNewsFeed.title")}</h2>
      {useHasAnyInterests ? (
        <p className="pb-2">
          {t("home.personalisedNewsFeed.learnMorePrompt")}&nbsp;
          <NavLink to="/resources">{t("home.personalisedNewsFeed.goToResourcesLinkText")}</NavLink>
        </p>
      ) : (
        <p className="pb-2">
          {t("home.personalisedNewsFeed.setPreferencesPrompt")}&nbsp;
          <NavLink to="/account/preferences">{t("home.personalisedNewsFeed.setPreferencesLinkText")}</NavLink>
        </p>
      )}
      <LearningMaterials
        categoryIds={interestCategoryIds}
        errorMessage={t("home.personalisedNewsFeed.errorButton")}
        ariaLabel={t("home.personalisedNewsFeed.label")}
      />
    </div>
  );
};
