import { TrackerType } from "core/state/Trackers/TrackerType";
import { TrackerProgressTile, TrackerProgressTileProps } from "../TrackerProgressTile";
import { ReactComponent as ActivityIcon } from "shared/assets/Icons/activity.svg";
import { useTranslation } from "react-i18next";

interface GeneralActivityTrackerProgressTileProps {
  inCard?: boolean;
  size?: TrackerProgressTileProps["size"];
}

export const GeneralActivityTrackerProgressTile = ({ inCard = true, size = "lg" }: GeneralActivityTrackerProgressTileProps) => {
  const { t } = useTranslation();
  return (
    <TrackerProgressTile
      trackerType={TrackerType.Activity}
      trackerPagePath="general-activity-tracker"
      Icon={ActivityIcon}
      wrapInCard={inCard}
      size={size}
      caption={t("healthHub.trackers.activity.caption")}
    />
  );
};
