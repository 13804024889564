import { ReactComponent as BackButtonIcon } from "shared/assets/Icons/back.svg";
import classNames from "classnames";
import { useBreadcrumbContext } from "UIPalette/AppLayout/Components/Breadcrumbs/BreadcrumbContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import Breadcrumbs from "UIPalette/AppLayout/Components/Breadcrumbs/Breadcrumbs";

export type PageHeaderProps = {
  title: string;
  subtitle?: string;
  backRoute?: string | number | Function;
  className?: string | undefined;
};

export const PageHeader = ({ title, subtitle, backRoute, className }: PageHeaderProps) => {
  const navigate = useNavigate();
  const breadcrumbContext = useBreadcrumbContext();

  const setBreadcrumbContext = (title: string) => {
    if (breadcrumbContext.setBreadcrumbTitle) {
      breadcrumbContext.setBreadcrumbTitle(title);
    }
  };

  useEffect(() => {
    setBreadcrumbContext(title);

    return () => setBreadcrumbContext("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  return (
    <div>
      <Breadcrumbs />
      <div className={classNames("grid grid-cols-[auto,1fr] grid-rows-[1fr,auto] items-center mb-2", className)}>
        {backRoute !== undefined && (
          <button
            className="self-center justify-self-center mr-2 col-start-1 row-start-1"
            aria-label={t("navigation.backButton")}
            onClick={() => {
              if (typeof backRoute === "number") {
                navigate(-1);
              } else if (typeof backRoute === "function") {
                backRoute();
              } else {
                navigate(backRoute);
              }
            }}
          >
            <BackButtonIcon className="lg:w-8 w-6" title={t("navigation.backButton")} />
          </button>
        )}

        <h2 className="col-start-2 row-start-1 p-0">{title}</h2>

        {subtitle !== undefined && <h5 className="block lg:mt-[-10px] col-start-2 row-start-2 p-0 text-[pt-14] font-bold">{subtitle}</h5>}
      </div>
    </div>
  );
};
