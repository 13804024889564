import { Hideable } from "shared/UI/Hideable/Hideable";
import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { TrackerProgressDonut } from "UIPalette/TrackerProgress/TrackerProgress";
import { TrackerType } from "core/state/Trackers/TrackerType";
import { ComponentType, useState } from "react";
import { useTrackerContext } from "core/state/Trackers/TrackerContext";
import { useTranslation } from "react-i18next";
import { SetGoalModal } from "./SetGoalModal";
import { trackBtnClicks, trackEvents } from "core/monitoring/Events";
import { EventNames } from "core/monitoring/types/enums";

export type TrackerProgressCardProps = {
  trackerType: TrackerType;
  percentage: number;
  heading: string;
  goalHeading: string;
  goalDescription: string;
  goalIncrement: number;
  goalUnit: string;
  progressMessage: string;
  Icon: ComponentType<React.SVGProps<SVGSVGElement>>;
};

export const TrackerProgressCard = ({
  trackerType,
  percentage,
  heading,
  goalHeading,
  goalDescription,
  goalUnit,
  goalIncrement,
  progressMessage,
  Icon,
}: TrackerProgressCardProps) => {
  const { t } = useTranslation();
  const [showSetGoal, setShowSetGoal] = useState(false);
  const { getGoal, setGoal } = useTrackerContext();

  function save(goal: number) {
    trackEvents(EventNames.SAVE_HEALTH_DATA_GOAL, { value: TrackerType[trackerType] });
    setGoal(trackerType, goal);
    setShowSetGoal(false);
  }

  const handleOpenModal = () => {
    trackBtnClicks(`change_${TrackerType[trackerType]}_goal_data`);
    setShowSetGoal(true);
  };

  const handleCloseModal = () => {
    trackBtnClicks(`cancel_change_${TrackerType[trackerType]}_goal_data`);
    setShowSetGoal(false);
  };

  return (
    <>
      <RoundedCard>
        <div className="flex flex-col gap-y-2">
          <h4 className="p-0">{heading}</h4>
          <div className="flex flex-row gap-x-2 items-center">
            <div className="w-16 h-16 flex-shrink-0">
              <TrackerProgressDonut percentage={percentage} size="sm" TrackerIcon={Icon} />
            </div>
            <p role="note">{progressMessage}</p>
          </div>
          <StylableButton text={t("healthHub.trackers.goal.buttons.changeGoal")} color="primary" fullWidth={true} onClick={handleOpenModal} />
        </div>
      </RoundedCard>
      <Hideable hidden={!showSetGoal}>
        <SetGoalModal
          heading={goalHeading}
          description={goalDescription}
          unit={goalUnit}
          incrementStep={goalIncrement}
          currentGoal={getGoal(trackerType)}
          onClose={handleCloseModal}
          onSave={save}
        />
      </Hideable>
    </>
  );
};
