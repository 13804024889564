import { ReactComponent as BottomLoginImage } from "shared/assets/BrightLogoColumn.svg";
import { EqualWebButton } from "UIPalette/EqualWebButton/EqualWebButton";
import { Outlet } from "react-router-dom";

export const LoginLayout = () => {
  return (
    <main role="main">
      <div className="absolute w-full h-full">
        <div className="absolute h-10 right-2">
          <EqualWebButton />
        </div>
        <div className="flex flex-col items-center justify-center h-full gap-16 lg:flex-row lg:justify-center">
          <div className={"w-80 flex flex-col gap-8 items-center justify-center"}>
            <BottomLoginImage className={"h-[200px]"} />
          </div>
          <div className="w-3/4 lg:px-5 lg:mr-5 lg:w-80 md:w-1/3">
            <Outlet />
          </div>
        </div>
      </div>
    </main>
  );
};
