import Spinner from "@brighthr/component-spinner";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Refresh } from "shared/assets/Icons/refresh.svg";

type RetryButtonProps = {
  text?: string;
  onClick: VoidFunction;
  className?: string;
};

export const RetryButton = ({ text, onClick, className }: RetryButtonProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoading) {
      const timerOne = setTimeout(onClick, 3000);
      const timerTwo = setTimeout(() => setIsLoading(false), 7000);
      return () => {
        clearTimeout(timerOne);
        clearTimeout(timerTwo);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <div className={classNames("mx-auto flex flex-col items-center", className)}>
      {text && <p className="mb-2 font-bold">{text}</p>}
      <button
        disabled={isLoading}
        className="flex items-center rounded bg-white p-2 border-2 border-primary-700"
        onClick={() => {
          setIsLoading(true);
        }}
      >
        <div className="mx-2">
          {isLoading ? <Spinner className="stroke-primary-700" size="xs" ariaLabel={t("buttons.loading")} /> : <Refresh className="h-4 w-4" />}
        </div>
        <p>{t("buttons.retry")}</p>
      </button>
    </div>
  );
};
