import { ReactComponent as FaceIconVerySad } from "shared/assets/Icons/face-very-sad.svg";
import { ReactComponent as FaceIconSad } from "shared/assets/Icons/face-sad.svg";
import { ReactComponent as FaceIconNeutral } from "shared/assets/Icons/face-neutral.svg";
import { ReactComponent as FaceIconHappy } from "shared/assets/Icons/face-happy.svg";
import { ReactComponent as FaceIconVeryHappy } from "shared/assets/Icons/face-very-happy.svg";

export const VerySadFace = () => <FaceIconVerySad width="24" height="24" />;
export const SadFace = () => <FaceIconSad width="24" height="24" />;
export const NeutralFace = () => <FaceIconNeutral width="24" height="24" />;
export const HappyFace = () => <FaceIconHappy width="24" height="24" />;
export const VeryHappyFace = () => <FaceIconVeryHappy width="24" height="24" />;
