import { ReactElement, cloneElement, useId, useMemo } from "react";

import { ReactComponent as ErrorIcon } from "./assets/error.svg";
import { ReactComponent as SuccessIcon } from "./assets/success.svg";
import { ReactComponent as WarningIcon } from "./assets/warning.svg";

export type ValidatableWrapperProps = {
  validationMessage?: string;
  validationState?: "success" | "error" | "warning";
};

export const ValidatableWrapper = ({ validationMessage, validationState, children }: ValidatableWrapperProps & { children: ReactElement }) => {
  const uniqueId = useId();
  const validationMessageId = !!validationMessage ? `validation-message-${uniqueId}` : undefined;
  const canShowMessage = !!validationState && !!validationMessage;

  const validationComponent = useMemo(() => {
    switch (validationState) {
      case "error":
        return (
          <div className="flex items-center">
            <ErrorIcon width={16} height={16} />
            <p id={validationMessageId} className="pl-1 text-sm text-m-red">
              {validationMessage}
            </p>
          </div>
        );
      case "warning":
        return (
          <div className="flex items-center">
            <WarningIcon width={16} height={16} />
            <p id={validationMessageId} className="pl-1 text-sm text-d-yellow">
              {validationMessage}
            </p>
          </div>
        );
      case "success":
        return (
          <div className="flex items-center">
            <SuccessIcon width={16} height={18} />
            <p id={validationMessageId} className="pl-1 text-sm text-m-green">
              {validationMessage}
            </p>
          </div>
        );
    }
  }, [validationMessage, validationMessageId, validationState]);

  return (
    <div className="mb-1">
      {cloneElement(children, { "aria-describedby": canShowMessage ? validationMessageId : undefined }, children.props.children)}
      <div className="flex items-end mt-auto min-h-[18px]">{canShowMessage ? validationComponent : <></>}</div>
    </div>
  );
};
