import { forwardRef } from "react";
import { lightenColour, darkenColour } from "shared/UI/colours";

export type CirclesProps = { baseColour: string } & React.HTMLAttributes<HTMLDivElement>;

export const Circles = forwardRef<HTMLDivElement, CirclesProps>(({ baseColour, ...rest }: CirclesProps, ref) => {
  const contrast = 25;
  return (
    <div ref={ref} {...rest}>
      <svg viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="46" cy="46" r="46" fill={lightenColour(baseColour, 3 * contrast)} />
        <circle cx="46" cy="46" r="41" fill={lightenColour(baseColour, 2 * contrast)} />
        <circle cx="46" cy="46" r="36" fill={lightenColour(baseColour, contrast)} />
        <circle cx="46" cy="46" r="29" fill={baseColour} />
        <circle cx="46" cy="46" r="21" fill={darkenColour(baseColour, contrast)} />
        <circle cx="46" cy="46" r="14" fill={darkenColour(baseColour, 2 * contrast)} />
        <circle cx="46" cy="46" r="8" fill={darkenColour(baseColour, 3 * contrast)} />
      </svg>
    </div>
  );
});
