import Input from "@brighthr/component-input";
import { forwardRef, LegacyRef, useRef, useState } from "react";
import classNames from "classnames";
import "./TimePicker.css";
import { ValueCallback } from "shared/core/types/callbacks";
import { Hideable } from "shared/UI/Hideable/Hideable";
import { SelectedTime } from "./SelectedTime";
import TimePeriod from "./TimePeriod";
import TimeFormat from "./TimeFormat";
import { useTranslation } from "react-i18next";

export type Time = {
  hour: number;
  minute: number;
};

export type TimePickerProps = {
  label: string;
  format: TimeFormat;
  defaultPeriod?: TimePeriod;
  onTimeSet: ValueCallback<Time | undefined>;
  expand?: boolean;
  onExpandChanged?: ValueCallback<boolean>;
  id?: string;
};

const allowedSpecialKeys: string[] = ["ArrowLeft", "ArrowRight", "Backspace", "Delete", "Tab"];

export const TimePicker = forwardRef<HTMLDivElement | undefined, TimePickerProps>(
  ({ label, onTimeSet, format, defaultPeriod = TimePeriod.AM, expand, onExpandChanged, id = "" }, ref) => {
    const { t } = useTranslation();
    const minuteInputRef = useRef<HTMLInputElement>(null);
    const [internalExpanded, setInternalExpanded] = useState(false);
    const [period, setPeriod] = useState<TimePeriod | undefined>(format === TimeFormat["12H"] ? defaultPeriod : undefined);
    const [hourInput, setHourInput] = useState<string | undefined>(undefined);
    const [minuteInput, setMinuteInput] = useState<string | undefined>(undefined);

    const selectedTime = new SelectedTime(hourInput, minuteInput, period);
    const maxHour = format === TimeFormat["24H"] ? 23 : 12;

    const isControlled = expand !== undefined;
    const isTimerExpanded = isControlled ? expand : internalExpanded;
    const setExpanded = (value: boolean) => (isControlled ? onExpandChanged?.(value) : setInternalExpanded(value));

    function onHourChanged(hour: string) {
      setHourInput(hour);
      onSelectedTimeChanged(new SelectedTime(hour, minuteInput, period));
    }

    function onMinuteChanged(minute: string) {
      setMinuteInput(minute);
      onSelectedTimeChanged(new SelectedTime(hourInput, minute, period));
    }

    function onSelectedTimeChanged(selectedTime: SelectedTime) {
      onTimeSet(selectedTime.isValid() ? (selectedTime.time as Time) : undefined);
    }

    function correctTimeInputValue(target: EventTarget & HTMLInputElement, maxValue: number) {
      if (target.value.length > target.maxLength) {
        target.value = target.value.slice(0, target.maxLength - 1) + target.value.slice(-1);
      }

      if (target.valueAsNumber > maxValue) {
        target.value = maxValue.toString();
      }
    }

    return (
      <div className="relative time-picker" ref={ref as LegacyRef<HTMLDivElement> | undefined}>
        <div className={classNames("relative m-0 border-collapse bg-white text-start time-picker-input", { "active-time-picker": isTimerExpanded })}>
          <Input
            id={`time-picker-${id}`}
            type="button"
            key={`time-picker-${format}`}
            value={selectedTime.getDisplay(format)}
            readOnly
            rightIconName="clock"
            onFocus={() => setExpanded(true)}
            onClick={() => setExpanded(!isTimerExpanded)}
          />
          <label htmlFor={`time-picker-${id}`} className="text-grey absolute top-0.5 left-2 text-xs select-none">
            {label}
          </label>
        </div>
        {isTimerExpanded && (
          <div className="w-full h-fit border-2 border-primary-400 border-t-0 rounded-b bg-white pt-3">
            <div
              className={classNames("flex flex-row h-20 mx-4  mb-4", {
                "justify-evenly": format === TimeFormat["12H"],
                "justify-center": format === TimeFormat["24H"],
              })}
            >
              <div className="flex flex-col">
                <div className="border-2 border-primary-400 rounded w-16 justify-center inline-block">
                  <input
                    id={`hour-input-${id}`}
                    type="number"
                    name={t("timePicker.hour")}
                    key={`hour-${format}-${period}`}
                    maxLength={2}
                    min={0}
                    max={maxHour}
                    pattern="[0-9]*"
                    className="w-full h-16 text-center text-3xl m-0"
                    defaultValue={selectedTime.getHourDisplay(format)}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus={true}
                    onChange={({ currentTarget: target }) => {
                      correctTimeInputValue(target, maxHour);
                      onHourChanged(target.value);
                      if (target.value.length >= target.maxLength) {
                        minuteInputRef.current?.focus();
                      }
                    }}
                    onKeyDown={(e) => {
                      const keyCode = Number(e.key);
                      if (isNaN(keyCode) && !allowedSpecialKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onBlur={(event) => {
                      switch (format) {
                        case TimeFormat["12H"]:
                          if (event.target.value.length === event.target.maxLength && event.target.value[0] === "0") {
                            event.target.value = event.target.value.slice(1);
                          }
                          break;
                        case TimeFormat["24H"]:
                          if (event.target.value.length === 1) {
                            event.target.value = `0${event.target.value}`;
                          }
                          break;
                      }
                    }}
                  ></input>
                </div>
                <label htmlFor={`hour-input-${id}`} className="text-sm pl-1">
                  {t("timePicker.hour")}
                </label>
              </div>
              <p className="text-7xl px-4 text-grey mt-[-10px]">:</p>
              <div className="flex flex-col">
                <div className="border-2 border-primary-400 rounded w-16">
                  <input
                    id={`minute-input-${id}`}
                    ref={minuteInputRef}
                    type="number"
                    name={t("timePicker.hour")}
                    key={`minute-${format}-${period}`}
                    defaultValue={selectedTime.getMinuteDisplay()}
                    maxLength={2}
                    className="w-full h-16 text-center text-3xl m-0"
                    onChange={({ target }) => {
                      correctTimeInputValue(target, 59);
                      onMinuteChanged(target.value);
                    }}
                    onKeyDown={(e) => {
                      const keyCode = Number(e.key);
                      if (isNaN(keyCode) && !allowedSpecialKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onBlur={({ target }) => {
                      if (target.value.length === 1) {
                        target.value = `0${target.value}`;
                      }
                    }}
                  ></input>
                </div>
                <label htmlFor={`minute-input-${id}`} className="text-sm pl-1">
                  {t("timePicker.minute")}
                </label>
              </div>
              <Hideable hidden={format === TimeFormat["24H"]}>
                <div className="ml-4 ">
                  <button
                    aria-label={t("timePicker.AMLabel")}
                    className={classNames("w-16 border-2 border-primary-700 rounded block mb-2 text-primary-700 font-bold", {
                      "bg-primary-700": period === TimePeriod.AM,
                      "text-white": period === TimePeriod.AM,
                    })}
                    onClick={() => {
                      setPeriod(TimePeriod.AM);
                      onSelectedTimeChanged(new SelectedTime(hourInput, minuteInput, TimePeriod.AM));
                    }}
                  >
                    AM
                  </button>
                  <button
                    aria-label={t("timePicker.PMLabel")}
                    className={classNames("w-16 border-2 border-primary-700 rounded block text-primary-700  font-bold", {
                      "bg-primary-700": period === TimePeriod.PM,
                      "text-white": period === TimePeriod.PM,
                    })}
                    onClick={() => {
                      setPeriod(TimePeriod.PM);
                      onSelectedTimeChanged(new SelectedTime(hourInput, minuteInput, TimePeriod.PM));
                    }}
                  >
                    PM
                  </button>
                </div>
              </Hideable>
            </div>
          </div>
        )}
      </div>
    );
  }
);
