import { PropsWithChildren } from "react";
import { useMatchMedia } from "shared/UI/Hooks/useMatchMedia";
import { ResponsiveBreakpoints } from "shared/UI/ResponsiveBreakpoints";
import { useTranslation } from "react-i18next";
import { ReactComponent as BackButtonIcon } from "shared/assets/Icons/back.svg";
import Icon from "@brighthr/component-icon";

export type FullScreenModalProps = {
  title: string;
  onClose: VoidFunction;
} & PropsWithChildren;

export const FullScreenModal = ({ title, children, onClose }: FullScreenModalProps) => {
  const { t } = useTranslation();
  const largerDisplay = useMatchMedia(ResponsiveBreakpoints.Tablet, false);
  return (
    <>
      {!largerDisplay && (
        <div data-testid="fullScreenModal" className="h-screen w-full z-MODAL fixed top-0 left-0 bg-primary-400">
          <div className="relative p-10 h-full text-white">
            <button className="pr-2" aria-label={t("navigation.backButton")} onClick={onClose}>
              <BackButtonIcon width="30" title={t("navigation.backButton")} />
            </button>
            {children}
          </div>
        </div>
      )}

      {largerDisplay && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          onClick={onClose}
          data-testid="background"
          className="h-screen w-full z-MODAL fixed top-0 left-0 bg-[#0f334d99] flex justify-center items-center cursor-default"
        >
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            // onKeyDown={handleKeyDown}
            className="flex flex-col relative m-4 transition-all bg-white shadow rounded-lg h-[90%] w-[740px] overflow-x-visible overflow-y-visible"
          >
            {title && <ModalHeader onClose={onClose} title={title} />}
            {children}
          </div>
        </div>
      )}
    </>
  );
};

type ModalHeaderProps = React.HTMLAttributes<HTMLDivElement> & {
  title: string;
  onClose: VoidFunction;
};

const ModalHeader = ({ title, onClose }: ModalHeaderProps): React.ReactElement => (
  <div className="flex justify-between items-center font-semibold rounded-t-lg py-[8px] px-6 bg-primary-700">
    <h1 className="text-base text-white">{title}</h1>

    <button aria-label="Close modal" type="button" onClick={onClose}>
      <Icon aria-hidden iconName="cross-thin" className="fill-white" size={28} />
    </button>
  </div>
);
