import { ValidatableWrapper, ValidatableWrapperProps } from "../ValidatableWrapper";
import DatePicker, { DatePickerProps } from "./DatePicker";

export type ValidatableDatePickerProps = {
  validationMessage?: string;
  validationState?: string;
} & DatePickerProps;

export const ValidatableDatePicker = ({
  validationMessage,
  validationState,
  ...props
}: ValidatableWrapperProps & Omit<DatePickerProps, "validationState" | "aria-describedby">): React.ReactElement => {
  return (
    <ValidatableWrapper validationMessage={validationMessage} validationState={validationState}>
      <DatePicker {...props} validationState={validationState} />
    </ValidatableWrapper>
  );
};
