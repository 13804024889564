import { FourWeekPlanSummaryWithProgress } from "shared/request/myHealthyAdvantageApi";
import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";
import { TitleCard } from "../TitleCard/TitleCard";
import { ReactComponent as RightChevron } from "shared/assets/Icons/right.svg";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "shared/extensions/string";

const hasProgress = (plan: FourWeekPlanSummaryWithProgress): boolean => {
  return plan.daysRemaining !== undefined;
};

export type FourWeekPlanListProps = {
  title: string;
  plans: FourWeekPlanSummaryWithProgress[];
};

export const FourWeekPlanList = ({ title, plans }: FourWeekPlanListProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const getProgressLabel = (daysRemaining: number) => {
    switch (daysRemaining) {
      case -1:
        return t("fourWeekPlans.progressCompleted");
      case 0:
        return t("fourWeekPlans.progressLastDay");
      default:
        return t("fourWeekPlans.progress", { daysRemaining });
    }
  };

  return (
    <TitleCard title={title}>
      {plans.map((plan) => {
        const daysRemaining = plan.daysRemaining! - 1;
        return (
          <div key={`plan-${plan.id}`} className="p-1">
            <button className="text-left w-full" onClick={() => navigate(`/resources/4-week-plans/${plan.id}`)}>
              <RoundedCard>
                <h4 className="cardTitle">{plan.name}</h4>
                {hasProgress(plan) && Number.isInteger(daysRemaining) && (
                  <div className="mb-3">
                    <div className="mb-2">{getProgressLabel(daysRemaining)}</div>
                    <ProgressBar percentageComplete={getProgressFromDaysRemaining(daysRemaining)} />
                  </div>
                )}

                <div className="flex items-center mb-4">
                  <p className="mr-4 grow">{plan.description}</p>
                  <div>
                    <RightChevron className="text-grey" height="24" width="12" />
                  </div>
                </div>
              </RoundedCard>
            </button>
          </div>
        );
      })}
    </TitleCard>
  );
};

export const getProgressFromDaysRemaining = (daysRemaining: number) => {
  return Math.min(((28 - daysRemaining) / 28) * 100, 100);
};
