export abstract class ClientCodeState {
  public static get(): string | undefined {
    return localStorage.getItem("clientCode") ?? undefined;
  }

  public static set(value: string) {
    localStorage.setItem("clientCode", value);
  }

  public static clear() {
    localStorage.removeItem("clientCode");
  }

  public static isDemoClient() {
    const clientCode = this.get();
    return ["HADEMO", "ACME"].includes(clientCode?.toUpperCase() ?? "");
  }
}
