import TagManager from "react-gtm-module";
import { DomainToGtmMap } from "shared/core/locale";

const initialiseGTM = () =>
  TagManager.initialize({
    gtmId: DomainToGtmMap[window.location.hostname] ?? "",
  });

const trackGAEvent = (name: string, properties?: Record<string, string>) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: name,
    ...properties,
  });
};

export { initialiseGTM, trackGAEvent };
