import { PropsWithChildren, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation } from "react-router-dom";
import { appInsightsLogger } from "core/monitoring/AppInsights";
import { useAuth } from "./useAuth";
import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import Spinner from "@brighthr/component-spinner";
import { DestinationState } from "shared/core/state/destinationState";
import "shared/extensions/string";
import { UserState } from "../state/userState";
import { useUserContext } from "../state/UserContext";

export const ProtectedRoute = ({ children }: PropsWithChildren): ReactElement => {
  const { t } = useTranslation();
  appInsightsLogger.info("useAuth - ProtectedRoute.tsx: protected route rendered");
  const auth = useAuth();
  const { isAdditionalInfoComplete } = useUserContext();
  const location = useLocation();

  if (auth.isLoading) {
    return <Spinner ariaLabel={t("login.inProgressMessage")} />;
  }
  if (auth.shouldAuthenticate) {
    DestinationState.set(location.pathname);
    return <Navigate to="/login" />;
  }

  if (!auth.isAuthenticated) {
    return <ErrorAlert title={t("login.error.title")} content="" />;
  }

  if (UserState.get() === undefined) {
    DestinationState.set(location.pathname);
    return <Navigate to="/auth" />;
  }

  if (!isAdditionalInfoComplete() && location.pathname !== "/additional-info") {
    DestinationState.set(location.pathname);
    return <Navigate to="/additional-info" />;
  }

  return <>{children}</>;
};

export function protect(el: ReactElement): ReactElement {
  return <ProtectedRoute>{el}</ProtectedRoute>;
}
