import { Location } from "react-router-dom";

export const selectedInterestPrefix = "si=";

export function getSelectedFiltersFromUrl(location: Location) {
  return location.hash
    .slice(1)
    .split(selectedInterestPrefix)
    .map((si) => si.replace("&", ""))
    .filter((si) => si.length > 0);
}
