import { UserResponseModel } from "shared/request/myHealthyAdvantageApi";

export abstract class UserState {
  public static get(): UserResponseModel | undefined {
    const user = window.authStorage?.getItem("user");
    return user ? JSON.parse(user) : undefined;
  }

  public static set(user: UserResponseModel) {
    window.authStorage?.setItem("user", JSON.stringify(user));
  }

  public static clear() {
    window.authStorage?.removeItem("user");
  }
}
