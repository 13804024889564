import { WisdomModule } from "core/Modules";
import { useUserContext } from "core/state/UserContext";

interface ProtectedModuleProps {
  wisdomModule: WisdomModule;
  moduleComponent: JSX.Element;
}

export const ProtectedModule = ({ wisdomModule, moduleComponent }: ProtectedModuleProps) => {
  const { isPermitted } = useUserContext();

  if (!isPermitted(wisdomModule)) {
    return null;
  }

  return moduleComponent;
};
