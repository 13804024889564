import { useTranslation } from "react-i18next";
import { BreathingExercise } from "shared/request/myHealthyAdvantageApi";
import { Circles } from "../Circles/Circles";
import { useTrackerContext } from "core/state/Trackers/TrackerContext";
import { TrackerType } from "core/state/Trackers/TrackerType";
import { useEffect } from "react";
import Button from "@brighthr/component-button";

export type BreathingExerciseResultsProps = {
  exercise: BreathingExercise;
  duration: number;
  onDone: VoidFunction;
};

export const BreathingExerciseResults = ({ exercise, duration, onDone }: BreathingExerciseResultsProps) => {
  const { t } = useTranslation();
  const { getCurrentValue, setCurrentValue, trackerValuesLoading: isLoading, trackerValuesError: hasError } = useTrackerContext();

  useEffect(() => {
    if (!isLoading && !hasError) {
      const currentMinutesOfBreathingExercises = getCurrentValue(TrackerType.BreathingExercises);
      setCurrentValue(TrackerType.BreathingExercises, currentMinutesOfBreathingExercises + duration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, hasError]);

  return (
    <div className="h-full w-full z-MODAL fixed top-0 left-0" style={{ background: exercise.animationColour.hex }}>
      <div className="relative h-full text-white w-full md:w-3/4 lg:w-1/2 p-10 md:pl-20 lg:pl-40">
        <div>
          <div className="flex flex-col items-center">
            <Circles baseColour={exercise.animationColour.hex} className="w-40 my-20" />
          </div>
          <h2 className="text-white">{t("breathingExercises.results.heading")}</h2>
          <p className="text-center">{t("breathingExercises.results.headingParagraph", { duration })}</p>
        </div>

        <div className="pt-5">
          <Button text={t("breathingExercises.results.doneButtonLabel")} color="primary" onClick={onDone} />
        </div>
      </div>
    </div>
  );
};
