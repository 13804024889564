export interface DateShortcuts {
  today: Date;
  yesterday: Date;
}

export function dateShortcuts(): DateShortcuts {
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return { today, yesterday };
}

const oneDay = 86400000;

export function daysSince(first: Date) {
  return Math.floor((Date.now() - first.valueOf()) / oneDay);
}

export function isSameDay(dateA: Date, dateB: Date) {
  return dateA.toDateOnlyString() === dateB.toDateOnlyString();
}

export function minutesBetween(dateA: Date, dateB: Date) {
  var timeDifference = Math.abs(dateB.getTime() - dateA.getTime());
  var minutesDifference = Math.floor(timeDifference / 1000 / 60);
  return minutesDifference;
}
