import "shared/extensions/string";

import { ClientLogo } from "shared/UI/ClientLogo/ClientLogo";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { useTranslation } from "react-i18next";

export type ConfirmClientProps = {
  clientLogo?: string;
  clientName: string;
  clientCode: string;
  handleNext: Function;
  handleReset: Function;
};

export const ConfirmClient = ({ clientLogo, clientName, clientCode, handleReset, handleNext }: ConfirmClientProps) => {
  const { t } = useTranslation();

  return (
    <>
      <h2 className="text-center" aria-hidden>
        {clientName}
      </h2>

      <ClientLogo clientCode={clientCode} imageFilename={clientLogo} alt={t("signup.logo", { clientName })} className="mb-3" />

      <StylableButton
        className="bright-button"
        fullWidth
        color="primary"
        text={t("signup.button")}
        aria-label={t("signup.buttonAriaLabel", { clientName })}
        onClick={() => handleNext()}
      />
      <div className="text-center mt-5">
        <p role="note">{t("signup.organisationQuestion")}</p>

        <button className="m-2 text-brand font-bold text-[14pt]" role="link" onClick={() => handleReset()}>
          {t("signup.changeOrganisationLink")}
        </button>
      </div>
    </>
  );
};
