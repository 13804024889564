import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmationModal } from "shared/UI/Modal/ConfirmationModal/ConfirmationModal";
import "shared/UI/Typeahead/Typeahead.css";
import { ActivityEntry, GeneralActivitySelector } from "./GeneralActivitySelector";
import { useTrackerContext } from "core/state/Trackers/TrackerContext";
import { TrackerType } from "core/state/Trackers/TrackerType";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { trackSaveHealthData } from "core/monitoring/Events";

export const GeneralActivityInputModal = ({ onClose }: { onClose: VoidFunction }) => {
  const [selectedActivities, setSelectedActivities] = useState<ActivityEntry[]>([
    {
      name: "",
      duration: 0,
    },
  ]);
  const { setCurrentValue, getCurrentValue } = useTrackerContext();
  const { t } = useTranslation();

  const previouslyLoggedDuration = getCurrentValue(TrackerType.Activity);
  const selectedActivityDuration = selectedActivities.reduce((acc, cur) => acc + (cur.duration ?? 0), 0);

  function onCancel() {
    onClose();
  }

  function onSave() {
    trackSaveHealthData(TrackerType.Activity);
    setCurrentValue(TrackerType.Activity, selectedActivityDuration + previouslyLoggedDuration);
    onClose();
  }

  return (
    <ConfirmationModal
      title={t("healthHub.trackers.activity.addData.heading")}
      cancelButtonText={t("healthHub.trackers.activity.addData.cancel")}
      confirmButtonText={t("healthHub.trackers.activity.addData.save")}
      headerColour="inverted"
      confirmButtonDisabled={selectedActivityDuration <= 0}
      onCancel={onCancel}
      onConfirm={onSave}
    >
      <div className="flex flex-col">
        <p className="mb-2">{t("healthHub.trackers.activity.addData.info")}</p>
        {selectedActivities.map((activity, index) => {
          return (
            <GeneralActivitySelector
              key={`${activity.name}-entry-${index}`}
              activityEntry={activity}
              onActivityChanged={(activity) => {
                const activitiesToUpdate = [...selectedActivities];
                activitiesToUpdate.splice(index, 1, activity);
                setSelectedActivities(activitiesToUpdate);
              }}
            />
          );
        })}
        <div className="justify-items-end">
          <span className="float-right mt-1">
            <StylableButton
              outlineButton
              color="primary"
              type="button"
              text={"+"}
              aria-label={t("healthHub.trackers.activity.addData.addActivityLabel")}
              onClick={() =>
                setSelectedActivities([
                  ...selectedActivities,
                  {
                    name: "",
                    duration: 0,
                  },
                ])
              }
            />
          </span>
        </div>
        <p className="mt-4 whitespace-pre-line" role="note">
          {t("healthHub.trackers.activity.addData.activitiesLoggedMessage", {
            current: selectedActivityDuration,
            previous: previouslyLoggedDuration,
            total: selectedActivityDuration + previouslyLoggedDuration,
          })}
        </p>
      </div>
    </ConfirmationModal>
  );
};
