import StylableButton from "shared/UI/Buttons/StylableButton";
import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";
import { PropsWithChildren } from "react";

export type CardWithButtonProps = PropsWithChildren & {
  buttonText: string;
  handleClick: Function;
  buttonClassName?: string;
  className?: string;
  buttonColour?: "primary" | "accent" | "error";
};

export const CardWithButton = ({ buttonColour, buttonClassName, className, buttonText, handleClick, children }: CardWithButtonProps) => {
  return (
    <RoundedCard className={className}>
      {children}
      <div className="mt-4">
        <StylableButton
          color={buttonColour}
          className={buttonClassName}
          fullWidth
          text={buttonText}
          aria-label={buttonText}
          onClick={() => handleClick()}
        />
      </div>
    </RoundedCard>
  );
};

CardWithButton.defaultProps = { showChevron: false, title: "" };
