import { useTranslation } from "react-i18next";
import { CompleteMiniHealthCheckCommand, MiniHealthCheck, MiniHealthCheckQuestionOption } from "shared/request/myHealthyAdvantageApi";
import { Modal } from "shared/UI/Modal/Modal";
import { useState } from "react";
import { QuestionManager } from "./QuestionManager";
import { MiniHealthCheckQuestionnaireIntro } from "./MiniHealthCheckQuestionnaireIntro";
import { MiniHealthCheckQuestionnaireQuestion } from "./MiniHealthCheckQuestionnaireQuestion";

export type MiniHealthCheckQuestionnaireProps = {
  miniHealthCheck: MiniHealthCheck;
  onClose: VoidFunction;
  onCompleted: (result: CompleteMiniHealthCheckCommand) => void;
};

export const MiniHealthCheckQuestionnaire = ({ miniHealthCheck, onClose, onCompleted }: MiniHealthCheckQuestionnaireProps) => {
  const { t } = useTranslation();
  const [scores, setScores] = useState({} as { [name: string]: number });
  const [displayIntro, setDisplayIntro] = useState(true);
  const [currentQuestion, setCurrentQuestion] = useState(miniHealthCheck.questions[0]);
  const [currentOption, setCurrentOption] = useState(undefined as MiniHealthCheckQuestionOption | undefined);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(1);
  const questionManager = new QuestionManager(miniHealthCheck.questions);

  const gotoFirstQuestion = () => {
    setDisplayIntro(false);
    setCurrentQuestion(questionManager.firstQuestion());
    setCurrentOption(undefined);
    setScores({});
    setCurrentQuestionNumber(1);
  };

  const hasNextQuestion = questionManager.hasNextQuestion(currentQuestion);

  const handleNext = () => {
    if (hasNextQuestion) {
      gotoNextQuestion();
    } else {
      completeQuestionnaire();
    }
  };

  const handlePrevious = () => {
    const previousQuestion = questionManager.getPreviousQuestion(currentQuestion);
    if (previousQuestion) {
      setCurrentQuestion(previousQuestion);
      setCurrentOption(undefined);
      setCurrentQuestionNumber(currentQuestionNumber - 1);
    } else {
      gotoFirstQuestion();
      setDisplayIntro(true);
    }
  };

  const gotoNextQuestion = () => {
    updateScores();
    setCurrentQuestionNumber(currentQuestionNumber + 1);
    setCurrentQuestion(questionManager.getNextQuestion(currentQuestion, currentOption)!);
    setCurrentOption(undefined);
  };

  const completeQuestionnaire = () => {
    updateScores();
    let totalScore = 0;
    Object.entries(scores).forEach(([, value]) => {
      totalScore += value;
    });

    let result = miniHealthCheck.results[0];
    miniHealthCheck.results.forEach((r) => {
      if (totalScore >= r.minScore && totalScore <= r.maxScore) {
        result = r;
      }
    });

    onCompleted({ checkId: miniHealthCheck.id, resultId: result.id, score: totalScore, name: miniHealthCheck.name });
  };

  const updateScores = () => {
    const newScores = scores;
    newScores[currentQuestion.id] = currentOption!.score ?? 0;
    setScores(newScores);
  };

  const modelTitle = displayIntro
    ? t("completeMiniHealthCheck.questionnaireInfoHeading")
    : t("completeMiniHealthCheck.questionnaireProgress", {
        current: currentQuestionNumber,
        count: questionManager.getCount(),
      });
  const nextButtonText = hasNextQuestion ? t("navigation.nextButton") : t("navigation.submitButton");
  return (
    <Modal title={modelTitle} width="base" headerSize="2xl" close={onClose}>
      <>
        {displayIntro ? (
          <MiniHealthCheckQuestionnaireIntro intro={miniHealthCheck.questionsIntro} onCancel={onClose} onNext={gotoFirstQuestion} />
        ) : (
          <MiniHealthCheckQuestionnaireQuestion
            currentQuestion={currentQuestion}
            currentOption={currentOption}
            nextButtonText={nextButtonText}
            onPrevious={handlePrevious}
            onNext={handleNext}
            onSelectOption={setCurrentOption}
          />
        )}
      </>
    </Modal>
  );
};
