export const lightenColour = (hexColour: string, amt: number) => {
  let usePound = false;
  if (hexColour[0] === "#") {
    hexColour = hexColour.slice(1);
    usePound = true;
  }

  const roundColour = (n: number) => (n > 255 ? 255 : n < 0 ? 0 : n);
  const num = parseInt(hexColour, 16);

  const red = roundColour((num >> 16) + amt);
  const blue = roundColour(((num >> 8) & 0x00ff) + amt);
  const green = roundColour((num & 0x0000ff) + amt);

  return (usePound ? "#" : "") + (green | (blue << 8) | (red << 16)).toString(16);
};

export const darkenColour = (hexColour: string, amt: number) => lightenColour(hexColour, -amt);
