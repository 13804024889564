import { Platform, useMobilePlatform } from "core/hooks/useMobilePlatform";
import { getAppVersion } from "core/javascriptChannels";
import { externalLink } from "core/links";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { satisfies } from "semver";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { Modal, ModalBody, ModalFooter } from "shared/UI/Modal/Modal";

export const AppVersionModal = () => {
  const [showAppVersionModal, setShowAppVersionModal] = useState<boolean>(false);
  const [showRequiredAppVersion, setShowRequiredAppVersion] = useState<boolean>(false);

  const { isMobileApp, platform } = useMobilePlatform();

  useEffect(() => {
    const getMobileAppVersion = async () => {
      const currentAppVersion = await getAppVersion();
      if (!satisfies(currentAppVersion, process.env.REACT_APP_MIN_MOBILE_APP_VERSION!)) {
        setShowAppVersionModal(true);
        setShowRequiredAppVersion(true);
      } else if (!satisfies(currentAppVersion, process.env.REACT_APP_SUGGESTED_MOBILE_APP_VERSION!)) {
        setShowAppVersionModal(true);
      }
    };

    if (isMobileApp) {
      getMobileAppVersion();
    }
  }, [isMobileApp]);

  let url = "#";
  switch (platform) {
    case Platform.android:
      url = process.env.REACT_APP_WISDOM_PLAYSTORE_URI!;
      break;
    case Platform.ios:
      url = process.env.REACT_APP_WISDOM_APPSTORE_URI!;
      break;
  }

  const dismissAction = () => {
    if (!showRequiredAppVersion) {
      setShowAppVersionModal(false);
    }
  };

  return (
    <>
      {isMobileApp && showAppVersionModal && (
        <Modal
          title={showRequiredAppVersion ? t("appVersionModel.required.title") : t("appVersionModel.suggested.title")}
          width="xl"
          close={dismissAction}
          bgDismiss={!showRequiredAppVersion}
          hideDismiss={showRequiredAppVersion}
        >
          <ModalBody>
            <p>{showRequiredAppVersion ? t("appVersionModel.required.description") : t("appVersionModel.suggested.description")}</p>
          </ModalBody>
          <ModalFooter className="gap-4">
            <StylableButton
              className="[&>*]:w-full flex-grow"
              text={showRequiredAppVersion ? t("appVersionModel.required.update") : t("appVersionModel.suggested.update")}
              color="primary"
              onClick={() => externalLink(url, false)}
            />
            {!showRequiredAppVersion && (
              <StylableButton className="[&>*]:w-full flex-grow" text={t("appVersionModel.suggested.later")} color="accent" onClick={dismissAction} />
            )}
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};
