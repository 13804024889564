import { CurvedTopBanner } from "UIPalette/CurvedTopBanner/CurvedTopBanner";
import { TwoColumnLayout } from "UIPalette/Layout/TwoColumnLayout";
import { PageHeader } from "UIPalette/PageHeader/PageHeader";
import { TrackerAchievements } from "UIPalette/TrackerAchievements/TrackerAchievements";
import { TrackerFourWeekPlans } from "UIPalette/TrackerFourWeekPlans/TrackerFourWeekPlans";
import { TrackerLearningMaterials } from "UIPalette/TrackerLearningMaterials/TrackerLearningMaterials";
import { useTrackerContext } from "core/state/Trackers/TrackerContext";
import { TrackerType } from "core/state/Trackers/TrackerType";
import { PropsWithChildren } from "react";
import { SyncTrackerDataButton } from "./SyncTrackerDataButton";

export type TrackerProps = {
  trackerType: TrackerType;
  heading: string;
  isSyncable: boolean;
} & PropsWithChildren;

export const Tracker = ({ trackerType, heading, isSyncable, children }: TrackerProps) => {
  const { getFourWeekPlanIds, getLearningMaterialCategoryIds } = useTrackerContext();

  return (
    <>
      <CurvedTopBanner>
        <PageHeader title={heading} backRoute={"/health-hub"} />
      </CurvedTopBanner>
      <TwoColumnLayout>
        <TwoColumnLayout.Left>
          {children}
          {isSyncable && <SyncTrackerDataButton trackerType={trackerType} />}
          <TrackerAchievements trackerType={trackerType} />
        </TwoColumnLayout.Left>
        <TwoColumnLayout.Right>
          <TrackerFourWeekPlans planIdsFilter={getFourWeekPlanIds(trackerType)} />
          <TrackerLearningMaterials categoryIdsFilter={getLearningMaterialCategoryIds(trackerType)} />
        </TwoColumnLayout.Right>
      </TwoColumnLayout>
    </>
  );
};
