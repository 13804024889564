import Button from "@brighthr/component-button";
import { Modal, ModalBody, ModalFooter } from "shared/UI/Modal/Modal";
import { useTranslation } from "react-i18next";

export type InfoModalProps = {
  title: string;
  message: String;
  onDismiss: VoidFunction;
};

export const InfoModal = ({ title, message, onDismiss }: InfoModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal title={title} width="xs" headerSize="base" close={() => {}} hideDismiss={true}>
      <ModalBody>
        <div className="h-40 pb-4 whitespace-pre-line">
          <p>{message}</p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button text={t("modals.okayButtonLabel")} outlineButton color="primary" onClick={() => onDismiss()} />
      </ModalFooter>
    </Modal>
  );
};
