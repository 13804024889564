import { NavLink, Outlet } from "react-router-dom";

import HealthAssuredLogo from "shared/assets/HealthAssuredLogo.png";
import { ManualLocaleState } from "core/hooks/useLocale/useLocale";
import { t } from "i18next";
import { useEffect } from "react";
import { useModalContext } from "core/state/ModalContext/ModalContext";

export const LoginInfoLayout = () => {
  const { triggerModal } = useModalContext();

  useEffect(() => {
    triggerModal("localeModal", true);
  }, [triggerModal]);

  return (
    <div className="flex flex-col min-h-screen gap-0 overflow-clip">
      <header role="banner" className="flex flex-row bg-white h-20 w-full sticky top-0 z-OVERLAY drop-shadow-md md:px-[4rem]">
        <NavLink
          className="flex items-center flex-grow w-4/6 h-full px-3 lg:flex-grow-0 xs:pointer-events-none lg:pointer-events-auto sm:w-auto"
          aria-label={t("navigation.homeNavigationLabel")}
          to={"/"}
        >
          <img alt={t("navigation.healthAssuredlogo")} src={HealthAssuredLogo} className="h-full max-h-14" />
        </NavLink>
      </header>
      <main role="main">
        <div className="flex flex-col w-full mx-auto lg:max-w-[1761px] mb-6 px-6 md:px-[5rem]">{ManualLocaleState.get() && <Outlet />}</div>
      </main>
    </div>
  );
};
