import { t } from "i18next";
import { ReactComponent as ResourcesIcon } from "shared/assets/Icons/resources.svg";
import { ReactComponent as FilledResourcesIcon } from "shared/assets/Icons/resources-filled.svg";
import { useNavigate } from "react-router-dom";

export const BottomNavResourcesButton = ({ active }: { active: boolean }) => {
  const navigateHook = useNavigate();

  return (
    <button
      className="flex flex-col items-center text-brand font-bold text-[14pt] mx-1"
      aria-label={t("navigation.resourcesNavigationLabel")}
      onClick={() => {
        navigateHook("/resources");
      }}
    >
      {active ? <FilledResourcesIcon /> : <ResourcesIcon />}
      <p className="mt-1">{t("navigation.resourcesButton")}</p>
    </button>
  );
};
