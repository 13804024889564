import React, { useEffect, useState } from "react";

import Icon from "@brighthr/component-icon";
import cn from "classnames";
import { createPortal } from "react-dom";

export type headerSizeTypes = "sm" | "base" | "lg" | "xl" | "2xl" | "3xl";
export type headerColour = "primary" | "inverted";

type ModalHeaderProps = React.HTMLAttributes<HTMLDivElement> & {
  title: string;
  hideDismiss?: boolean;
  headerSize?: headerSizeTypes;
  close: () => void;
  color?: headerColour;
};

const ModalHeader = ({ title, hideDismiss = false, headerSize = "lg", color = "primary", close }: ModalHeaderProps): React.ReactElement => (
  <div
    className={cn("flex justify-between items-center font-semibold rounded-t-lg py-[8px] px-6", {
      "bg-primary-700": color === "primary",
      "bg-white": color === "inverted",
    })}
  >
    <h1
      className={cn(
        `text-${headerSize}`,
        {
          "text-white": color === "primary",
          "text-black": color === "inverted",
        },
        "pb-0"
      )}
    >
      {title}
    </h1>
    {!hideDismiss && (
      <button aria-label="Close modal" type="button" onClick={close}>
        <Icon
          aria-hidden
          iconName="cross-thin"
          className={cn({
            "fill-white": color === "primary",
            "fill-black": color === "inverted",
          })}
          size={28}
        />
      </button>
    )}
  </div>
);

export type ModalBodyProps = React.HTMLAttributes<HTMLDivElement> & {
  children?: React.ReactNode;
};

export const ModalBody = ({ children, ...props }: ModalBodyProps) => {
  if (!children) {
    return null;
  }

  return (
    <div className="block h-auto p-6 overflow-x-hidden overflow-y-auto bg-white" {...props}>
      {children}
    </div>
  );
};

export type ModalFooterProps = React.HTMLAttributes<HTMLDivElement> & {
  className?: string;
  withoutBG?: boolean;
  children?: React.ReactNode;
};

export const ModalFooter = ({ className, withoutBG = false, children }: ModalFooterProps) => {
  if (!children) {
    return null;
  }
  return (
    <div
      className={cn(
        "flex sticky bottom-0 left-0 justify-between items-center rounded-b-lg px-6 py-4",
        {
          "bg-white": withoutBG,
          "bg-neutral-100": !withoutBG,
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export type widthTypes = "xs" | "sm" | "base" | "lg" | "xl";

export type ModalProps = React.HTMLAttributes<HTMLDivElement> & {
  headerSize?: headerSizeTypes;
  close: () => void;
  title?: string;
  allowOverflow?: boolean;
  color?: "primary" | "inverted";
  bgDismiss?: boolean;
  width?: widthTypes;
  hideDismiss?: boolean;
  children?: React.ReactNode;
};

export const Modal = ({
  headerSize = "lg",
  color = "primary",
  close,
  title = "",
  allowOverflow = false,
  hideDismiss = false,
  bgDismiss = true,
  width = "base",
  children,
  ...props
}: ModalProps) => {
  const [createdElement, setCreatedElement] = useState<HTMLDivElement>();

  const dismiss = () => {
    if (bgDismiss) {
      close();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Escape") {
      dismiss();
      e.preventDefault();
    }
  };

  useEffect(() => {
    const keyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        dismiss();
        e.preventDefault();
      }
    };

    document.addEventListener("keydown", keyDown);

    const randomId = Math.random().toString();
    const wrapperElement = document.createElement("div");
    wrapperElement.setAttribute("id", `modal${randomId}`);
    document.body.appendChild(wrapperElement);
    setCreatedElement(wrapperElement);
    document.body.style.overflow = "hidden";

    return () => {
      document.removeEventListener("keydown", keyDown);
      wrapperElement?.parentNode?.removeChild(wrapperElement);
      document.body.style.overflow = "auto";
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sizes = {
    xs: "w-[360px]",
    sm: "w-[540px]",
    base: "w-[740px]",
    lg: "w-[900px]",
    xl: "w-[1280px]",
  };

  const selectedWidth = sizes[width];

  if (!createdElement) {
    return null;
  }

  return createPortal(
    <>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        onClick={dismiss}
        onKeyDown={handleKeyDown}
        {...props}
        data-testid="background"
        className="fixed flex inset-0 overscroll-none w-full z-[9998] bg-[#0f334d99] justify-center items-center cursor-default"
      >
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          onKeyDown={handleKeyDown}
          className={cn("flex flex-col relative m-4 h-auto transition-all bg-white shadow rounded-lg max-h-[90%]", selectedWidth, {
            "overflow-x-visible overflow-y-visible": allowOverflow,
            "overflow-x-hidden overflow-y-auto": !allowOverflow,
          })}
        >
          {title && <ModalHeader headerSize={headerSize} color={color} close={close} title={title} hideDismiss={hideDismiss} />}
          {children}
        </div>
      </div>
    </>,
    createdElement
  );
};
