import { t } from "i18next";
import { ReactElement, useState } from "react";
import { LearningMaterialSummary, useSearchLearningMaterialsFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { useNavigate } from "react-router-dom";
import useComponentVisible from "shared/UI/Hooks/useComponentVisible";
import { useDebounce } from "shared/core/hooks/useDebounce";
import { ReactComponent as PodcastIcon } from "shared/assets/Icons/podcast.svg";
import { ReactComponent as ArticleIcon } from "shared/assets/Icons/article.svg";
import { ReactComponent as VideoIcon } from "shared/assets/Icons/video.svg";
import { ReactComponent as SearchIcon } from "shared/assets/Icons/search.svg";
import { trackEvents } from "core/monitoring/Events";
import { EventNames } from "core/monitoring/types/enums";

export const SearchBar = (): ReactElement => {
  const [searchTerm, setSearchTerm] = useState("");
  const { targetAreaRef, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const navigate = useNavigate();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const { data: searchResults } = useSearchLearningMaterialsFunctionRequest(
    { maxResults: 15, text: debouncedSearchTerm },
    { shouldFetch: !!debouncedSearchTerm && debouncedSearchTerm.length > 2 }
  );

  const onTextChange = (text: string) => {
    setSearchTerm(text);
  };

  const onSearchResultClicked = (articleId: string) => {
    trackEvents(EventNames.NAV_SEARCH, { query: searchTerm, value: articleId });
    setIsComponentVisible(false);
    setSearchTerm("");
    navigate(`/resources/articles/${articleId}`);
  };

  const getArticleImage = (type: string): ReactElement => {
    const imageTitle = t<string>(`navigation.search.articleType-${type}`);
    switch (type) {
      case "video":
        return <VideoIcon height={20} width={30} title={imageTitle} />;
      case "podcast":
        return <PodcastIcon height={20} width={30} title={imageTitle} />;
      case "article":
      default:
        return <ArticleIcon height={20} width={30} title={imageTitle} />;
    }
  };

  const getArticleSubText = (article: LearningMaterialSummary): string => {
    const articleTypeText = t<string>(`navigation.search.articleType-${article.articleType}`);
    const durationText = article.duration ? ` - ${t("navigation.search.articleDuration", { duration: article.duration })}` : "";
    return articleTypeText + durationText;
  };

  return (
    <>
      {!isComponentVisible ? (
        <button
          aria-label={t("navigation.search.searchButtonAriaLabel")}
          className="float-right h-full align-middle mx-5 mt-0 text-primary-700"
          onClick={() => setIsComponentVisible(true)}
        >
          <SearchIcon title={t("navigation.search.searchBarIconLabel")} className="h-7 w-7" />
        </button>
      ) : (
        <div ref={targetAreaRef} className="h-full top-0 absolute align-middle w-full md:w-1/2 lg:w-1/3 md:static md:float-right bg-white z-40">
          <div className="p-2 flex items-center h-full relative">
            <input
              className="w-full border-solid border-2 font-semibold text-neutral-700 outline-none placeholder:font-normal placeholder:text-neutral-400 border-neutral-300 focus-visible:border-primary-300 rounded-full p-3 pr-12 m-0"
              type="text"
              aria-label={t("navigation.search.searchInputLabel")}
              onChange={(e) => onTextChange(e.target.value)}
              value={searchTerm}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={true}
            />
            <SearchIcon className="text-primary-700 absolute right-0 mr-5 h-7 w-7" title={t("navigation.search.searchBarIconLabel")} />
          </div>
          <div className="bg-white w-full max-h-[50vh] overflow-y-auto shadow-bottom">
            {searchResults?.map((result) => (
              <button
                key={result.id}
                className="text-left block px-2 pb-3 w-full hover:bg-primary-200 active:bg-primary-200 focus:bg-primary-200"
                aria-label={t("navigation.search.searchResultLabel", { result: result.title })}
                onClick={() => onSearchResultClicked(result.id)}
              >
                <p className="pl-1">{result.title}</p>
                <div className="text-sm">
                  <span className="float-left text-primary-700">{getArticleImage(result.articleType)}</span>
                  <p className="float-left">{getArticleSubText(result)}</p>
                </div>
              </button>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
