import { PropsWithChildren, useId } from "react";

import theme from "../../../../../theme";

enum DonutColourThresholdPercentages {
  Medium = 40,
  High = 70,
}

export type DonutProps = PropsWithChildren & {
  percentage: number;
  thickness: number;
};

export const Donut = ({ percentage, thickness, children }: DonutProps) => {
  function getDonutColour(percentage: number) {
    if (percentage > DonutColourThresholdPercentages.High) return "forest";
    if (percentage >= DonutColourThresholdPercentages.Medium) return "m-yellow";
    return "scarlet";
  }

  const donutUid = useId().replaceAll(":", "");
  const bgColour = theme.colours["silver"];
  const colour = theme.colours[getDonutColour(percentage)];
  const donutAnimationId = "donutAnimation-" + donutUid;
  const donutSegmentAnimationId = "donutSegmentAnimation-" + donutUid;
  const diameterOfCircleWithCircumferenceOf100 = 15.91549430918954;

  return (
    <div style={{ position: "relative" }}>
      <svg width="100%" height="100%" viewBox="0 0 40 40">
        <circle cx="20" cy="20" r={diameterOfCircleWithCircumferenceOf100} fill="transparent" stroke={bgColour} strokeWidth={thickness}></circle>
        <circle
          className={donutAnimationId}
          cx="20"
          cy="20"
          r="15.91549430918954"
          stroke={colour}
          strokeWidth={thickness}
          strokeDasharray={`${percentage} ${100 - percentage}`}
          strokeDashoffset="25"
          fill="transparent"
        />
        <style>
          {`
            .${donutAnimationId} {
              animation: ${donutSegmentAnimationId} 1.5s forwards;
              animationIterationCount: 1;
            }

            @keyframes ${donutSegmentAnimationId} {
              0% {
                stroke-dasharray: 0, 100;
              }
              30% {
                stroke-dasharray: 0, 100;
              }
              100% {
                stroke-dasharray: ${percentage}, ${100 - percentage};
              }
            }
        `}
        </style>
      </svg>
      <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>{children}</div>
    </div>
  );
};

Donut.defaultProps = { thickness: 5 };
