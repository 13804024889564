import TimeFormat from "./TimeFormat";
import TimePeriod from "./TimePeriod";

export class SelectedTime {
  private readonly defaultDisplay = "--";
  hour: number | undefined;
  hourString: string | undefined;
  minute: number | undefined;
  minuteString: string | undefined;
  initialPeriod: TimePeriod | undefined;

  constructor(hourString: string | undefined, minuteString: string | undefined, initialPeriod?: TimePeriod) {
    this.hourString = hourString;
    this.minuteString = minuteString;
    this.initialPeriod = initialPeriod;
    this.hour = this.parseHour(this.hourString, initialPeriod);
    this.minute = this.parseMinute(this.minuteString);
  }

  public isValid() {
    return this.hour !== undefined && this.minute !== undefined;
  }

  private parseMinute(minuteString: string | undefined): number | undefined {
    const minuteAsNumber = Number(minuteString);
    if (minuteString === undefined || minuteString === "" || isNaN(minuteAsNumber)) {
      return undefined;
    }
    return minuteAsNumber >= 0 && minuteAsNumber < 60 ? minuteAsNumber : undefined;
  }

  private parseHour(hourString: string | undefined, period: TimePeriod | undefined): number | undefined {
    const hourAsNumber = Number(hourString);

    if (hourString === undefined || hourString === "" || isNaN(hourAsNumber)) {
      return undefined;
    }

    const isValid12HourTime = hourAsNumber > 0 && hourAsNumber < 12;
    const isValid24HourTime = hourAsNumber >= 0 && hourAsNumber < 24;

    switch (period) {
      case TimePeriod.AM:
        if (isValid12HourTime) {
          return hourAsNumber;
        }
        if (hourAsNumber === 12) {
          return 0;
        }
        break;
      case TimePeriod.PM:
        if (isValid12HourTime) {
          return hourAsNumber + 12;
        }
        if (hourAsNumber === 12) {
          return hourAsNumber;
        }
        break;
      default:
        return isValid24HourTime ? hourAsNumber : undefined;
    }
  }

  public get time() {
    return { hour: this.hour, minute: this.minute };
  }

  public getDisplay(format: TimeFormat) {
    const periodDisplay = format === TimeFormat["12H"] && this.initialPeriod !== undefined ? ` ${TimePeriod[this.initialPeriod]}` : "";
    return `${this.getHourDisplay(format) ?? "--"}:${this.getMinuteDisplay() ?? "--"}${periodDisplay}`;
  }

  public getHourDisplay(format: TimeFormat) {
    if (this.hour === undefined) {
      return undefined;
    }

    if (format === TimeFormat["24H"]) {
      return this.hour! < 10 ? `0${this.hour}` : `${this.hour}`;
    }

    return this.hourString;
  }

  public getMinuteDisplay() {
    if (this.minute === undefined) {
      return undefined;
    }

    return this.minute < 10 ? `0${this.minute}` : `${this.minute}`;
  }
}
