import { useEffect, useState } from "react";
import { ClientCodeState } from "shared/core/state/clientCodeState";
import {
  useDeleteUserTrackerValueFunctionRequest,
  useListUserCurrentTrackerValuesFunctionRequest,
  UserCurrentTrackerValue,
  useSetUserTrackerGoalFunctionRequest,
  useSetUserTrackerValueFunctionRequest,
} from "shared/request/myHealthyAdvantageApi";
import { TrackerType } from "./TrackerType";
import "shared/extensions/date";
import { toastNotify } from "shared/UI/Toaster/Toaster";
import { useTranslation } from "react-i18next";
import { isSameDay, minutesBetween } from "core/helpers/dates";

export type TrackerValue = { current: number; goal: number };
export type TrackerMap = { [trackerType: number]: TrackerValue };

const NoMoodSet = 0;

const defaultGoals = {
  water: 2000,
  steps: 10_000,
  sleep: 8,
  activity: 30,
} as const;

export const getDefaultTrackerValues = (): TrackerMap => {
  return {
    [TrackerType.Mood]: { current: 0, goal: NoMoodSet },
    [TrackerType.Sleep]: { current: 0, goal: defaultGoals.sleep },
    [TrackerType.Steps]: { current: 0, goal: defaultGoals.steps },
    [TrackerType.Activity]: { current: 0, goal: defaultGoals.activity },
    [TrackerType.Water]: { current: 0, goal: defaultGoals.water },
    [TrackerType.BreathingExercises]: { current: 0, goal: 0 },
  };
};

const mapResponseDataToTrackerMap = (data: UserCurrentTrackerValue[], currentState: TrackerMap) =>
  data.reduce((acc, val) => {
    const goal = val.goal === 0 ? getDefaultTrackerValues()[val.trackerType].goal : val.goal;
    acc[val.trackerType] = { current: val.value ?? 0, goal: goal };
    return acc;
  }, currentState);

export const useTrackersState = () => {
  const clientCode = ClientCodeState.get();
  const [lastTrackerRefresh, setLastTrackerRefresh] = useState(new Date());
  const { t } = useTranslation();
  const [trackerValues, setTrackerValues] = useState<TrackerMap>(getDefaultTrackerValues);
  const { data: trackersData, isLoading, error, mutate } = useListUserCurrentTrackerValuesFunctionRequest({ xClientCode: clientCode });
  const { trigger: setTrackerValueTrigger } = useSetUserTrackerValueFunctionRequest({ xClientCode: clientCode });
  const { trigger: setTrackerGoalTrigger } = useSetUserTrackerGoalFunctionRequest({ xClientCode: clientCode });
  const { trigger: deleteTrackerValueTrigger } = useDeleteUserTrackerValueFunctionRequest({ xClientCode: clientCode });

  async function setTrackerCurrentValue(trackerType: TrackerType, value: number) {
    const currentValueHasChanged = trackerValues[trackerType].current !== value;
    if (currentValueHasChanged) {
      try {
        await setTrackerValueTrigger({
          body: {
            trackerType,
            date: new Date().toDateOnly(),
            value,
            manual: true,
          },
        });

        const stateToUpdate = { ...trackerValues };
        stateToUpdate[trackerType].current = value;
        setTrackerValues(stateToUpdate);
      } catch {
        toastNotify(t("healthHub.trackers.setCurrentError"), "error");
      }
    }
  }

  async function setTrackerGoal(trackerType: TrackerType, goal: number) {
    const goalHasChanged = trackerValues[trackerType].goal !== goal;
    if (goalHasChanged) {
      try {
        await setTrackerGoalTrigger({ body: { trackerType, goal: goal } });

        const stateToUpdate = { ...trackerValues };
        stateToUpdate[trackerType].goal = goal;
        setTrackerValues(stateToUpdate);
      } catch {
        toastNotify(t("healthHub.trackers.setGoalError"), "error");
      }
    }
  }

  async function clearTrackerCurrentValue(trackerType: TrackerType) {
    try {
      await deleteTrackerValueTrigger({
        query: {
          trackerType: TrackerType[trackerType],
          date: new Date().toDateOnlyString(),
        },
      });
      const stateToUpdate = { ...trackerValues };
      stateToUpdate[trackerType].current = 0;
      setTrackerValues(stateToUpdate);
    } catch {
      toastNotify(t("healthHub.trackers.clearTrackerError"), "error");
    }
  }

  useEffect(() => {
    const minuteInterval = setInterval(async () => {
      const now = new Date();
      if (!isSameDay(lastTrackerRefresh, now) || minutesBetween(lastTrackerRefresh, now) >= 5) {
        await mutate();
        setLastTrackerRefresh(now);
      }
    }, 60000);
    return () => clearInterval(minuteInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (trackersData) {
      const newTrackeValues = mapResponseDataToTrackerMap(trackersData, trackerValues);
      setTrackerValues(newTrackeValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackersData]);

  return {
    trackerValues,
    isLoading,
    hasError: !!error,
    setTrackerCurrentValue,
    setTrackerGoal,
    clearTrackerCurrentValue,
    refreshTrackerValues: mutate,
  };
};
