import "shared/extensions/date";
import "./Leaderboard.css";
import { useGetLeaderboardFunctionRequest, useGetLeaderboardTemplateFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { useParams } from "react-router-dom";
import { LeaderboardDisplay } from "./LeaderboardDisplay";
import { useTranslation } from "react-i18next";
import { ViewStateWrapper } from "UIPalette/ViewStateWrapper/ViewStateWrapper";
import { PageHeader } from "UIPalette/PageHeader/PageHeader";
import { CurvedTopBanner } from "UIPalette/CurvedTopBanner/CurvedTopBanner";
import { NotFound } from "../../../../Views/NotFound";
import PageTitle from "UIPalette/PageTitle/PageTitle";

export const Leaderboard = () => {
  const { t } = useTranslation();
  const { code } = useParams();
  const isCodeValid = code !== undefined && /^[XCJQ4D1TMB8G6R52A9E3Z7FV0HKNPSOW-]+$/.test(code);

  const { data, isLoading, error, mutate } = useGetLeaderboardFunctionRequest({ leaderboardCode: code }, { shouldFetch: isCodeValid });
  const templateResponse = useGetLeaderboardTemplateFunctionRequest({ id: data?.templateId! }, { shouldFetch: !!data?.templateId });

  if (isLoading || !!error || !isCodeValid) {
    return (
      <>
        <PageTitle title={t("pageTitle.leaderboards.home")} />
        <CurvedTopBanner>
          <PageHeader title={t("leaderboards.display.defaultPageHeader")} backRoute={-1} />
        </CurvedTopBanner>
        <ViewStateWrapper loading={isLoading} error={!!error} errorMessage={t("leaderboards.display.error")} onRetry={mutate} isFullScreenView={true}>
          <NotFound />
        </ViewStateWrapper>
      </>
    );
  }

  const displayData = {
    id: data?.id!,
    templateId: data?.templateId!,
    name: data?.name!,
    trackerType: data?.trackerType!,
    code: data?.code!,
    startDate: data?.startDate!,
    endDate: data?.endDate!,
    participants: data?.participants!,
    description: templateResponse?.data?.description,
    isTemplateLoading: templateResponse?.isLoading,
  };

  return (
    <>
      <PageTitle title={t("pageTitle.leaderboards.code", { code: data?.name ?? "" })} />
      <LeaderboardDisplay {...displayData} />
    </>
  );
};
