import { t } from "i18next";

export function useGreeting() {
  const currentHour = new Date().getHours();

  if (currentHour >= 0 && currentHour < 12) {
    return t("greetings.morning");
  } else if (currentHour >= 12 && currentHour <= 17) {
    return t("greetings.afternoon");
  } else {
    return t("greetings.evening");
  }
}
