import { TrackerType } from "core/state/Trackers/TrackerType";
import { TrackerProgressTile, TrackerProgressTileProps } from "../TrackerProgressTile";
import { ReactComponent as SleepIcon } from "shared/assets/Icons/sleep.svg";
import { useTranslation } from "react-i18next";

interface SleepTrackerProgressTileProps {
  inCard?: boolean;
  size?: TrackerProgressTileProps["size"];
}

export const SleepTrackerProgressTile = ({ inCard, size = "lg" }: SleepTrackerProgressTileProps) => {
  const { t } = useTranslation();
  return (
    <TrackerProgressTile
      trackerType={TrackerType.Sleep}
      trackerPagePath="sleep-tracker"
      Icon={SleepIcon}
      caption={t("healthHub.trackers.sleep.caption")}
      wrapInCard={inCard ?? true}
      size={size}
    />
  );
};
