import { DateOnly } from "shared/request/myHealthyAdvantageApi";
import StylableButton from "shared/UI/Buttons/StylableButton";
import "shared/extensions/date";
import { daysSince } from "core/helpers/dates";
import { t } from "i18next";
import { Hideable } from "shared/UI/Hideable/Hideable";
import { useState } from "react";
import { ConfirmationModal } from "shared/UI/Modal/ConfirmationModal/ConfirmationModal";
import { trackBtnClicks } from "core/monitoring/Events";

type DailyProgressActionButtonProps = {
  startDate: DateOnly | undefined;
  planOnClicks: { start: () => void; mid: () => void; end: () => void };
  hasNotes: boolean;
};
export const DailyProgressActionButton = ({ startDate, planOnClicks, hasNotes }: DailyProgressActionButtonProps) => {
  const [showRedoPlanConfirmationModal, setShowRedoPlanConfirmationModal] = useState(false);

  let text: string;
  let onClick: () => void;
  if (startDate === undefined) {
    text = t("fourWeekPlans.dailyProgress.startPlan");
    trackBtnClicks("start_4_week_plan");
    onClick = planOnClicks.start;
  } else if (daysSince(Date.fromDateOnly(startDate)!) <= 28) {
    text = t("fourWeekPlans.dailyProgress.midPlan");
    trackBtnClicks("mid_4_week_plan");
    onClick = planOnClicks.mid;
  } else {
    text = t("fourWeekPlans.dailyProgress.endPlan");
    trackBtnClicks("end_4_week_plan");
    onClick = planOnClicks.end;
    if (hasNotes) {
      text = t("fourWeekPlans.dailyProgress.redoPlan");
      onClick = () => {
        setShowRedoPlanConfirmationModal(true);
      };
    }
  }

  return (
    <>
      <StylableButton className="bright-button" fullWidth color="primary" onClick={() => onClick()} text={text} aria-label={text} />
      <Hideable hidden={!hasNotes}>
        <StylableButton
          className="bright-button mt-2"
          fullWidth
          outlineButton
          color="primary"
          onClick={() => planOnClicks.end()}
          text={t("fourWeekPlans.dailyProgress.checkNotes")}
          aria-label={t("fourWeekPlans.dailyProgress.checkNotes")}
        />
      </Hideable>
      <Hideable hidden={!showRedoPlanConfirmationModal}>
        <ConfirmationModal
          title={t("fourWeekPlans.dailyProgress.redoPlanModal.title")}
          message={t("fourWeekPlans.dailyProgress.redoPlanModal.description")}
          cancelButtonText={t("fourWeekPlans.dailyProgress.redoPlanModal.cancelButton")}
          confirmButtonText={t("fourWeekPlans.dailyProgress.redoPlanModal.confirmButton")}
          fullWidthButtons
          buttonSize={"xl"}
          onCancel={() => setShowRedoPlanConfirmationModal(false)}
          onConfirm={() => {
            setShowRedoPlanConfirmationModal(false);
            planOnClicks.start();
          }}
        />
      </Hideable>
    </>
  );
};
