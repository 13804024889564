import React, { PropsWithChildren, createContext, useCallback, useContext, useReducer } from "react";

export type Modal = {
  id: string;
  visible: boolean;
};

type ModalAction =
  | { type: "REGISTER_MODAL"; payload: Omit<Modal, "visible"> }
  | { type: "TRIGGER_MODAL"; payload: { id: string; visible: boolean } }
  | { type: "REMOVE_MODAL"; payload: string };

const modalReducer = (state: Modal[], action: ModalAction): Modal[] => {
  switch (action.type) {
    case "REGISTER_MODAL":
      return [...state, { ...action.payload, visible: false }];
    case "TRIGGER_MODAL":
      return state.map((modal) => (modal.id === action.payload.id ? { ...modal, visible: action.payload.visible } : modal));
    case "REMOVE_MODAL":
      return state.filter((modal) => modal.id !== action.payload);
    default:
      return state;
  }
};

export type ModalContextType = {
  modals: Modal[];
  registerModal: (modal: Omit<Modal, "visible">) => void;
  triggerModal: (id: string, visible: boolean) => void;
  removeModal: (id: string) => void;
  getModal: (id: string) => Modal | undefined;
};

export const ModalContext = createContext<ModalContextType>({} as ModalContextType);

export const useModalContext = () => useContext(ModalContext);

export const ModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [modals, dispatch] = useReducer(modalReducer, []);

  const registerModal = useCallback((modal: Omit<Modal, "visible">) => {
    dispatch({ type: "REGISTER_MODAL", payload: modal });
  }, []);

  const triggerModal = useCallback((id: string, visible: boolean) => {
    dispatch({ type: "TRIGGER_MODAL", payload: { id, visible } });
  }, []);

  const removeModal = useCallback((id: string) => {
    dispatch({ type: "REMOVE_MODAL", payload: id });
  }, []);

  const getModal = useCallback(
    (id: string): Modal | undefined => {
      return modals.find((modal) => modal.id === id);
    },
    [modals]
  );

  const contextValue: ModalContextType = {
    modals,
    registerModal,
    triggerModal,
    removeModal,
    getModal,
  };

  return <ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>;
};
