import {
  UserInterestPreference,
  useGetUserInterestPreferencesFunctionRequest,
  useUpdateUserInterestsFunctionRequest,
} from "shared/request/myHealthyAdvantageApi";
import { useEffect, useState } from "react";

import { CheckBox } from "UIPalette/Components/CheckBox/CheckBox";
import { CurvedTopBanner } from "UIPalette/CurvedTopBanner/CurvedTopBanner";
import { PageHeader } from "UIPalette/PageHeader/PageHeader";
import { ResponsiveBreakpoints } from "shared/UI/ResponsiveBreakpoints";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { ViewStateWrapper } from "UIPalette/ViewStateWrapper/ViewStateWrapper";
import { t } from "i18next";
import { toastNotify } from "shared/UI/Toaster/Toaster";
import { useMatchMedia } from "shared/UI/Hooks/useMatchMedia";
import { useUserContext } from "core/state/UserContext";
import PageTitle from "UIPalette/PageTitle/PageTitle";

export const Preferences = () => {
  const [interestPreferences, setInterestPreferences] = useState<UserInterestPreference[]>([]);
  const { data, error, isLoading, mutate: refresh } = useGetUserInterestPreferencesFunctionRequest({});
  const { trigger } = useUpdateUserInterestsFunctionRequest({});
  const { user, updateUserInterests } = useUserContext();
  const largerDisplay = useMatchMedia(ResponsiveBreakpoints.Desktop, false);

  useEffect(() => {
    if (data?.interestPreferences) {
      const sortedPreferences = [...data.interestPreferences].sort((a, b) => a.name.localeCompare(b.name));
      setInterestPreferences(sortedPreferences);
    }
  }, [data]);

  const savePreference = async () => {
    const interestCategoryIds = interestPreferences.filter((interest) => interest.isSelected).map((interest) => interest.categoryId);
    try {
      const response = await trigger({ body: { interestCategoryIds } });
      updateUserInterests(response?.data.interests ?? []);
      await refresh();
      toastNotify(t("preferences.updateSuccess"), "success");
    } catch {
      toastNotify(t("preferences.updateFailure"), "error");
    }
  };

  const canSavePreferences = () => {
    const selectedCategories = interestPreferences
      .filter((interest) => interest.isSelected)
      .map((interest) => interest.categoryId)
      .sort((a, b) => a.localeCompare(b));
    const previouslySelectedCategories = user?.interests?.map((interest) => interest.categoryId).sort();
    return selectedCategories.join() === previouslySelectedCategories?.join();
  };

  return (
    <>
      <PageTitle title={t("pageTitle.account.preferences")} />
      <CurvedTopBanner>
        <PageHeader title={t("preferences.heading")} backRoute={largerDisplay ? "/" : "/account"} />{" "}
      </CurvedTopBanner>
      <ViewStateWrapper loading={isLoading} error={!!error} errorMessage={t("preferences.error")} onRetry={refresh} isFullScreenView={true}>
        <p className="col-start-1 mb-5">{t("preferences.intro")}</p>
        <div className="lg:flex lg:items-center lg:grid lg:grid-cols-2 lg:gap-x-8">
          {interestPreferences.map((interest, index) => (
            <div className={`border-b border-b-sky-grey py-4`} key={index}>
              <CheckBox
                checked={interest.isSelected}
                onChange={(e) => {
                  const preferencesToUpdate = [...interestPreferences];
                  preferencesToUpdate[index].isSelected = e.target.checked;
                  setInterestPreferences(preferencesToUpdate);
                }}
                label={interest.name}
              />
            </div>
          ))}
        </div>
        <StylableButton
          disabled={canSavePreferences()}
          className="mt-5 bright-button lg:self-center lg:w-1/2 lg:mt-8"
          fullWidth
          color="primary"
          type="button"
          text={t("preferences.save")}
          onClick={async () => savePreference()}
        />
      </ViewStateWrapper>
    </>
  );
};
