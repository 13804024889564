/* eslint-disable no-extend-native */
export {};

declare global {
  interface Number {
    toDecimalPlaces(place: Number): number;
    asOrdinal(): string;
    percentageOf(of: number, to?: number): number;
  }
}

const english_ordinal_rules = new Intl.PluralRules("en", { type: "ordinal" });
const suffixes = {
  zero: "th",
  one: "st",
  two: "nd",
  few: "rd",
  other: "th",
  many: "th",
};

Number.prototype.toDecimalPlaces = function (places: number): number {
  let value = Number(this);
  value = parseFloat(value.toFixed(places));
  return value;
};

Number.prototype.percentageOf = function (of: number, to?: number) {
  if (of <= 0) {
    return 0;
  }

  const percentage = Math.min(100, (Number(this) / of) * 100);

  return to !== undefined ? percentage.toDecimalPlaces(to) : percentage;
};

Number.prototype.asOrdinal = function (): string {
  const number = Number(this);
  const category = english_ordinal_rules.select(number);
  const suffix = suffixes[category];
  return number + suffix;
};
