declare global {
  interface Window {
    interdeal: any;
  }
}

export const EqualWebButton = () => (
  <button onClick={() => window.interdeal?.a11y.openMenu()} className="h-full">
    <img src="https://cdn.equalweb.com/assets/images/1.svg" alt="Web Equal" className="h-7 w-7" />
  </button>
);
