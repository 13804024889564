import { t } from "i18next";
import { ConfirmationModal } from "shared/UI/Modal/ConfirmationModal/ConfirmationModal";
import Textarea from "@brighthr/component-textarea";
import "./ReflectModal.css";
import { Controller, useForm } from "react-hook-form";

type ReflectModalProps = {
  onCancel: () => void;
  onConfirm: (successNotes: string, challengeNotes: string) => Promise<void>;
  notes: { challengeNotes?: string; successNotes?: string };
};

const ReflectModal = ({ onCancel, onConfirm, notes }: ReflectModalProps) => {
  const {
    control,
    getValues,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      successNotes: notes.successNotes ?? "",
      challengeNotes: notes.challengeNotes ?? "",
    },
  });

  async function onSubmit() {
    const { successNotes, challengeNotes } = getValues();
    await onConfirm(successNotes, challengeNotes);
  }

  function getCancelButtonText() {
    if (notes.successNotes || notes.challengeNotes) {
      return t("fourWeekPlans.finishPlanModal.closeButton");
    }
    return t("fourWeekPlans.finishPlanModal.cancelButton");
  }

  return (
    <ConfirmationModal
      title={t("fourWeekPlans.finishPlanModal.title")}
      width="sm"
      buttonSize="xl"
      fullWidthButtons
      cancelButtonText={getCancelButtonText()}
      confirmButtonText={t("fourWeekPlans.finishPlanModal.confirmButton")}
      onCancel={onCancel}
      onConfirm={onSubmit}
      confirmButtonDisabled={!isDirty}
    >
      <p>{t("fourWeekPlans.finishPlanModal.description")}</p>

      <form>
        <div className="label-xl mt-3">
          <Controller
            name={"successNotes"}
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <div className="mt-5 mb-5">
                <Textarea
                  id="successNotes"
                  rows={5}
                  label={t("fourWeekPlans.finishPlanModal.successNotesHeader")}
                  onChange={onChange}
                  value={value}
                />
              </div>
            )}
          />
        </div>

        <div className="label-xl mt-3">
          <Controller
            name={"challengeNotes"}
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <div className="mt-5">
                <Textarea
                  id="challengeNotes"
                  rows={5}
                  label={t("fourWeekPlans.finishPlanModal.challengeNotesHeader")}
                  onChange={onChange}
                  value={value}
                />
              </div>
            )}
          />
        </div>
      </form>
    </ConfirmationModal>
  );
};
export default ReflectModal;
