import "shared/extensions/date";

import { UpdateUserCommand, UserResponseModel, useUpdateUserFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { useLocation, useNavigate } from "react-router-dom";

import { DestinationState } from "shared/core/state/destinationState";
import { NoValueSelected } from "shared/constants/SelectConstants";
import PersonalInfoForm, { Inputs } from "../Account/Components/PersonalInfoForm/PersonalInfoForm";
import { UserState } from "core/state/userState";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserContext } from "core/state/UserContext";
import PageTitle from "UIPalette/PageTitle/PageTitle";
import { trackFormSubmission } from "core/monitoring/Events";

export const AdditionalInfo = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [errorMessage, setErrorMessage] = useState(false);
  const { trigger } = useUpdateUserFunctionRequest({});
  const storedUser = UserState.get();
  const navigateHook = useNavigate();
  const { saveUser } = useUserContext();

  const onSubmit = async (additionalInfo: Inputs) => {
    setErrorMessage(false);
    const fullData: UpdateUserCommand = {
      forenames: additionalInfo.forenames,
      surname: additionalInfo.surname,
      dateOfBirth: additionalInfo.dateOfBirth.toDateOnly(),
      gender: additionalInfo.gender,
      phoneNumber: additionalInfo.phoneNumber,
      divisionId: additionalInfo.divisionId === NoValueSelected ? undefined : additionalInfo.divisionId,
    };
    trackFormSubmission("Additional-Info", fullData);
    try {
      const user = (await trigger({ body: fullData }))?.data!;
      saveUser(user);
      navigate(user);
    } catch {
      setErrorMessage(true);
    }
  };

  const navigate = (user: UserResponseModel) => {
    const hasInterests = (user.interests.length ?? 0) !== 0;
    let destination = DestinationState.get();
    destination = destination !== pathname ? destination : "/";
    navigateHook(hasInterests ? destination : "/preferences");
  };

  return (
    <>
      <PageTitle title={t("pageTitle.additionalInfo")} />
      <h2>{t("additionalInfo.heading")}</h2>
      <PersonalInfoForm onSubmit={onSubmit} errorMessage={errorMessage} user={storedUser} type={"additionalInfo"} />
    </>
  );
};
