import { Splide, SplideTrack, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { ReactElement, useMemo } from "react";
import "./Carousel.css";

type CarouselProps = {
  itemsPerSlide: number;
  ariaLabel: string;
  children: ReactElement[];
};

export const Carousel = ({ itemsPerSlide, ariaLabel, children }: CarouselProps) => {
  const slides = useMemo(() => {
    const slides: ReactElement[] = [];

    for (let index = 0; index < children.length; index += itemsPerSlide) {
      const slideElements = children.slice(index, index + itemsPerSlide);
      slides.push(<SplideSlide key={`slide-${slides.length}`}>{slideElements}</SplideSlide>);
    }

    return slides;
  }, [children, itemsPerSlide]);

  return (
    <Splide hasTrack={false} options={{ label: ariaLabel }}>
      <SplideTrack>{slides}</SplideTrack>
      <div className="splide__pagination" />
      <div className="splide__arrows hidden" />
    </Splide>
  );
};
