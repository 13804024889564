import "./DatePicker.css";

import { Input, InputProps } from "../Input/Input";
import Picker, { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import { enAU, enCA, enGB, enIE, enNZ } from "date-fns/locale";
import { forwardRef, useMemo, useRef } from "react";
import { getMonth, getYear, isValid, parse } from "date-fns";

import { ReactComponent as CalendarIcon } from "../assets/calendar.svg";
import Icon from "@brighthr/component-icon";
import { ValueCallback } from "../../../core/types/callbacks";
import cn from "classnames";

const formStyles = "p-2 border rounded border-neutral-500 outline-0 focus:border-primary-400 focus:shadow font-semibold";

const CalendarContainer = ({ children }: { children: React.ReactNode }) => (
  <div className="p-2 bg-white border rounded shadow-xl sm:p-4 border-primary-400">{children}</div>
);

const isDateWithinRange = (date: Date, start?: Date, end?: Date) =>
  isValid(date) && (start === undefined || date >= start) && (end === undefined || date <= end);

const mapLocale = {
  "en-gb": enGB,
  "en-ca": enCA,
  "en-ie": enIE,
  "en-au": enAU,
  "en-nz": enNZ,
};

type locales = keyof typeof mapLocale;

export type DatePickerProps = {
  minDate?: Date;
  maxDate?: Date;
  date?: Date;
  label?: string;
  validationState?: "error" | "warning" | "success";
  setDate: ValueCallback<Date | undefined>;
  onChange?: ValueCallback<Date | undefined>;
  onFocus?: () => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  locale?: locales;
  GAEvent?: (s: string) => void;
  ["aria-describedby"]?: string;
};

const isCanada = (locale: locales) => locale === "en-ca";

const noop = () => {
  // noop
};

const yearsBetweenDates = (startDate: Date, endDate: Date, selectedDate?: Date) => {
  const selectedDateYear = selectedDate?.getFullYear();
  const startDateYear = startDate.getFullYear();
  const startYear = selectedDateYear && selectedDateYear < startDateYear ? selectedDateYear : startDateYear;
  const endYear = endDate.getFullYear();
  const yearsBetween = endYear - startYear + 1;

  return new Array(yearsBetween).fill(startYear).map((val, i) => val + i);
};

const makeLocalizedListOfMonths = (locale: locales) => new Array(12).fill("").map((_, i) => mapLocale[locale].localize?.month(i));

const dateFormat = (locale: locales) => (!isCanada(locale) ? `dd/MM/yyyy` : `yyyy/MM/dd`);

// eslint-disable-next-line default-param-last
const formatDate = (date = "", currentValue: string, locale: locales) => {
  let currentDate = date;

  currentDate.replace(/.*\/$/g, (text: string) => (currentDate.length < currentValue.length ? currentDate.slice(0, -1) : text));

  if (isCanada(locale)) {
    if (currentDate.length < 6) {
      currentDate = currentDate
        .replace(/[^\d/]/g, "")
        .replace(/(\d\d\d\d)(\d)/g, "$1/$2")
        .replace(/\d\d\d\d$/g, (text: string) => (currentDate.length > currentValue.length ? `${text}/` : text));
    } else {
      currentDate = currentDate
        .replace(/^\/(.*)/g, "$1")
        .replace(/(\/\d\d)(\d)/g, "$1/$2")
        .replace(/\d\d$/g, (text: string) => (currentDate.length > currentValue.length && currentDate.length < 7 ? `${text}/` : text))
        .replace(/^(\d\d\d\d\/)(\d\/)$/g, "$10$2")
        .replace(/[^\d/]/g, "");
    }
  } else if (currentDate.length > 6) {
    currentDate = currentDate.replace(/[^\d/]/g, "").replace(/(\d\d\d\d)(\d)/g, "$1");
  } else {
    currentDate = currentDate
      .replace(/(\d\d)(\d)/g, "$1/$2")
      .replace(/^(\d\/)$/g, "0$1")
      .replace(/\d\d$/g, (text: string) => (currentDate.length > currentValue.length && currentDate.length < 7 ? `${text}/` : text))
      .replace(/^(\d\d\/)(\d\/)$/g, "$10$2")
      .replace(/[^\d/]/g, "")
      .replace(/^\/(.*)/g, "$1");
  }

  return currentDate.replace(/^(.{0,10})(.*)$/g, "$1").replace(/\/\/$/g, "/");
};

const renderDayContents = (day: number, date: Date) => {
  const tooltipText = `Tooltip for date: ${date.toString()}`;
  return (
    <div title={tooltipText} className="flex items-center justify-around h-full sm:h-12">
      <span>{day}</span>
    </div>
  );
};

const CustomHeader = (minDate: Date, maxDate: Date, locale: locales, GAEvent: (s: string) => void, selectedDate?: Date) => {
  const years = yearsBetweenDates(minDate, maxDate, selectedDate);
  const months = makeLocalizedListOfMonths(locale);

  return ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: ReactDatePickerCustomHeaderProps) => (
    <div className="flex justify-between pb-3">
      <button
        className="pl-4"
        aria-label="previous month"
        type="button"
        onClick={() => {
          GAEvent("Previous Month");
          decreaseMonth();
        }}
        disabled={prevMonthButtonDisabled}
      >
        <Icon
          iconName="chevron-thin-left"
          size={40}
          className={cn("-ml-3", {
            prevMonthButtonDisabled,
            "fill-link-disabled cursor-not-allowed": prevMonthButtonDisabled,
            "fill-primary-600": !prevMonthButtonDisabled,
          })}
        />
      </button>
      <div className="flex">
        <select
          aria-label="month"
          className={cn(formStyles, "mr-3")}
          value={months[getMonth(date)]}
          onChange={({ target: { value } }) => {
            GAEvent(`Month ${months.indexOf(value) + 1}`);
            changeMonth(months.indexOf(value));
          }}
          onFocus={() => GAEvent("Month Picker")}
        >
          {months.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <select
          aria-label="year"
          className={formStyles}
          value={getYear(date)}
          onChange={({ target: { value } }) => {
            GAEvent(`Year ${value}`);
            changeYear(Number(value));
          }}
          onFocus={() => GAEvent("Year Picker")}
        >
          {years.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <button
        className="pr-4"
        aria-label="next month"
        type="button"
        onClick={() => {
          GAEvent("Next Month");
          increaseMonth();
        }}
        disabled={nextMonthButtonDisabled}
      >
        <Icon
          iconName="chevron-thin-right"
          size={40}
          className={cn("-mr-3", {
            "fill-link-disabled cursor-not-allowed": nextMonthButtonDisabled,
            "fill-primary-600": !nextMonthButtonDisabled,
          })}
        />
      </button>
    </div>
  );
};

type DateInputProps = {
  value?: string;
  minDate?: Date;
  maxDate?: Date;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  locale: locales;
  validationState?: "error" | "warning" | "success";
};

const DateInput = forwardRef<HTMLInputElement, DateInputProps & Omit<InputProps, keyof DateInputProps | "type">>((props, ref) => {
  const { minDate, maxDate, onKeyDown, onChange, value = "", locale = "en-gb", ...rest } = props;

  const currentValue = value as string;

  return (
    <Input
      {...rest}
      aria-describedby={rest["aria-describedby"]}
      value={value}
      autoComplete="off"
      onKeyDown={(e) => {
        const target = e.target as HTMLInputElement;

        if (e.key.includes("Enter")) {
          if (isCanada(locale) && target.value.length === 9) {
            target.value = target.value.replace(/\/(\d)$/g, "/0$1");
          }

          const date = parse(target.value, dateFormat(locale), new Date());

          if (!isDateWithinRange(date, minDate, maxDate)) {
            e.preventDefault();
            e.stopPropagation();
            return;
          }
        }

        onKeyDown?.(e);
      }}
      onChange={(e) => {
        e.target.value = formatDate(e.target.value, currentValue, locale);

        onChange?.(e);
      }}
      type="text"
      icon={<CalendarIcon className="w-6 h-6" />}
      ref={ref}
    />
  );
});

const DatePicker = ({
  minDate = new Date("1900-01-01"),
  maxDate = new Date("2100-12-31"),
  date,
  setDate = noop,
  locale = "en-gb",
  onFocus = noop,
  onChange = noop,
  onBlur = noop,
  GAEvent = noop,
  ...rest
}: DatePickerProps): React.ReactElement => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const Header = useMemo(() => CustomHeader(minDate, maxDate, locale, GAEvent, date), [minDate, maxDate]);

  const datePickerRef = useRef<any>(null);

  // Stops calendar opening on focus
  const handleOnKeyDown = (event: React.KeyboardEvent) => {
    if (datePickerRef?.current && !datePickerRef.current.state.open) {
      if (event.key.includes("Enter")) {
        event.preventDefault();
        datePickerRef.current?.setOpen(true);
      }
    }
  };

  return (
    <Picker
      locale={mapLocale[locale]}
      startDate={new Date()}
      onFocus={() => {
        GAEvent("DayPicker Input Open");
        onFocus();
      }}
      onBlur={onBlur}
      customInput={<DateInput minDate={minDate} maxDate={maxDate} locale={locale} {...rest} />}
      minDate={minDate}
      maxDate={maxDate}
      calendarContainer={CalendarContainer}
      renderCustomHeader={Header}
      selected={date}
      onChange={(date) => {
        GAEvent("Set");

        setDate(date ?? undefined);
        if (onChange) {
          onChange(date ?? undefined);
        }
      }}
      strictParsing
      renderDayContents={renderDayContents}
      dateFormat={dateFormat(locale)}
      useWeekdaysShort
      enableTabLoop={false}
      preventOpenOnFocus
      ref={datePickerRef}
      onKeyDown={handleOnKeyDown}
    />
  );
};

export const PopperContainer = (children: React.ReactNode[]) => {
  return <div>{children}</div>;
};

export default DatePicker;
