import { MiniHealthCheckQuestion, MiniHealthCheckQuestionOption } from "shared/request/myHealthyAdvantageApi";

export class QuestionManager {
  constructor(public questions: MiniHealthCheckQuestion[]) {}

  firstQuestion = (): MiniHealthCheckQuestion => this.questions[0];

  hasNextQuestion = (currentQuestion: MiniHealthCheckQuestion): boolean =>
    this.isChoiceQuestion(currentQuestion) || this.isNotLastQuestion(currentQuestion);

  getNextQuestion = (
    currentQuestion: MiniHealthCheckQuestion,
    selectedOption: MiniHealthCheckQuestionOption | undefined
  ): MiniHealthCheckQuestion | undefined => {
    if (this.isChoiceQuestion(currentQuestion)) {
      if (selectedOption === undefined) {
        throw Error("Must specify a selected option when getting question after a choice question");
      }

      return selectedOption.next![0];
    }

    const nextIndex = this.getNextQuestionIndex(currentQuestion);
    return nextIndex === -1 ? undefined : this.questions[nextIndex];
  };

  getPreviousQuestion = (currentQuestion: MiniHealthCheckQuestion): MiniHealthCheckQuestion | undefined => {
    const parentChoiceQuestion = this.getParentChoiceQuestion(currentQuestion);
    if (parentChoiceQuestion !== undefined) {
      return parentChoiceQuestion;
    }

    const previousIndex = this.getPreviousQuestionIndex(currentQuestion);
    return previousIndex === -1 ? undefined : this.questions[previousIndex];
  };

  getCount = () => {
    let count = 0;
    this.questions.forEach((question) => {
      count += this.isChoiceQuestion(question) ? 2 : 1;
    });
    return count;
  };

  private isChoiceQuestion = (question: MiniHealthCheckQuestion): boolean =>
    question.options?.find((o) => o.next !== undefined && o.next.length > 0) !== undefined;

  private isNotLastQuestion = (question: MiniHealthCheckQuestion): boolean => this.getNextQuestionIndex(question) !== -1;

  private getNextQuestionIndex = (question: MiniHealthCheckQuestion): number => {
    const questionIndex = this.getQuestionIndex(question);
    return questionIndex !== this.questions.length - 1 ? questionIndex + 1 : -1;
  };

  private getPreviousQuestionIndex = (question: MiniHealthCheckQuestion): number => {
    const questionIndex = this.getQuestionIndex(question);
    return questionIndex !== 0 ? questionIndex - 1 : -1;
  };

  private getQuestionIndex = (question: MiniHealthCheckQuestion): number => {
    const questionIndex = this.questions.findIndex((x) => x.id === question.id);
    if (questionIndex === -1) {
      const parent = this.getParentChoiceQuestion(question);
      if (parent === undefined) {
        throw new Error("unexpected question, unable to find in array or as optional choice question");
      }
      return this.getQuestionIndex(parent);
    }
    return questionIndex;
  };

  private getParentChoiceQuestion = (optionQuestion: MiniHealthCheckQuestion): MiniHealthCheckQuestion | undefined => {
    for (const question of this.questions) {
      if (question.options) {
        for (const option of question.options!) {
          if (option.next) {
            for (const subQuestion of option.next!) {
              if (subQuestion.id === optionQuestion.id) {
                return question;
              }
            }
          }
        }
      }
    }

    return undefined;
  };
}
