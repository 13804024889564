import { getMobilePlatfrom } from "core/javascriptChannels";
import { useEffect, useState } from "react";

export enum Platform {
  android = "android",
  ios = "iOS",
}

type UseMobilePlatformType = {
  platform: Platform | undefined;
  isMobileApp: boolean;
  backgroundAudioEnabled?: boolean;
};

export const useMobilePlatform = (): UseMobilePlatformType => {
  const [platform, setPlatform] = useState<Platform | undefined>(undefined);
  const isMobileApp = window.flutter_inappwebview != null;

  // This assists backwards compatability due to fix for https://brighthr.atlassian.net/browse/MHA-649
  // where audio in the mobile app stops when screen is locked or app is in the background
  const backgroundAudioEnabled = window.backgroundAudioEnabled;

  useEffect(() => {
    async function getPlatform() {
      const platformString: string = await getMobilePlatfrom();

      if (Object.keys(Platform).includes(platformString)) {
        setPlatform((Platform as any)[platformString]);
      }
    }
    if (isMobileApp) {
      getPlatform();
    }
  }, [isMobileApp]);

  return { platform, isMobileApp, backgroundAudioEnabled };
};
