import { t } from "i18next";
import { ReactComponent as HomeIcon } from "shared/assets/Icons/home.svg";
import { ReactComponent as FilledHomeIcon } from "shared/assets/Icons/home-filled.svg";
import { useNavigate } from "react-router-dom";

export const BottomNavHomeButton = ({ active }: { active: boolean }) => {
  const navigateHook = useNavigate();

  return (
    <button
      className="flex flex-col items-center text-brand font-bold text-[14pt] mx-1"
      aria-label={t("navigation.homeNavigationLabel")}
      onClick={() => {
        navigateHook("/home");
      }}
    >
      {active ? <FilledHomeIcon /> : <HomeIcon />}
      <p className="mt-1">{t("navigation.homeButton")}</p>
    </button>
  );
};
