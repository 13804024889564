import { useTranslation } from "react-i18next";
import { ReactComponent as PlayIcon } from "shared/assets/Icons/play.svg";
import { ReactComponent as PauseIcon } from "shared/assets/Icons/pause.svg";
import Spinner from "@brighthr/component-spinner";

type AudioPlayerControlBarProps = {
  disabled?: boolean;
  position: number;
  duration: number;
  isPlaying: boolean;
  isLoading?: boolean;
  play: () => void;
  pause: () => void;
  onSeekMouseDown: () => void;
  onSeekMouseUp: () => void;
  onSeekChange: (position: number) => void;
};

export const AudioPlayerControlBar = ({
  isPlaying,
  duration,
  position,
  isLoading = false,
  pause,
  play,
  onSeekMouseDown,
  onSeekMouseUp,
  onSeekChange,
  disabled = false,
}: AudioPlayerControlBarProps) => {
  const { t } = useTranslation();
  const timeRemaining = Math.min(duration * position, duration);

  return (
    <>
      <input
        disabled={disabled}
        type="range"
        className="accent-primary-700 h-10 w-full"
        aria-label={t("viewArticle.player.timeSlider")}
        value={position}
        min={0}
        max={1}
        step="any"
        onMouseDown={() => onSeekMouseDown()}
        onMouseUp={() => onSeekMouseUp()}
        onTouchStart={() => onSeekMouseDown()}
        onTouchEnd={() => onSeekMouseUp()}
        onChange={(e) => {
          onSeekChange(parseFloat(e.currentTarget.value));
        }}
      />
      <div className="flex flex-row items-center">
        <button
          disabled={disabled}
          className="bg-primary-700 text-white rounded-full p-2 mr-4"
          aria-label={isPlaying ? t("viewArticle.player.pauseLabel") : t("viewArticle.player.playLabel")}
          onClick={() => (isPlaying ? pause() : play())}
        >
          {isPlaying ? <PauseIcon className="w-20 h-10" /> : <PlayIcon className="w-20 h-10" />}
        </button>
        {isLoading && <Spinner ariaLabel={t("buttons.loading")} className="stroke-primary-700" size="xs" />}
        {!isLoading && (
          <>
            <span className="mr-1" aria-label={t("viewArticle.player.timeRemaining")}>
              {format(timeRemaining)}
            </span>
            /
            <span className="ml-1" aria-label={t("viewArticle.player.duration")}>
              {format(duration)}
            </span>
          </>
        )}
      </div>
    </>
  );
};

const format = (seconds: number): string => {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }
  return `${mm}:${ss}`;
};
