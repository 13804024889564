import { BreathingExerciseStage } from "shared/request/myHealthyAdvantageApi";

export class BreathingExerciseRunnerState {
  constructor(
    public playing: boolean = true,
    public introCompleted: boolean = false,
    public muted: boolean = false,
    public currentStageIndex: number | undefined = undefined,
    public currentStageTimeRemaining: number = 0
  ) {}

  next = (stages: BreathingExerciseStage[]): BreathingExerciseRunnerState | undefined => {
    if (!this.introCompleted || !this.playing) {
      return undefined;
    }

    if (this.currentStageIndex === undefined || this.currentStageTimeRemaining === 1) {
      const nextStageIndex = ((this.currentStageIndex ?? -1) + 1) % stages.length;
      const nextStage = stages[nextStageIndex];
      return this.update({ currentStageIndex: nextStageIndex, currentStageTimeRemaining: nextStage.duration });
    }

    return this.update({ currentStageTimeRemaining: this.currentStageTimeRemaining - 1 });
  };

  update = (changes: Partial<BreathingExerciseRunnerState>): BreathingExerciseRunnerState => {
    return new BreathingExerciseRunnerState(
      changes.playing ?? this.playing,
      changes.introCompleted ?? this.introCompleted,
      changes.muted ?? this.muted,
      changes.currentStageIndex ?? this.currentStageIndex,
      changes.currentStageTimeRemaining ?? this.currentStageTimeRemaining
    );
  };
}
