import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  CompleteMiniHealthCheckCommand,
  useCompleteMiniHealthCheckFunctionRequest,
  useGetMiniHealthCheckFunctionRequest,
} from "shared/request/myHealthyAdvantageApi";
import { MiniHealthCheckIntro } from "./components/MiniHealthCheckIntro";
import { MiniHealthCheckResults } from "./components/MiniHealthCheckResults";
import { MiniHealthCheckQuestionnaire } from "./components/MiniHealthCheckQuestionnaire";
import { ViewStateWrapper } from "UIPalette/ViewStateWrapper/ViewStateWrapper";
import { toastNotify } from "shared/UI/Toaster/Toaster";
import { NotFound } from "../../../../Views/NotFound";
import { datocmsUUIDRegex } from "shared/utils/regex";
import PageTitle from "UIPalette/PageTitle/PageTitle";
import { trackEvents } from "core/monitoring/Events";
import { EventNames } from "core/monitoring/types/enums";

export const CompleteMiniHealthCheck = () => {
  const { t } = useTranslation();
  const { miniHealthCheckId } = useParams();
  const isValidMiniHealthCheckId = miniHealthCheckId !== undefined && datocmsUUIDRegex.test(miniHealthCheckId);
  const {
    data,
    isLoading,
    error,
    mutate: reloadHealthCheck,
  } = useGetMiniHealthCheckFunctionRequest({ id: miniHealthCheckId }, { shouldFetch: isValidMiniHealthCheckId });
  const { trigger } = useCompleteMiniHealthCheckFunctionRequest({});
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);

  const startTest = () => {
    trackEvents(EventNames.MINI_HEALTH_CHECK_START, { ...(miniHealthCheck?.name && { value: miniHealthCheck?.name }) });
    setShowQuestionnaire(true);
  };

  const completeTest = async (command: CompleteMiniHealthCheckCommand) => {
    try {
      trackEvents(EventNames.MINI_HEALTH_CHECK_END, { ...(miniHealthCheck?.name && { value: miniHealthCheck?.name }) });
      await trigger({ body: command });
      setShowQuestionnaire(false);
      reloadHealthCheck();
    } catch {
      toastNotify(t("completeMiniHealthCheck.failedToComplete"), "error");
    }
  };

  const { miniHealthCheck, completed } = data ?? {};

  if (!isValidMiniHealthCheckId) {
    return <NotFound />;
  }

  return (
    <>
      <PageTitle title={t("pageTitle.resources.miniHealthChecks.miniHealthCheckId", { miniHealthCheckId: miniHealthCheck?.name })} />
      <ViewStateWrapper loading={isLoading} error={!!error} errorMessage={t("miniHealthChecks.errorSingle")} onRetry={reloadHealthCheck}>
        {miniHealthCheck && !completed && <MiniHealthCheckIntro miniHealthCheck={miniHealthCheck} onStartTest={startTest} />}
        {miniHealthCheck && completed && <MiniHealthCheckResults miniHealthCheck={miniHealthCheck} completed={completed} onRetakeTest={startTest} />}
        {miniHealthCheck && showQuestionnaire && (
          <MiniHealthCheckQuestionnaire miniHealthCheck={miniHealthCheck} onClose={() => setShowQuestionnaire(false)} onCompleted={completeTest} />
        )}
      </ViewStateWrapper>
    </>
  );
};
