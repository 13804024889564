import { LearningMaterials } from "UIPalette/LearningMaterials/LearningMaterials";
import { useTranslation } from "react-i18next";

export type TrackerLearningMaterialsProps = {
  categoryIdsFilter: string[];
};

export const TrackerLearningMaterials = ({ categoryIdsFilter }: TrackerLearningMaterialsProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <h3>{t("healthHub.usefulArticles.heading")}</h3>
      <LearningMaterials
        categoryIds={categoryIdsFilter}
        maxResults={15}
        resultsPerPage={3}
        navigateBasePath={"/resources/"}
        errorMessage={t("healthHub.usefulArticles.error")}
        ariaLabel={t("healthHub.usefulArticles.label")}
      />
    </div>
  );
};
