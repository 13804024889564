import "./Preview.css";

import { Content, Leaderboard } from "shared/request/myHealthyAdvantageApi";

import { CurvedTopBanner } from "UIPalette/CurvedTopBanner/CurvedTopBanner";
import { DatoContent } from "UIPalette/Components/DatoContent/DatoContent";
import { PageHeader } from "UIPalette/PageHeader/PageHeader";
import { PropsWithChildren } from "react";

export type LeaderboardPreview = {
  leaderboard: Leaderboard;
  durationText: string;
  startDate: Date;
  endDate: Date;
};

export type PreviewProps = {
  name: string;
  content: Content;
  subtitle: string;
  onClose: Function | number;
};
export const Preview = ({ name, content, subtitle, onClose, children }: PreviewProps & PropsWithChildren) => {
  return (
    <div className="flex flex-grow flex-col">
      <CurvedTopBanner>
        <PageHeader title={name} subtitle={subtitle} backRoute={onClose} />
      </CurvedTopBanner>
      <DatoContent className="flex-grow" content={content} />
      {children}
    </div>
  );
};
