import useLocale, { ManualLocaleState, ManualLocales } from "core/hooks/useLocale/useLocale";

import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import { Hideable } from "shared/UI/Hideable/Hideable";
import { Input } from "shared/UI/Inputs/Input/Input";
import { LocaleOption } from "UIPalette/LocaleModal/LocaleModal";
import { Modal } from "shared/UI/Modal/Modal";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export type EnterClientCodeProps = {
  handleClick: Function;
  error?: string;
  initialClientCode: string | undefined;
};

export const EnterClientCode = ({ handleClick, error, initialClientCode }: EnterClientCodeProps) => {
  const { t } = useTranslation();
  const { updateLocale } = useLocale();
  const [clientCode, setClientCode] = useState(initialClientCode ?? "");
  const [helpOpen, setHelpOpen] = useState(false);

  const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setClientCode(value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleClick(clientCode);
    }
  };

  const handleModalOpen = () => {
    setHelpOpen(true);
  };

  const handleLocaleSelect = (locale: ManualLocales) => {
    updateLocale(locale);
  };

  return (
    <>
      <h1 className="text-center">{t("signup.heading")}</h1>
      <Input
        label={t("signup.clientCodeLabel")}
        placeholder={t("signup.clientCodePlaceholder")}
        type="text"
        value={clientCode}
        onChange={handleChange}
        onKeyUp={handleKeyPress}
      />
      {error ? <ErrorAlert title={t("signup.confirmCodeErrorTitle")} content={error} /> : null}
      <StylableButton className="mt-2 bright-button" fullWidth color="primary" text={t("signup.button")} onClick={() => handleClick(clientCode)} />
      <div className="mt-5 text-center">
        <p>{t("signup.helpText")}</p>
        <button className="m-2 text-brand font-bold text-[14pt]" role="link" onClick={handleModalOpen} aria-label={t("signup.helpButtonText")}>
          {t("signup.helpButtonText")}
        </button>
      </div>

      <Hideable hidden={!helpOpen}>
        <Modal close={() => setHelpOpen(false)}>
          <div className="p-3">
            <div>
              <h3 className="p-0">{t("signup.helpModal.title")}</h3>
              <p>{t("signup.helpModal.body")}</p>
            </div>
            <div className="mt-3">
              <h3 className="p-0">{t("signup.helpModal.contactTitle")}</h3>
              {ManualLocaleState.get() ? (
                <>
                  <a
                    className="block w-full p-2 font-bold text-center no-underline border-2 rounded border-primary-700 text-primary-700"
                    href={`tel:${t("signup.helpModal.phoneNumber")!}`}
                  >
                    {t("signup.helpModal.phoneNumber")!}
                  </a>
                  <p className="my-2 text-center">{t("signup.helpModal.or")}</p>
                  <a
                    className="block w-full p-2 font-bold text-center no-underline border-2 rounded border-primary-700 text-primary-700"
                    href={`mailto:${t("signup.helpModal.email")!}`}
                  >
                    {t("signup.helpModal.email")!}
                  </a>
                </>
              ) : (
                <div className="flex flex-wrap items-center gap-2">
                  <p className="text-bold">{`${t("localeModel.title")}:`} </p>
                  <LocaleOption handleLocaleClick={handleLocaleSelect} />
                </div>
              )}
            </div>
          </div>
        </Modal>
      </Hideable>
    </>
  );
};
