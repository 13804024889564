import { AcknowledgementCard } from "UIPalette/AcknowledgementCard/AcknowledgementCard";
import { ReactComponent as CallUsIcon } from "shared/assets/Icons/phone.svg";
import { ClickableRoundedCard } from "../ContactUs/Components/ContactCard/ClickableRoundedCard";
import ClientLogoImage from "shared/UI/ClientLogo/ClientLogoImage";
import { GeneralActivityTrackerProgressTile } from "../HealthHub/components/GeneralActivityTrackerProgressTile/GeneralActivityTrackerProgressTile";
import { Hideable } from "shared/UI/Hideable/Hideable";
import { LeaderboardsSingleCard } from "../HealthHub/components/LeaderboardsSingleCard/LeaderboardsSingleCard";
import { MoodTrackerCard } from "UIPalette/MoodTrackerCard/MoodTrackerCard";
import { PerksAndDiscountsCard } from "./components/PerksAndDiscountsCard/PerksAndDiscountsCard";
import { PersonalisedNewsFeed } from "./components/PersonalisedNewsFeed/PersonalisedNewsFeed";
import { ProtectedModule } from "core/auth/ProtectedModule";
import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";
import { SleepTrackerProgressTile } from "../HealthHub/components/SleepTrackerProgressTile/SleepTrackerProgressTile";
import { StepsTrackerProgressTile } from "../HealthHub/components/StepsTrackerProgressTile/StepsTrackerProgressTile";
import { TodaysMoodCard } from "./components/TodaysMoodCard/TodaysMoodCard";
import { TrackerType } from "core/state/Trackers/TrackerType";
import { TwoColumnLayout } from "UIPalette/Layout/TwoColumnLayout";
import { WaterTrackerProgressTile } from "../HealthHub/components/WaterTrackerProgressTile/WaterTrackerProgressTile";
import WisdomModules from "core/Modules";
import { isAU } from "shared/core/locale";
import { t } from "i18next";
import { useGreeting } from "../../hooks/useGreeting";
import useLocale from "core/hooks/useLocale/useLocale";
import { useNavigate } from "react-router-dom";
import { useTrackerContext } from "core/state/Trackers/TrackerContext";
import { useUserContext } from "core/state/UserContext";
import PageTitle from "UIPalette/PageTitle/PageTitle";

function ContactUsCard(props: { handleClick: () => void }) {
  return (
    <ClickableRoundedCard
      title={t("home.contactUs.title")}
      icon={<CallUsIcon />}
      description={t("home.contactUs.description")}
      handleClick={props.handleClick}
      showChevron
    />
  );
}

function MoodTrackerModule(props: { moodSet: boolean }) {
  return <>{props.moodSet ? <TodaysMoodCard /> : <MoodTrackerCard />}</>;
}

function TrackersModule() {
  return (
    <RoundedCard>
      <h4>{t("home.trackers.title")}</h4>
      <div className={"flex flex-row justify-evenly h-max-[5rem]"}>
        <WaterTrackerProgressTile inCard={false} size={"md"} />
        <StepsTrackerProgressTile inCard={false} size={"md"} />
        <SleepTrackerProgressTile inCard={false} size={"md"} />
        <GeneralActivityTrackerProgressTile inCard={false} size={"md"} />
      </div>
    </RoundedCard>
  );
}

export const Home = () => {
  const { getCurrentValue } = useTrackerContext();
  const { user } = useUserContext();
  const moodSet = getCurrentValue(TrackerType.Mood) > 0;
  const navigate = useNavigate();
  const greeting = useGreeting();
  const { localeId } = useLocale();

  const client = user?.client;

  return (
    <>
      <PageTitle title={t("pageTitle.home")} />
      <div className="mt-6">
        <div>
          {client && client.logoUrl && (
            <div className={"flex justify-end mb-2"}>
              <div>
                <ClientLogoImage
                  className="max-h-10 md:max-w-[11rem] max-w-[7rem]  h-full object-contain"
                  clientCode={client?.code}
                  imageFilename={client.logoUrl}
                  alt={t("home.clientLogoAltText")}
                />
              </div>
            </div>
          )}
          <h2>{t("home.greeting", { timeOfDay: greeting, firstName: user?.forenames })}</h2>
        </div>
        <TwoColumnLayout>
          <TwoColumnLayout.Left>
            <ProtectedModule wisdomModule={WisdomModules.trackers} moduleComponent={<TrackersModule />} />
            <div className="md:hidden">
              <ProtectedModule wisdomModule={WisdomModules.moodTracker} moduleComponent={<MoodTrackerModule moodSet={moodSet} />} />
            </div>
            <PersonalisedNewsFeed />
          </TwoColumnLayout.Left>
          <TwoColumnLayout.Right>
            <div className="hidden md:block">
              <ProtectedModule wisdomModule={WisdomModules.moodTracker} moduleComponent={<MoodTrackerModule moodSet={moodSet} />} />
            </div>
            <ProtectedModule wisdomModule={WisdomModules.perksAndDiscounts} moduleComponent={<PerksAndDiscountsCard />} />
            <ProtectedModule wisdomModule={WisdomModules.leaderBoards} moduleComponent={<LeaderboardsSingleCard />} />
            <ContactUsCard handleClick={() => navigate("/contact-us")} />
            <Hideable hidden={!isAU(localeId)}>
              <AcknowledgementCard />
            </Hideable>
          </TwoColumnLayout.Right>
        </TwoColumnLayout>
      </div>
    </>
  );
};
