import { useNavigate } from "react-router-dom";
import { ResourceTile } from "../ResourceTile";
import { ReactComponent as FourWeekPlans } from "shared/assets/Icons/four-week-plans.svg";
import { ReactComponent as HealthChecks } from "shared/assets/Icons/health-checks.svg";
import { ReactComponent as BreathingExercises } from "shared/assets/Icons/breathing-exercises.svg";
import { t } from "i18next";
import { ProtectedModule } from "core/auth/ProtectedModule";
import WisdomModules from "core/Modules";

export const WellbeingToolTiles = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-row justify-around text-lg lg:text-xl w-full h-fit gap-2 md:gap-4">
      <ProtectedModule
        wisdomModule={WisdomModules.fourWeekPlan}
        moduleComponent={
          <ResourceTile
            title={t("resources.fourWeekPlanLinkName")}
            ariaLabel={t("resources.fourWeekPlanNavigationLabel")}
            ResourceIcon={FourWeekPlans}
            onClick={() => navigate("/resources/4-week-plans")}
          />
        }
      />
      <ProtectedModule
        wisdomModule={WisdomModules.miniHealthChecks}
        moduleComponent={
          <ResourceTile
            title={t("resources.healthCheckLinkName")}
            ariaLabel={t("resources.healthCheckNavigationLabel")}
            ResourceIcon={HealthChecks}
            onClick={() => navigate("/resources/mini-health-checks")}
          />
        }
      />
      <ResourceTile
        title={t("resources.breathingExercisesLinkName")}
        ariaLabel={t("resources.breathingExercisesNavigationLabel")}
        ResourceIcon={BreathingExercises}
        onClick={() => navigate("/resources/breathing-exercises")}
      />
    </div>
  );
};
