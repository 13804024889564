import { t } from "i18next";
import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";

export const AcknowledgementCard = () => {
  return (
    <RoundedCard className="bg-cover bg-center bg-[url('shared/assets/AcknowledgementImageAsset.png')]">
      <h4>{t("home.acknowledgement.title")}</h4>
      <p>{t("home.acknowledgement.description")} </p>
    </RoundedCard>
  );
};
