import { MiniHealthCheck } from "shared/request/myHealthyAdvantageApi";
import { PageHeader } from "UIPalette/PageHeader/PageHeader";
import { CurvedTopBanner } from "UIPalette/CurvedTopBanner/CurvedTopBanner";
import { StructuredText, StructuredTextDocument } from "react-datocms";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { useTranslation } from "react-i18next";

export type MiniHealthCheckIntroProps = {
  miniHealthCheck: MiniHealthCheck;
  onStartTest: VoidFunction;
};

export const MiniHealthCheckIntro = ({ miniHealthCheck, onStartTest: handleStartTest }: MiniHealthCheckIntroProps) => {
  const { t } = useTranslation();
  return (
    <>
      <CurvedTopBanner>
        <PageHeader title={t("completeMiniHealthCheck.title", { name: miniHealthCheck.name })} backRoute="/resources/mini-health-checks" />
      </CurvedTopBanner>
      <div className="col-span-2">
        <StructuredText data={miniHealthCheck.intro as StructuredTextDocument} />

        <div className="flex flex-row justify-evenly w-full pt-10">
          <div className="w-80">
            <StylableButton color="primary" fullWidth text={t("completeMiniHealthCheck.startTest")} onClick={handleStartTest} />
          </div>
        </div>
      </div>
    </>
  );
};
