import { t } from "i18next";
import { useState } from "react";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { Hideable } from "shared/UI/Hideable/Hideable";
import { GeneralActivityInputModal } from "./GeneralActivityInputModal";
import { trackAddHealthData, trackCancelHealthData } from "core/monitoring/Events";
import { TrackerType } from "core/state/Trackers/TrackerType";

export const GeneralActivityInput = () => {
  const [showAddDataModal, setShowAddDataModal] = useState(false);

  const handleAddData = () => {
    trackAddHealthData(TrackerType.Activity);
    setShowAddDataModal(true);
  };

  const handleCloseModal = () => {
    trackCancelHealthData(TrackerType.Activity);
    setShowAddDataModal(false);
  };

  return (
    <>
      <StylableButton text={t("healthHub.trackers.activity.addData.addButtonLabel")} color="primary" fullWidth={true} onClick={handleAddData} />
      <Hideable hidden={!showAddDataModal}>
        <GeneralActivityInputModal onClose={handleCloseModal} />
      </Hideable>
    </>
  );
};
