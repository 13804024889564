import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";
import { LearningMaterialSummary } from "shared/request/myHealthyAdvantageApi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RefObject, useEffect, useRef, useState } from "react";

type UseLearningMaterialLineClampProps = {
  headingRef: RefObject<HTMLElement>;
  paragraphRef: RefObject<HTMLElement>;
  dependencies: any[];
};

export const useLearningMaterialLineClamp = ({ headingRef, paragraphRef, dependencies }: UseLearningMaterialLineClampProps) => {
  const [clampClass, setClampClass] = useState("line-clamp-2");

  const recalculateClampClass = () => {
    if (headingRef.current && paragraphRef.current) {
      const headingHeight = headingRef.current.offsetHeight;
      const paragraphHeight = paragraphRef.current.offsetHeight;

      const parentDiv = headingRef.current.parentElement!;
      const parentPaddingVertical =
        parseInt(window.getComputedStyle(parentDiv).paddingTop) + parseInt(window.getComputedStyle(parentDiv).paddingBottom);
      const availableSpace = parentDiv.clientHeight - parentPaddingVertical;

      if (headingHeight + paragraphHeight > availableSpace) {
        setClampClass("line-clamp-2");
      } else {
        setClampClass("line-clamp-3");
      }
    }
  };

  useEffect(() => {
    recalculateClampClass();

    window.addEventListener("resize", recalculateClampClass);

    return () => {
      window.removeEventListener("resize", recalculateClampClass);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, headingRef, paragraphRef]);

  const maxHeightClass = clampClass === "line-clamp-2" ? "max-h-10" : "max-h-16";
  return { clampClass, maxHeightClass };
};

type LearningMaterialCardProps = {
  item: LearningMaterialSummary;
  navigateBasePath: string;
};

export const LearningMaterialCard = ({ item, navigateBasePath }: LearningMaterialCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const headingRef = useRef<HTMLHeadingElement>(null);
  const paragraphRef = useRef<HTMLParagraphElement>(null);
  const { clampClass, maxHeightClass } = useLearningMaterialLineClamp({
    headingRef,
    paragraphRef,
    dependencies: [item.title, item.categories, item.duration],
  });

  return (
    <div className="mb-2">
      <button
        className="text-left w-full"
        onClick={() => {
          navigate(`${navigateBasePath}${item.id}`);
        }}
      >
        <RoundedCard className={""}>
          <div className="flex flex-row -m-5 h-[130px]">
            <div className={"w-1/3 overflow-hidden"}>
              <img
                className="rounded-card rounded-r-none object-cover object-center h-full w-full"
                src={`${item.image.url}?h=800`}
                alt={item.image.title ?? item.title}
              />
            </div>
            <div className="p-5 w-2/3">
              <h4 className="text-xl max-h-14 p-0 mb-1 line-clamp-2 overflow-hidden" ref={headingRef}>
                {item.title}
              </h4>
              <p className={`text-sm ${maxHeightClass} text-d-grey ${clampClass} overflow-hidden`} ref={paragraphRef}>
                <span>
                  {item.categories
                    .map((c) => c.name)
                    .sort((a, b) => a.localeCompare(b))
                    .join(", ")}
                </span>
                {item.duration && (
                  <>
                    <span> | </span>
                    <span>{t("resources.durationMinutes", { duration: item.duration })}</span>
                  </>
                )}
              </p>
            </div>
          </div>
        </RoundedCard>
      </button>
    </div>
  );
};
