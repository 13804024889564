export {};

declare global {
  interface StringConstructor {
    formatDateTime(value: string | undefined): string;
  }

  interface String {
    isNullOrEmpty(): boolean;

    formatDateTime(): string;
  }
}

String.formatDateTime = function (value: string | undefined): string {
  if (value === undefined) {
    return "";
  }
  const theDate = new Date(value);
  return `${theDate.toLocaleDateString()} ${theDate.toLocaleTimeString()}`;
};

// eslint-disable-next-line no-extend-native
String.prototype.isNullOrEmpty = function (): boolean {
  const value = String(this);
  if (value === null) return true;
  return value.trim() === "";
};
