import "shared/extensions/string";

import { ReactElement, cloneElement } from "react";

import classNames from "classnames";

export type IconProps = {
  caption?: string;
  icon?: ReactElement;
  fgColour?: string;
  bgColour?: string;
  hasDropShadow?: boolean;
};

export const Icon = ({ caption = "", icon, fgColour = "text-primary-700", bgColour = "bg-white", hasDropShadow = true }: IconProps) => {
  const iconWithProps = icon
    ? cloneElement(icon, {
        className: classNames("w-12"),
      })
    : null;
  return (
    <div className="flex flex-col items-center w-12">
      <div
        className={classNames("inline-flex", "justify-center", "items-center", "w-full", "h-12", "w-12", "rounded-full", fgColour, bgColour, {
          "drop-shadow-md": hasDropShadow,
        })}
      >
        {iconWithProps}
      </div>
      {caption.isNullOrEmpty() ? null : <p className="pt-3 text-center body-2">{caption}</p>}
    </div>
  );
};
