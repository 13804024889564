import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { Hideable } from "shared/UI/Hideable/Hideable";
import { ConfirmationModal } from "shared/UI/Modal/ConfirmationModal/ConfirmationModal";
import { useTrackerContext } from "core/state/Trackers/TrackerContext";
import { TrackerType } from "core/state/Trackers/TrackerType";
import { Time, TimePicker } from "UIPalette/TimePicker/TimePicker";
import { TimeFormatSelector } from "UIPalette/TimePicker/TimeFormatSelector";
import TimeFormat from "UIPalette/TimePicker/TimeFormat";
import TimePeriod from "UIPalette/TimePicker/TimePeriod";
import { trackAddHealthData, trackCancelHealthData, trackSaveHealthData } from "core/monitoring/Events";

export const SleepDurationInputModal = () => {
  const [focusedInput, setFocusedInput] = useState<"from" | "to" | undefined>(undefined);
  const [timeFormat, setTimeFormat] = useState<TimeFormat>(TimeFormat["24H"]);
  const [showAddDataModal, setShowAddDataModal] = useState(false);
  const [timeFrom, setTimeFrom] = useState<Time | undefined>(undefined);
  const [timeTo, setTimeTo] = useState<Time | undefined>(undefined);
  const { setCurrentValue } = useTrackerContext();
  const { t } = useTranslation();
  const secondTimePicker = useRef<HTMLDivElement | undefined>(null);

  const isValid = !!timeFrom && !!timeTo;
  const duration = isValid ? getDurationInMinutes() : 0;

  function reset() {
    setTimeFrom(undefined);
    setTimeTo(undefined);
    setFocusedInput(undefined);
    setShowAddDataModal(false);
  }

  function onConfirm() {
    trackSaveHealthData(TrackerType.Sleep);
    setCurrentValue(TrackerType.Sleep, duration);
    reset();
  }

  function onCancel() {
    trackCancelHealthData(TrackerType.Sleep);
    setFocusedInput(undefined);
    reset();
  }

  function getDurationInMinutes(): number {
    const minutesInADay: number = 1440;

    function convertTimeToMinutes(time: Time): number {
      return time.hour * 60 + time.minute;
    }

    const timeFromMinutes = convertTimeToMinutes(timeFrom!);
    const timeToMinutes = convertTimeToMinutes(timeTo!);
    const isNextDay = timeFromMinutes <= timeToMinutes;

    return isNextDay ? timeToMinutes - timeFromMinutes : minutesInADay - timeFromMinutes + timeToMinutes;
  }

  function convertMinutesToTime(duration: number): Time {
    const hour = Math.floor(duration / 60);
    const minute = Number(duration % 60);
    return { hour, minute };
  }

  useEffect(() => {
    function scrollSecondTimePickerIntoView() {
      if (secondTimePicker.current) {
        secondTimePicker.current.scrollIntoView({ behavior: "smooth" });
      }
    }

    window.addEventListener("resize", scrollSecondTimePickerIntoView);
    return () => {
      window.removeEventListener("resize", scrollSecondTimePickerIntoView);
    };
  });

  const handleAddData = () => {
    trackAddHealthData(TrackerType.Sleep);
    setShowAddDataModal(true);
  };

  return (
    <>
      <StylableButton text={t("healthHub.trackers.sleep.addData.addButtonLabel")} color="primary" fullWidth={true} onClick={handleAddData} />
      <Hideable hidden={!showAddDataModal}>
        <ConfirmationModal
          title={t("healthHub.trackers.sleep.addData.heading")}
          cancelButtonText={t("healthHub.trackers.sleep.addData.cancel")}
          confirmButtonText={t("healthHub.trackers.sleep.addData.save")}
          headerColour="inverted"
          confirmButtonDisabled={!isValid}
          onCancel={onCancel}
          onConfirm={onConfirm}
        >
          <div>
            <div className="flex justify-between">
              <p>{t("healthHub.trackers.sleep.addData.timeFormat")}</p>
              <TimeFormatSelector
                onChange={(format) => {
                  setTimeFormat(format);
                  setTimeFrom(undefined);
                  setTimeTo(undefined);
                }}
                value={timeFormat}
              />
            </div>
            <TimePicker
              id="from"
              key={`time-picker-from-${timeFormat}`}
              label={t("healthHub.trackers.sleep.addData.startTime")}
              onTimeSet={(time) => setTimeFrom(time)}
              format={timeFormat}
              defaultPeriod={TimePeriod.PM}
              expand={focusedInput === "from"}
              onExpandChanged={(isExpanded) => setFocusedInput(isExpanded ? "from" : undefined)}
            />
            <span className="my-4 block" />
            <TimePicker
              ref={secondTimePicker}
              id="to"
              key={`time-picker-to-${timeFormat}`}
              label={t("healthHub.trackers.sleep.addData.endTime")}
              onTimeSet={(time) => setTimeTo(time)}
              format={timeFormat}
              defaultPeriod={TimePeriod.AM}
              expand={focusedInput === "to"}
              onExpandChanged={(isExpanded) => setFocusedInput(isExpanded ? "to" : undefined)}
            />
            {isValid && (
              <p className="mt-4" role="note">
                {t("healthHub.trackers.sleep.addData.sleepDurationNote", { ...convertMinutesToTime(duration) })}
              </p>
            )}
          </div>
        </ConfirmationModal>
      </Hideable>
    </>
  );
};
