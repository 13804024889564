import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LeaderboardsIcon } from "shared/assets/Icons/star.svg";
import React from "react";
import { useGetUserLeaderboardsFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import ViewLoading from "shared/UI/Spinners/ViewLoading";
import { ClickableRoundedCard } from "../../../ContactUs/Components/ContactCard/ClickableRoundedCard";
import { LeaderboardsList } from "../LeaderboardsList/LeaderboardsList";
import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";

export const LeaderboardsSingleCard = () => {
  const navigate = useNavigate();
  const { data: userLeaderboards, isLoading, error } = useGetUserLeaderboardsFunctionRequest({}, { showErrorIfStale: false });
  const hasActiveLeaderboards = !isLoading && !error && userLeaderboards && userLeaderboards.active.length > 0;

  if (isLoading && !!userLeaderboards) {
    return (
      <RoundedCard>
        <ViewLoading heightClass="h-full" />
      </RoundedCard>
    );
  }

  return (
    <>
      {!hasActiveLeaderboards && (
        <ClickableRoundedCard
          title={t("home.leaderboards.noActiveLeaderboardsTitle")}
          icon={<LeaderboardsIcon />}
          description={t("home.leaderboards.description")}
          handleClick={() => navigate("/leaderboards")}
          showChevron
        />
      )}
      {hasActiveLeaderboards && (
        <LeaderboardsList
          title={t("home.leaderboards.activeLeaderboardsTitle")}
          leaderboards={[userLeaderboards.active[0]]}
          titleNavigatesToAllLeaderboards={true}
        />
      )}
    </>
  );
};
