import { useEffect, useState } from "react";

import { ClientCodeState } from "shared/core/state/clientCodeState";
import { ConfirmClient } from "./Components/ConfirmClient/ConfirmClient";
import { DestinationState } from "shared/core/state/destinationState";
import { EnterClientCode } from "./Components/EnterClientCode/EnterClientCode";
import ViewLoading from "shared/UI/Spinners/ViewLoading";
import { appInsightsLogger } from "core/monitoring/AppInsights";
import { useAuth } from "core/auth/useAuth";
import { useConfirmClientCodeFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PageTitle from "UIPalette/PageTitle/PageTitle";

export const Login = () => {
  const handleReset = () => {
    ClientCodeState.clear();
    setClientCode(undefined);
  };

  const handleSubmitConfirm = () => {
    ClientCodeState.set(clientCode!);
    signinRedirect({ extraQueryParams: { client_code: clientCode } });
  };

  const { t } = useTranslation(undefined, { keyPrefix: "signup" });
  appInsightsLogger.info("useAuth - Login.tsx");
  const { isAuthenticated, signinRedirect } = useAuth();
  const navigate = useNavigate();

  const [clientCode, setClientCode] = useState(ClientCodeState.get());
  const clientCodeEntered = !!clientCode;

  const { data, isLoading, error } = useConfirmClientCodeFunctionRequest(
    {
      clientCode: clientCode,
    },
    { shouldFetch: clientCodeEntered, shouldRetryOnError: false, needsBearerToken: false }
  );

  useEffect(() => {
    // Check for use already being logged in (due to auto-renewal of expired refresh token in the background)
    if (isAuthenticated && clientCode) {
      navigate(DestinationState.get());
    }
  }, [isAuthenticated, clientCode, navigate]);

  if (isLoading) {
    return <ViewLoading label={t("loading")} />;
  }

  if (data === undefined) {
    return (
      <>
        <PageTitle title="Enter-Client-Code" />
        <EnterClientCode
          handleClick={(clientCode: string) => setClientCode(clientCode)}
          error={error ? t<string>([`error.confirmCode.${error.response?.status}`, "error.confirmCode.other"]) : undefined}
          initialClientCode={clientCode}
        />
      </>
    );
  }

  return (
    <>
      <PageTitle title="Confirm-Client-Code" />
      <ConfirmClient
        clientName={data?.clientName ?? ""}
        clientLogo={data?.clientLogo}
        clientCode={clientCode!}
        handleReset={handleReset}
        handleNext={handleSubmitConfirm}
      />
    </>
  );
};
