import React, { ForwardedRef, ReactElement } from "react";
import { Input, InputProps } from "../Input/Input";

export const IntegerInput = React.forwardRef((props: Omit<InputProps, "type">, _ref?: ForwardedRef<HTMLInputElement>): ReactElement => {
  return (
    <div className={"relative bg-transparent text-start"}>
      <Input
        {...props}
        type="number"
        onKeyDown={(e) => {
          if (e.key === "." || e.key === "e") {
            e.preventDefault();
          }

          if (e.currentTarget.value.length >= e.currentTarget.maxLength && !isNaN(Number(e.key))) {
            e.preventDefault();
          }

          props.onKeyDown?.(e);
        }}
        ref={_ref}
      />
    </div>
  );
});
