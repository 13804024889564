import { ConfirmationModal } from "shared/UI/Modal/ConfirmationModal/ConfirmationModal";
import { DatoContent } from "UIPalette/Components/DatoContent/DatoContent";
import { t } from "i18next";
import { useGetFourWeekPlanFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { ViewStateWrapper } from "UIPalette/ViewStateWrapper/ViewStateWrapper";

interface TipsAndAdviceModalProps {
  datoFourWeekPlanId: string;
  onCancel: () => void;
  onConfirm: () => void;
}

export const TipsAndAdviceModal = ({ datoFourWeekPlanId, onCancel, onConfirm }: TipsAndAdviceModalProps) => {
  const { data, isLoading, error, mutate } = useGetFourWeekPlanFunctionRequest(
    { id: datoFourWeekPlanId },
    { revalidateIfStale: false, showErrorIfStale: false }
  );

  return (
    <>
      <ConfirmationModal
        title={t("fourWeekPlans.tips&AdviceModal.title")}
        cancelButtonText={t("fourWeekPlans.tips&AdviceModal.cancelButton")}
        confirmButtonText={t("fourWeekPlans.tips&AdviceModal.confirmButton")}
        width={"base"}
        confirmButtonDisabled={isLoading || !!error}
        onConfirm={onConfirm}
        onCancel={onCancel}
      >
        <ViewStateWrapper loading={isLoading} error={!!error} errorMessage={t("fourWeekPlans.resourceError")} onRetry={mutate}>
          <DatoContent content={data?.content} />
        </ViewStateWrapper>
      </ConfirmationModal>
    </>
  );
};
