import { GeneralActivityInput } from "./components/ActivityInput/GeneralActivityInput";
import { GeneralActivityTrackerChart } from "./components/GeneralActivityTrackerChart";
import { GeneralActivityTrackerProgressCard } from "./components/Progress/GeneralActivityTrackerProgressCard";
import { TrackerType } from "core/state/Trackers/TrackerType";
import { useTranslation } from "react-i18next";
import { Tracker } from "../components/Tracker";
import PageTitle from "UIPalette/PageTitle/PageTitle";

export const GeneralActivityTracker = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle title={t("pageTitle.healthHub.generalActivityTracker")} />
      <Tracker trackerType={TrackerType.Activity} heading={t("healthHub.trackers.activity.title")} isSyncable={false}>
        <GeneralActivityTrackerProgressCard />
        <GeneralActivityTrackerChart />
        <GeneralActivityInput />
      </Tracker>
    </>
  );
};
