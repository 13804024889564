import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import useSWR, { SWRResponse } from "swr";

import { SWRConfiguration } from "swr/_internal";
import { client } from "./client";
import { setAuthHeaders } from "./headers";
import { useEffect } from "react";

export interface IReturn<Data, Error>
  extends Pick<SWRResponse<AxiosResponse<Data>, AxiosError<Error>>, "isValidating" | "error" | "mutate" | "isLoading"> {
  data: Data | undefined;
  response: AxiosResponse<Data> | undefined;
}

export interface ISWRConfig<Data = unknown, Error = unknown> extends Omit<SWRConfiguration<AxiosResponse<Data>, AxiosError<Error>>, "onSuccess"> {
  onSuccess?: (response: AxiosResponse<Data>, key: string) => void;
  shouldFetch?: boolean;
  needsBearerToken?: boolean;
  revalidateOnMountIfStale?: boolean;
  showErrorIfStale?: boolean;
}

export const useGetRequest = <Data = unknown, Error = unknown>(
  axiosConfig: AxiosRequestConfig,
  SWRConfig?: ISWRConfig<Data, Error>
): IReturn<Data, Error> => {
  const { shouldFetch = true, needsBearerToken = true, revalidateOnMountIfStale, showErrorIfStale = true, ...baseSWRConfig } = SWRConfig ?? {};
  setAuthHeaders(needsBearerToken, axiosConfig);

  if (revalidateOnMountIfStale !== undefined) {
    baseSWRConfig.revalidateOnMount = revalidateOnMountIfStale;
  }

  const {
    data: response,
    error,
    isValidating,
    mutate,
    isLoading,
  } = useSWR<AxiosResponse<Data>, AxiosError<Error>>(
    () => (shouldFetch ? generateKey(axiosConfig) : null),
    () => client(axiosConfig),
    baseSWRConfig
  );

  useEffect(() => {
    if (revalidateOnMountIfStale === false && !response) mutate();
  }, [response, mutate, revalidateOnMountIfStale]);

  return {
    data: response && response.data,
    response,
    error: !showErrorIfStale && response ? undefined : error,
    isValidating,
    isLoading,
    mutate,
  };
};

const generateKey = (axiosConfig: AxiosRequestConfig) => {
  const requestHasParams = !!axiosConfig.params;
  return requestHasParams ? `${axiosConfig.url!}?${new URLSearchParams(axiosConfig.params).toString()}` : axiosConfig.url!;
};
