import { AxiosError, AxiosRequestConfig, RawAxiosRequestHeaders } from "axios";
import useSWRMutation, { SWRMutationConfiguration } from "swr/mutation";

import { client } from "./client";
import { setAuthHeaders } from "./headers";

export type SWRMutationConfig<Request = any, Response = any, Error = any> = SWRMutationConfiguration<Response, AxiosError<Error>, Request, string>;

export const useMutationRequest = <Request extends { body?: any; query?: any } | undefined, Response = any, Error = any>({
  url,
  method,
  headers,
  mutationConfig,
  axiosConfig,
}: {
  url: string;
  method: string;
  headers: RawAxiosRequestHeaders;
  mutationConfig?: SWRMutationConfiguration<Response, AxiosError<Error>, Request, string>;
  axiosConfig?: AxiosRequestConfig;
}) => {
  setAuthHeaders(true, { headers, ...axiosConfig });

  const { trigger, data, isMutating, error, reset } = useSWRMutation<Response, AxiosError<Error>, string, Request>(
    url,
    (url: string, options: { arg: Request }) =>
      client.request({
        url,
        method,
        headers,
        data: options.arg?.body,
        params: options.arg?.query,
        ...axiosConfig,
      }),
    mutationConfig
  );

  return { trigger, data, isMutating, error, reset };
};
