import { CurvedTopBanner } from "UIPalette/CurvedTopBanner/CurvedTopBanner";
import { PageHeader } from "UIPalette/PageHeader/PageHeader";
import { t } from "i18next";

export const ViewHeader = () => {
  return (
    <>
      <CurvedTopBanner>
        <PageHeader title={t("leaderboards.create.heading")} backRoute={-1} />
      </CurvedTopBanner>
      <p role="note">{t("leaderboards.create.description")}</p>
    </>
  );
};
