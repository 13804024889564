export const AppRoutes = {
  miniHealthChecks: "mini-health-checks",
  waterTracker: "/health-hub/water-tracker",
  stepsTracker: "/health-hub/steps-tracker",
  activityTracker: "/health-hub/general-activity-tracker",
  breathingExercises: "/resources/breathing-exercises",
  sleepTracker: "/health-hub/sleep-tracker",
  healthHub: "/health-hub",
  account: "/account",
  index: "/",
  home: "/home",
  resources: "/resources",
  brightTV: "/resources/bright-tv/",
} as const;

export type AppRoute = (typeof AppRoutes)[keyof typeof AppRoutes];
