import {
  LeaderboardPreview,
  useGetLeaderboardTemplateFunctionRequest,
  useJoinLeaderboardFunctionRequest,
  usePreviewLeaderboardFunctionRequest,
} from "shared/request/myHealthyAdvantageApi";
import { getNow, getTimeLeft } from "core/helpers/time";
import { useNavigate, useParams } from "react-router-dom";

import ErrorAlert from "shared/UI/Alerts/ErrorAlert";
import { Preview } from "../Create/components/Preview/Preview";
import StylableButton from "shared/UI/Buttons/StylableButton";
import ViewLoading from "shared/UI/Spinners/ViewLoading";
import { toastNotify } from "shared/UI/Toaster/Toaster";
import { useTranslation } from "react-i18next";
import PageTitle from "UIPalette/PageTitle/PageTitle";
import { trackFormSubmission } from "core/monitoring/Events";

export const LeaderboardJoin = () => {
  const { code } = useParams();
  const isCodeValid = code !== undefined && /^[XCJQ4D1TMB8G6R52A9E3Z7FV0HKNPSOW-]+$/.test(code);

  const { trigger } = useJoinLeaderboardFunctionRequest({});
  const {
    isLoading: leaderboardLoading,
    error: leaderboardError,
    data: leaderboard,
  } = usePreviewLeaderboardFunctionRequest({ leaderboardCode: code }, { shouldFetch: isCodeValid });
  const {
    isLoading: leaderboardTemplateLoading,
    error: leaderboardTemplateError,
    data: leaderboardTemplate,
  } = useGetLeaderboardTemplateFunctionRequest({ id: leaderboard?.templateId }, { shouldFetch: leaderboard !== undefined });
  const navigate = useNavigate();
  const { t } = useTranslation();

  async function JoinLeaderboard() {
    try {
      const body = { leaderboardCode: code! };
      trackFormSubmission("join_leaderboard", body, false);
      await trigger({ body });
      navigate(`../${code!}`);
    } catch {
      toastNotify(t("leaderboards.join.errorJoining"), "error");
    }
  }

  if (leaderboardLoading || leaderboardTemplateLoading) {
    return <ViewLoading />;
  }

  if (!isCodeValid || (leaderboardError && leaderboardError?.response?.status === 404)) {
    return (
      <>
        <p>{t("leaderboards.join.notFound", { code })}</p>
      </>
    );
  }

  if (leaderboardError || leaderboardTemplateError) {
    return <ErrorAlert content={t("leaderboards.join.errorLoading")} />;
  }

  const subtitle = buildSubtitle(leaderboard!);

  return (
    <>
      <PageTitle title={t("pageTitle.leaderboards.preview", { code: leaderboardTemplate?.name ?? "" })} />
      <div className="flex flex-grow flex-col">
        <Preview name={leaderboardTemplate!.name!} content={leaderboardTemplate!.description} subtitle={subtitle} onClose={-1}>
          <StylableButton
            className="bright-button preview-button"
            fullWidth
            color="primary"
            type="button"
            text={t("leaderboards.join.joinLeaderboardButton")}
            aria-label={t("leaderboards.join.joinLeaderboardButton")}
            onClick={JoinLeaderboard}
          />
        </Preview>
      </div>
    </>
  );

  function buildSubtitle(leaderboard: LeaderboardPreview) {
    const numberOfMembers = t("leaderboards.display.subTitle", { count: leaderboard!.currentNumberOfParticipants });
    const timeLeft = getTimeLeft(getNow(), Date.fromDateOnly(leaderboard!.endDate)!);

    return `${numberOfMembers} - ${timeLeft}`;
  }
};
