export type ProgressBarProps = {
  percentageComplete: number;
  backgroundColour: string;
  progressColour: string;
};

export const ProgressBar = ({ percentageComplete, backgroundColour, progressColour }: ProgressBarProps) => {
  return (
    <div className={`w-full h-2 rounded ${backgroundColour}`}>
      <div className={`${progressColour} h-2 rounded`} style={{ width: `${percentageComplete}%` }}></div>
    </div>
  );
};

ProgressBar.defaultProps = { backgroundColour: "bg-l-grey", progressColour: "bg-brand" };
