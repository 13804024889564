import React, { useState } from "react";
import cn from "classnames";
import Icon from "@brighthr/component-icon";
import { IconNameType } from "../IconTypes";

export type AlertProps = React.HTMLAttributes<HTMLDivElement> & {
  fullWidth?: boolean;
  inline?: boolean;
  type: "information" | "success" | "warning" | "error";
  icon?: IconNameType;
  size?: "xs" | "sm" | "base";
  title?: string;
  dismiss?: boolean;
  alertValidationShowState?: boolean;
  border?: boolean;
  className?: string;
  onDismiss?: () => void;
};
const sizeOptions = {
  base: "p-4",
  sm: "py-2 px-4",
  xs: "py-1 px-2",
};
const AlertTypeColours = {
  information: {
    background: "bg-primary-50",
    border: "border-primary-400",
    text: "text-neutral-700",
    icon: "fill-primary-400",
  },
  success: {
    background: "bg-success-100",
    border: "border-success-600",
    text: "text-neutral-900",
    icon: "fill-success-700",
  },
  warning: {
    background: "bg-warning-100",
    border: "border-warning-600",
    text: "text-warning-800",
    icon: "fill-warning-600",
  },
  error: {
    background: "bg-white",
    border: "border-error-600",
    text: "text-error-800",
    icon: "fill-error-600",
  },
};

const Alert = ({
  fullWidth = false,
  inline = false,
  type = "information",
  icon,
  size = "base",
  title = "",
  children,
  dismiss = false,
  alertValidationShowState = true,
  border = false,
  className = "",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onDismiss = () => {},
}: AlertProps): React.ReactElement => {
  const [displayState, setDisplayState] = useState(true);

  const onDismissCallback = () => {
    if (onDismiss) {
      onDismiss();
    }
    setDisplayState(false);
  };

  if (!displayState) {
    return <></>;
  }

  if (!alertValidationShowState) {
    return <></>;
  }

  return (
    <div
      className={cn(
        "rounded-lg flex",
        sizeOptions[size],
        { "max-w-md": !fullWidth },
        { "items-center": inline },
        { "border-2": border },
        { [`${AlertTypeColours[type].border}`]: border },
        AlertTypeColours[type].background,
        AlertTypeColours[type].text,
        className
      )}
      role="alert"
      aria-live="polite"
    >
      {icon && (
        <div
          className={cn("align-top", {
            "mr-2 w-6": size === "xs",
            "pr-4 w-11": size !== "xs",
          })}
        >
          <Icon size={size === "xs" ? 20 : 30} className={`${AlertTypeColours[type].icon}`} iconName={icon} aria-hidden="true" />
        </div>
      )}
      <div className="w-full text-neutral-600">
        {title && <div className={cn("font-bold", { "text-base": !children }, { "text-xl": children }, AlertTypeColours[type].text)}>{title}</div>}
        {children}
      </div>
      {dismiss && (
        <div>
          <button type="button" className="p-0 w-18" onClick={onDismissCallback} data-testid="dismiss-button">
            <span className="float-right text-sm font-bold text-primary-700">Dismiss</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default Alert;
