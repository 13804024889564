import "./CurvedTopBanner.css";

import { PropsWithChildren } from "react";

export type CurvedTopBannerProps = PropsWithChildren & {
  className?: string | undefined;
};

export const CurvedTopBanner = ({ className, children }: CurvedTopBannerProps) => {
  return (
    <div className={`outerTopBanner ${className}`}>
      <div className="innerTopBanner">
        <div className="flex flex-col w-full mx-auto lg:max-w-[1761px] px-6 md:px-[5rem]">{children}</div>
      </div>
    </div>
  );
};
