import "./StylableButton.css";

import Button, { ButtonProps } from "@brighthr/component-button";

import classNames from "classnames";

export declare type StylableButtonProps = ButtonProps & {
  className?: string;
};

const StylableButton = ({ className, ...rest }: StylableButtonProps) => {
  return (
    <div className={classNames(className !== undefined ? className : "default-button", "styled-button")}>
      <Button {...rest} />
    </div>
  );
};

export default StylableButton;
