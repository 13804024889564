import { CompletedMiniHealthCheck, MiniHealthCheck } from "shared/request/myHealthyAdvantageApi";
import { PageHeader } from "UIPalette/PageHeader/PageHeader";
import { StructuredText, StructuredTextDocument } from "react-datocms";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { useTranslation } from "react-i18next";
import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";
import { LearningMaterials } from "UIPalette/LearningMaterials/LearningMaterials";
import { TrackerCard } from "UIPalette/TrackerCard/TrackerCard";
import { FourWeekPlanList } from "UIPalette/FourWeekPlanList/FourWeekPlanList";
import cn from "classnames";
import { CurvedTopBanner } from "UIPalette/CurvedTopBanner/CurvedTopBanner";
import { TwoColumnLayout } from "UIPalette/Layout/TwoColumnLayout";

export type MiniHealthCheckResultsProps = {
  miniHealthCheck: MiniHealthCheck;
  completed: CompletedMiniHealthCheck;
  onRetakeTest: VoidFunction;
};

export const MiniHealthCheckResults = ({ miniHealthCheck, completed, onRetakeTest }: MiniHealthCheckResultsProps) => {
  const { t } = useTranslation();
  const result = miniHealthCheck.results.find((r) => r.id === completed.resultId);
  const hasTrackersOr4WeekPlans = miniHealthCheck.trackers.length > 0 || miniHealthCheck.fourWeekPlans.length > 0;

  return (
    <>
      <CurvedTopBanner>
        <PageHeader title={t("completeMiniHealthCheck.title", { name: miniHealthCheck.name })} backRoute="/resources/mini-health-checks" />
      </CurvedTopBanner>
      <p className="pb-3">{t("completeMiniHealthCheck.retakeAdvice")}</p>

      <RoundedCard className="mb-5">
        {result && <h3>{result.title}</h3>}
        {result && <StructuredText data={result.content as StructuredTextDocument} />}
        <div className="flex flex-row justify-evenly w-full pt-10">
          <div className="w-80">
            <StylableButton color="primary" fullWidth text={t("completeMiniHealthCheck.retakeTest")} onClick={onRetakeTest} />
          </div>
        </div>
      </RoundedCard>

      <TwoColumnLayout>
        <TwoColumnLayout.Left>
          {miniHealthCheck.trackers.map((tracker) => {
            return <TrackerCard key={`tracker${tracker.id}`} tracker={tracker} />;
          })}

          {miniHealthCheck.fourWeekPlans.length > 0 && (
            <FourWeekPlanList title={t("completeMiniHealthCheck.fourWeekPlans")} plans={miniHealthCheck.fourWeekPlans} />
          )}
        </TwoColumnLayout.Left>

        <TwoColumnLayout.Right>
          {miniHealthCheck.categories.length > 0 && (
            <div className={cn("flex flex-col", { "sm:col-start-2": hasTrackersOr4WeekPlans })}>
              <LearningMaterials
                categoryIds={miniHealthCheck.categories.map((c) => c.id)}
                errorMessage={t("completeMiniHealthCheck.relatedArticlesErrorButton")}
                ariaLabel={t("completeMiniHealthCheck.relatedArticles")}
              />
            </div>
          )}
        </TwoColumnLayout.Right>
      </TwoColumnLayout>
    </>
  );
};
