import { AddWaterIntakeCard } from "./components/AddWaterIntakeCard/AddWaterIntakeCard";
import { TrackerType } from "core/state/Trackers/TrackerType";
import { WaterTrackerChart } from "./components/WaterTrackerChart";
import { WaterTrackerProgressCard } from "./components/Progress/WaterTrackerProgressCard";
import { t } from "i18next";
import { Tracker } from "../components/Tracker";
import PageTitle from "UIPalette/PageTitle/PageTitle";

export const WaterTracker = () => {
  return (
    <>
      <PageTitle title={t("pageTitle.healthHub.waterTracker")} />
      <Tracker trackerType={TrackerType.Water} heading={t("healthHub.trackers.water.heading")} isSyncable={true}>
        <WaterTrackerProgressCard />
        <WaterTrackerChart />
        <AddWaterIntakeCard />
      </Tracker>
    </>
  );
};
