import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export const AnchorScroller = () => {
  const { pathname, hash } = useLocation();

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    if (hash) {
      const scrollToElement = document.getElementById(hash.substring(1));
      if (scrollToElement == null) {
        scrollToTop();
      } else {
        scrollToElement.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      scrollToTop();
    }
  }, [pathname, hash]);

  return null;
};
