import React, { ReactNode } from "react";

interface TwoColumnLayoutProps {
  children?: ReactNode;
}

export const TwoColumnLayout = ({ children }: TwoColumnLayoutProps) => {
  let leftChildren: ReactNode = null;
  let rightChildren: ReactNode = null;

  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.type === Left) {
        leftChildren = child.props.children;
      } else if (child.type === Right) {
        rightChildren = child.props.children;
      }
    }
  });

  return (
    <div className="flex flex-col md:flex-row gap-8">
      <div className="flex flex-col w-full md:w-1/2 gap-6">{leftChildren}</div>
      <div className="flex flex-col w-full md:w-1/2 gap-6">{rightChildren}</div>
    </div>
  );
};

interface ColumnProps {
  children: ReactNode;
}

const Left: React.FC<ColumnProps> = ({ children }) => <>{children}</>;
const Right: React.FC<ColumnProps> = ({ children }) => <>{children}</>;

TwoColumnLayout.Left = Left;
TwoColumnLayout.Right = Right;
