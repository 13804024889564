import { appInsightsLogger } from "core/monitoring/AppInsights";
import { UserState } from "core/state/userState";
import { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import { User, UserManager } from "oidc-client-ts";
import { ClientCodeState } from "shared/core/state/clientCodeState";
import { useMobilePlatform } from "core/hooks/useMobilePlatform";
import { authenticateMobileUser, flutterAppOpen } from "core/javascriptChannels";

export type TokenRenewalContextType = {
  renewToken: () => void;
};

type TokenRenewalContextProviderProps = {
  userManager: UserManager;
};

export const TokenRenewalContext = createContext<TokenRenewalContextType>({} as TokenRenewalContextType);

export const useTokenRenewalContext = () => useContext(TokenRenewalContext);

export const TokenRenewalContextProvider = (props: PropsWithChildren & TokenRenewalContextProviderProps) => {
  const [startRefresh, setStartRefresh] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const { isMobileApp } = useMobilePlatform();

  useEffect(() => {
    const renewToken = async () => {
      function tokenNotExpiringSoon(odicUser: User) {
        return odicUser.expires_in != null && odicUser.expires_in > 300;
      }

      function tokenNotExpired(odicUser: User) {
        return !odicUser.expired;
      }

      const updateUserTokens = async () => {
        // Return true if flutter token was overridden
        let overridden = false;
        const userAuth = await flutterAppOpen();
        if (userAuth) {
          const user = await props.userManager.getUser();
          const flutterUser = User.fromStorageString(userAuth);
          if (ClientCodeState.isDemoClient()) {
            appInsightsLogger.info(
              `Flutter Token Details -  Refresh Token: ${flutterUser.refresh_token} Access token: ${flutterUser.access_token} UserObject: ${userAuth}`
            );
            appInsightsLogger.info(
              `Web App Token Details -  Refresh Token: ${user?.refresh_token} Access token: ${user?.access_token} Expires At: ${
                user?.expires_at
              } IsExpired: ${user?.expired} Expires in: ${user?.expires_in}  IsMobile: ${!!window.flutter_inappwebview ? true : false}`
            );
          }
          if (user != null) {
            if (user.access_token !== flutterUser.access_token || user.refresh_token !== flutterUser.refresh_token) {
              appInsightsLogger.info("Updating auth and refresh tokens from app");
              overridden = true;
              user.access_token = flutterUser.access_token;
              user.refresh_token = flutterUser.refresh_token;
              await props.userManager.storeUser(user);
              await props.userManager.getUser(); // Triggers user loaded event to update auth state
            } else {
              appInsightsLogger.info("Skipping update of auth tokens - background tokens have same value");
            }
          } else {
            appInsightsLogger.info("User object was not found - overriding object from flutter");
            overridden = true;
            await props.userManager.storeUser(flutterUser);
            await props.userManager.getUser();
          }
        }
        return overridden;
      };

      try {
        const user = UserState.get();
        if (user) {
          appInsightsLogger.info("User available.");
          const odicUser = await props.userManager.getUser();
          if (ClientCodeState.isDemoClient()) {
            appInsightsLogger.info(
              `Token Details -  Refresh Token: ${odicUser?.refresh_token} Access token: ${odicUser?.access_token}  Expires At: ${
                odicUser?.expires_at
              }  IsExpired: ${odicUser?.expired} Expires in: ${odicUser?.expires_in} IsMobile: ${!!window.flutter_inappwebview ? true : false}`
            );
          }
          if (odicUser !== null && (tokenNotExpired(odicUser) || tokenNotExpiringSoon(odicUser))) {
            appInsightsLogger.info("User available. Not expired or expiring soon. Not renewing token");
            return;
          }

          const overrideWithFlutterTokens = await updateUserTokens();
          if (overrideWithFlutterTokens) {
            appInsightsLogger.info("User Auth object has been updated with data in flutter. No longer trying to renew");
            return;
          }

          appInsightsLogger.info("User available. Attempting token renewal...");
          if (ClientCodeState.isDemoClient()) {
            appInsightsLogger.info(
              `onRenew Before Token Details -  Refresh Token: ${odicUser?.refresh_token} Access token: ${odicUser?.access_token}  Expires At: ${
                odicUser?.expires_at
              }  IsExpired: ${odicUser?.expired} Expires in: ${odicUser?.expires_in} IsMobile: ${!!window.flutter_inappwebview ? true : false}`
            );
          }
          await props.userManager.signinSilent();
          appInsightsLogger.info("Silent token renewal on expiry successful");
          const userAfter = await props.userManager.getUser();
          if (ClientCodeState.isDemoClient()) {
            appInsightsLogger.info(
              `onRenew After Token Details -  Refresh Token: ${userAfter?.refresh_token} Access token: ${userAfter?.access_token}  Expires At: ${
                userAfter?.expires_at
              }  IsExpired: ${userAfter?.expired} Expires in: ${userAfter?.expires_in} IsMobile: ${!!window.flutter_inappwebview ? true : false}`
            );
          }

          if (isMobileApp) {
            if (ClientCodeState.isDemoClient()) {
              appInsightsLogger.info(
                `Calling autheticateMobileUser - TokenRenewalContext: refreshToken: ${userAfter?.refresh_token} accessToken:${
                  userAfter?.access_token
                }, clientCode: ${ClientCodeState.get()}`
              );
            }
            const clientCode = ClientCodeState.get();
            if (userAfter && clientCode) {
              authenticateMobileUser(clientCode, userAfter?.toStorageString());
            }
          }
        }
      } catch (err) {
        appInsightsLogger.error("Error from signinSilent on expiry:", err);
        if (ClientCodeState.isDemoClient()) {
          const odicUser = await props.userManager.getUser();
          appInsightsLogger.error(
            `Refresh Token being used in above call that failed is: ${odicUser?.refresh_token} Access_Token: ${odicUser?.access_token}  Expires At: ${
              odicUser?.expires_at
            }  IsExpired: ${odicUser?.expired} Expires in: ${odicUser?.expires_in} IsMobile: ${!!window.flutter_inappwebview ? true : false}`
          );
        }
      } finally {
        setIsLocked(false);
        setStartRefresh(false);
      }
    };

    if (startRefresh && !isLocked) {
      setIsLocked(true);
      renewToken();
    } else {
      setStartRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLocked, startRefresh]);

  return <TokenRenewalContext.Provider value={{ renewToken: () => setStartRefresh(true) }}>{props.children}</TokenRenewalContext.Provider>;
};
