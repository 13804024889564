const WisdomModules = {
  perksAndDiscounts: 'perks-and-discounts',
  videoChat: 'video-chat',
  moodTracker: 'mood-tracker',
  achievements: 'achievements',
  fourWeekPlan: 'four-week-plans',
  leaderBoards: 'leaderboards',
  employeeDirectory: 'employee-directory',
  trackers: 'wellbeing-trackers',
  miniHealthChecks: 'mini-health-checks'
} as const;

export type WisdomModule = typeof WisdomModules[keyof typeof WisdomModules];

export default WisdomModules;