export type BreathingExerciseProgressBarProps = {
  percentageComplete: number;
  background: string;
  progressColour: string;
};

export const BreathingExerciseProgressBar = ({ percentageComplete, background, progressColour }: BreathingExerciseProgressBarProps) => {
  return (
    <div className={`w-full h-2 rounded ${background}`}>
      <div className="h-2 rounded" style={{ width: `${percentageComplete}%`, backgroundColor: progressColour }}></div>
    </div>
  );
};
