import { IconButton } from "UIPalette/IconButton/IconButton";
import { Modal, ModalBody } from "shared/UI/Modal/Modal";
import { t } from "i18next";
import { useState } from "react";
import StylableButton from "shared/UI/Buttons/StylableButton";
import { ReactComponent as MinusImage } from "shared/assets/Icons/minus.svg";
import { ReactComponent as PlusImage } from "shared/assets/Icons/plus.svg";
import "shared/extensions/number";
import "./AddWaterIntakeModal.css";

enum MillilitresInA {
  Glass = 250,
  Pint = 568,
}

export interface AddWaterIntakeModalProps {
  millilitres: number;
  onClose: Function;
  onSave: Function;
}

export const AddWaterIntakeModal = (props: AddWaterIntakeModalProps) => {
  const [millilitres, setMillilitres] = useState(props.millilitres);

  const displayedMillilitres = t("healthHub.trackers.water.addData.units.millilitres", { count: millilitres });
  const glasses = t("healthHub.trackers.water.addData.units.glasses", { count: (millilitres / MillilitresInA.Glass).toDecimalPlaces(2) });
  const pints = t("healthHub.trackers.water.addData.units.pints", { count: (millilitres / MillilitresInA.Pint).toDecimalPlaces(2) });

  function decrement() {
    if (millilitres > 0) {
      setMillilitres(millilitres - MillilitresInA.Glass);
    }
  }

  function increment() {
    setMillilitres(millilitres + MillilitresInA.Glass);
  }

  return (
    <Modal width="xs" close={() => props.onClose()}>
      <ModalBody>
        <div className="flex flex-col gap-3">
          <h2 className="pb-0">{t("healthHub.trackers.water.addData.heading")}</h2>
          <p role="paragraph">{t("healthHub.trackers.water.addData.description")}</p>
          <div className="flex flex-row">
            <IconButton
              ariaLabel={t("healthHub.trackers.buttons.aria-minus")}
              icon={<MinusImage width="24" height="24" />}
              active={millilitres > 0}
              fgColour="fg-brand-aa"
              bgColour="bg-sky"
              handleClick={decrement}
              hasDropShadow={false}
            />
            <div className="flex flex-col flex-grow place-items-center">
              <span role="note" aria-label={t("healthHub.trackers.water.addData.aria.glasses")}>
                {glasses}
              </span>
              <div className="flex-row">
                <span role="note" aria-label={t("healthHub.trackers.water.addData.aria.millilitres")}>
                  {displayedMillilitres}
                </span>
                <span> / </span>
                <span role="note" aria-label={t("healthHub.trackers.water.addData.aria.pints")}>
                  {pints}
                </span>
              </div>
            </div>
            <IconButton
              ariaLabel={t("healthHub.trackers.buttons.aria-plus")}
              icon={<PlusImage width="24" height="24" />}
              active={true}
              fgColour="fg-brand-aa"
              bgColour="bg-sky"
              handleClick={increment}
              hasDropShadow={false}
            />
          </div>
        </div>
        <div className="mt-6 flex justify-end gap-x-3">
          <StylableButton
            className="buttons"
            text={t("healthHub.trackers.buttons.back")}
            color="primary"
            outlineButton={true}
            onClick={() => props.onClose()}
          />
          <StylableButton className="buttons" text={t("healthHub.trackers.buttons.save")} color="primary" onClick={() => props.onSave(millilitres)} />
        </div>
      </ModalBody>
    </Modal>
  );
};
