import { BreathingExercise, BreathingExerciseStage } from "shared/request/myHealthyAdvantageApi";
import { ReactComponent as BackButtonIcon } from "shared/assets/Icons/back.svg";
import { useTranslation } from "react-i18next";
import { Circles } from "../Circles/Circles";
import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { ReactComponent as PlayIcon } from "shared/assets/Icons/play.svg";
import { ReactComponent as PauseIcon } from "shared/assets/Icons/pause.svg";
import { ReactComponent as MuteIcon } from "shared/assets/Icons/volume-mute.svg";
import { ReactComponent as UnMuteIcon } from "shared/assets/Icons/volume-up.svg";
import { BreathingExerciseRunnerState } from "./BreathingExerciseRunnerState";
import { darkenColour } from "shared/UI/colours";
import { BreathingExerciseProgressBar } from "./BreathingExerciseProgressBar";

export type BreathingExerciseRunnerProps = {
  exercise: BreathingExercise;
  duration: number;
  onComplete: VoidFunction;
  onCancel: VoidFunction;
};

export const BreathingExerciseRunner = ({ exercise, duration, onComplete, onCancel }: BreathingExerciseRunnerProps) => {
  const { t } = useTranslation();
  const [playerState, setPlayerState] = useState<BreathingExerciseRunnerState>(new BreathingExerciseRunnerState());
  const durationInSeconds = duration * 60;
  const [remainingDuration, setRemainingDuration] = useState(durationInSeconds);
  const animationRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onTimerTick = () => {
      const nextState = playerState.next(exercise.stages);
      if (nextState !== undefined) {
        const newRemainingDuration = Math.max(remainingDuration - 1, 0);
        setRemainingDuration(newRemainingDuration);
        if (newRemainingDuration === 0) {
          onComplete();
          nextState.muted = true;
          nextState.playing = false;
        }

        setPlayerState(nextState);
        if (nextState.currentStageIndex !== playerState.currentStageIndex) {
          applyAnimation(animationRef.current!, exercise.stages[nextState.currentStageIndex!]);
        }
      }
    };

    const tick = setInterval(onTimerTick, 1000);
    return () => {
      clearInterval(tick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerState]);

  return (
    <div className="h-full w-full z-MODAL fixed top-0 left-0" style={{ background: exercise.animationColour.hex }}>
      <div className="relative m-10 h-full text-white">
        <button className="pr-2" aria-label={t("navigation.backButton")} onClick={onCancel}>
          <BackButtonIcon width="40" title={t("navigation.backButton")} />
        </button>
        {!playerState.introCompleted && (
          <button
            className="pr-2 float-right text-d-grey font-bold text-[18pt]"
            onClick={() => setPlayerState(playerState.update({ introCompleted: true }))}
          >
            {t("breathingExercises.skipIntro")}
          </button>
        )}
        <div className="h-3/4 flex flex-col items-center justify-center">
          <Circles ref={animationRef} baseColour={exercise.animationColour.hex} className="w-40 my-20" style={{ transform: "scale(1.0)" }} />

          <div className="hidden">
            <ReactPlayer
              url={exercise.voiceIntro.url}
              playing={playerState.playing && !playerState.introCompleted}
              volume={playerState.muted ? 0 : 1}
              onEnded={() => setPlayerState(playerState.update({ introCompleted: true }))}
            />
            <ReactPlayer url={exercise.audioClip.url} playing={playerState.playing} loop={true} volume={playerState.muted ? 0 : 1} />
          </div>

          <div className="h-10">
            {playerState.currentStageIndex !== undefined && (
              <span className="text-xl">{exercise.stages[playerState.currentStageIndex!].stageType.replace(/([a-z])([A-Z])/g, "$1 $2")}</span>
            )}
          </div>

          <div className="pb-5">
            <button
              className="bg-white text-grey rounded-full p-2 mr-5"
              aria-label={playerState.playing ? t("breathingExercises.pauseLabel") : t("breathingExercises.playLabel")}
              onClick={() => setPlayerState(playerState.update({ playing: !playerState.playing }))}
            >
              {playerState.playing ? <PauseIcon className="w-14 h-10" /> : <PlayIcon className="w-14 h-10" />}
            </button>

            <button
              className="text-white p-2 ml-5"
              aria-label={playerState.muted ? t("breathingExercises.unmuteLabel") : t("breathingExercises.muteLabel")}
              onClick={() => setPlayerState(playerState.update({ muted: !playerState.muted }))}
            >
              {playerState.muted ? <MuteIcon className="w-14 h-10" /> : <UnMuteIcon className="w-14 h-10" />}
            </button>
          </div>
          <div className="w-full md:w-3/4 lg:w-1/2">
            <BreathingExerciseProgressBar
              percentageComplete={(1 - remainingDuration / durationInSeconds) * 100}
              progressColour={darkenColour(exercise.animationColour.hex, 50)}
              background="bg-white"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const applyAnimation = (element: HTMLDivElement, stage: BreathingExerciseStage) => {
  switch (stage.stageType) {
    case "BreathIn":
      element.style.transform = "scale(1.5)";
      element.animate([{ transform: "scale(1.0)" }, { transform: "scale(1.5)" }], stage.duration * 1000);
      break;
    case "BreathOut":
      element.style.transform = "scale(1.0)";
      element.animate([{ transform: "scale(1.5)" }, { transform: "scale(1.0)" }], stage.duration * 1000);
      break;
    default:
      break;
  }
};
