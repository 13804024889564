import { AccessTokenState } from "../state/accessTokenState";
import { AxiosRequestConfig } from "axios";
import { ClientCodeState } from "../state/clientCodeState";

export function setAuthHeaders(needsBearerToken: boolean, axiosConfig: AxiosRequestConfig) {
  if (axiosConfig.headers!["X-Client-Code?"]) {
    delete axiosConfig.headers!["X-Client-Code?"];
  }

  const clientCode = ClientCodeState.get();
  if (clientCode !== undefined) {
    axiosConfig.headers!["X-Client-Code"] = clientCode;
  }

  if (needsBearerToken) {
    const accessToken = AccessTokenState.get();
    if (accessToken !== undefined) {
      axiosConfig.headers!.Authorization = `Bearer ${accessToken}`;
    }
  }
}
