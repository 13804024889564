import i18n from "i18next";

export const millisecondsInADay = 86_400_000;
const millisecondsInAnHour = 3_600_000;
const millisecondsInAMinute = 60_000;
const millisecondsInASecond = 1_000;

export function convertMinutesToHours(minutes: number): number {
  var wholeHours = Math.floor(minutes / 60);
  var partialHour = Number(((minutes % 60) / 60).toFixed(2));
  return wholeHours + partialHour;
}

export function getTimeLeft(from: Date, to: Date): string {
  to.setHours(23, 59, 59, 999);

  const millisecondsRemaining = to.getTime() - from.getTime() + 1;
  if (lessThanASecondRemaining(millisecondsRemaining)) {
    return i18n.t("time.noTimeLeft");
  }

  if (lessThanAMinuteRemaining(millisecondsRemaining)) {
    return getSecondsRemaining(millisecondsRemaining);
  }

  if (lessThanAnHourRemanining(millisecondsRemaining)) {
    return getMinutesRemaining(millisecondsRemaining);
  }

  return getDaysAndHoursRemaining(millisecondsRemaining);
}

function lessThanASecondRemaining(millisecondsRemaining: number) {
  return millisecondsRemaining < millisecondsInASecond;
}

function lessThanAMinuteRemaining(millisecondsRemaining: number) {
  return millisecondsRemaining < millisecondsInAMinute;
}

function getSecondsRemaining(millisecondsRemaining: number) {
  const seconds = Math.floor(millisecondsRemaining / millisecondsInASecond);
  return i18n.t("time.timeLeft", { timeLeft: seconds + "s" });
}

function lessThanAnHourRemanining(millisecondsRemaining: number) {
  return millisecondsRemaining < millisecondsInAnHour;
}

function getMinutesRemaining(millisecondsRemaining: number) {
  const minutes = Math.floor(millisecondsRemaining / millisecondsInAMinute);
  return i18n.t("time.timeLeft", { timeLeft: minutes + "m" });
}

function getDaysAndHoursRemaining(millisecondsRemaining: number) {
  const days = getDaysRemaining(millisecondsRemaining);
  const hours = getHoursRemaning(millisecondsRemaining, days);

  const daysDisplay = days > 0 ? days + "d" : "";
  const hoursDisplay = hours > 0 ? hours + "h" : "";

  const timeLeft = [daysDisplay, hoursDisplay].join(" ").trim();
  return i18n.t("time.timeLeft", { timeLeft });
}

function getDaysRemaining(millisecondsRemaining: number) {
  return Math.floor(millisecondsRemaining / millisecondsInADay);
}

function getHoursRemaning(millisecondsRemaining: number, days: number) {
  const millisecondsRemainingInTheDay = millisecondsRemaining - Math.max(0, days * millisecondsInADay);
  return Math.floor(millisecondsRemainingInTheDay / millisecondsInAnHour);
}

export function getNow(): Date {
  return new Date();
}
