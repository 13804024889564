import { useEffect, useRef, useState } from 'react';

export default function useComponentVisible(initialIsVisible: boolean) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const targetAreaRef = useRef<any>(null);

  const handleClick = (event: any) => {
    if (targetAreaRef.current && !targetAreaRef.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  const handleKeydown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown, true);
    document.addEventListener('click', handleClick, true);
    return () => {
      document.addEventListener("keydown", handleKeydown, true);
      document.removeEventListener('click', handleClick, true);
    };
  }, []);

  return { targetAreaRef, isComponentVisible, setIsComponentVisible };
}