import { NavLink } from "react-router-dom";
import React, { ComponentType } from "react";
import { ReactComponent as RightIcon } from "shared/assets/Icons/right.svg";

type AccountNavLinkProps = {
  to: string;
  text: string;
  Icon: ComponentType<React.SVGProps<SVGSVGElement>>;
  bottomLine?: boolean;
};

type AccountNavItemProps = Omit<AccountNavLinkProps, "to">;

type AccountNavButtonProps = {
  onClick: () => void;
} & Omit<AccountNavLinkProps, "to">;

function AccountNavItem({ text, bottomLine, Icon }: AccountNavItemProps) {
  const bottomLineClasses = bottomLine ? "border-b border-b-sky-grey" : "";
  return (
    <div className={`flex flex-row p-2 ${bottomLineClasses} border-t border-t-sky-grey align-middle justify-between`}>
      <div className={"flex flex-row"}>
        <div className={"flex justify-center items-center [&>*]:h-auto"}>
          <Icon className="text-grey h-full" />
        </div>
        <h3 className="p-2">{text}</h3>
      </div>
      <div className={"flex justify-center items-center"}>
        <RightIcon height={25} width={25} className="ml-auto text-grey" />
      </div>
    </div>
  );
}

export const AccountNavLink = ({ to, text, Icon, bottomLine }: AccountNavLinkProps) => {
  return (
    <NavLink to={to} className="no-underline">
      <AccountNavItem text={text} bottomLine={bottomLine} Icon={Icon} />
    </NavLink>
  );
};

export const AccountNavButton = ({ text, Icon, bottomLine, onClick }: AccountNavButtonProps) => {
  return (
    <button className="w-full" onClick={onClick}>
      <AccountNavItem text={text} bottomLine={bottomLine} Icon={Icon} />
    </button>
  );
};
