import classNames from "classnames";
import { PropsWithChildren } from "react";

export type RoundedCardProps = PropsWithChildren & {
  radiusStyle: string;
  className?: string;
  id?: string;
};

export const RoundedCard = ({ radiusStyle, children, className, id }: RoundedCardProps) => {
  return (
    <>
      <div className={classNames("bg-white", radiusStyle, "shadow-black", "drop-shadow-md", "p-5", className)} data-testid={"roundedCard"} id={id}>
        {children}
      </div>
    </>
  );
};

RoundedCard.defaultProps = { radiusStyle: "rounded-card" };
