import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from "react";
import { TrackerType, toTrackerType } from "./TrackerType";
import { TrackerWithRelatedContent, useGetTrackersWithRelatedContentFunctionRequest } from "shared/request/myHealthyAdvantageApi";

import { ClientCodeState } from "shared/core/state/clientCodeState";
import { useTrackersState } from "./useTrackersState";

export type TrackerContextType = {
  refreshTrackerValues: VoidFunction;
  getCurrentValue: (trackerType: TrackerType) => number;
  setCurrentValue: (trackerType: TrackerType, value: number) => void;
  clearCurrentValue: (trackerType: TrackerType) => void;
  getGoal: (trackerType: TrackerType) => number;
  setGoal: (trackerType: TrackerType, goal: number) => void;
  getFourWeekPlanIds: (trackerType: TrackerType) => string[];
  getLearningMaterialCategoryIds: (trackerType: TrackerType) => string[];
  trackerValuesLoading: boolean;
  trackerValuesError: boolean;
  relatedContentLoading: boolean;
  relatedContentError: boolean;
};

export const TrackerContext = createContext<TrackerContextType>({} as TrackerContextType);

export const useTrackerContext = () => useContext(TrackerContext);

type TrackerWithRelatedContentMap = {
  [Key in TrackerType]: TrackerWithRelatedContent | null;
};

function getDefaultTrackersWithRelatedContent() {
  return {
    [TrackerType.Mood]: null,
    [TrackerType.Sleep]: null,
    [TrackerType.Steps]: null,
    [TrackerType.Activity]: null,
    [TrackerType.Water]: null,
    [TrackerType.BreathingExercises]: null,
  };
}

const mapResponseDataToTrackerWithRelatedContent = (data: TrackerWithRelatedContent[], currentState: TrackerWithRelatedContentMap) =>
  data.reduce((acc, val) => {
    const trackerType = toTrackerType(val.identifier);
    acc[trackerType] = val;
    return acc;
  }, currentState);

export const TrackerContextProvider = ({ children }: PropsWithChildren) => {
  const {
    trackerValues,
    setTrackerCurrentValue,
    clearTrackerCurrentValue,
    setTrackerGoal,
    hasError: trackerValuesError,
    isLoading: trackerValuesLoading,
    refreshTrackerValues,
  } = useTrackersState();
  const [trackersWithRelatedContent, setTrackersWithRelatedContent] = useState<TrackerWithRelatedContentMap>(getDefaultTrackersWithRelatedContent());
  const {
    data: trackersWithRelatedContentData,
    isLoading: trackersWithRelatedContentLoading,
    error: trackersWithRelatedContentError,
  } = useGetTrackersWithRelatedContentFunctionRequest({ xClientCode: ClientCodeState.get() });

  useEffect(() => {
    if (trackersWithRelatedContentData) {
      const newTrackersWithRelatedContent = mapResponseDataToTrackerWithRelatedContent(trackersWithRelatedContentData, trackersWithRelatedContent);
      setTrackersWithRelatedContent(newTrackersWithRelatedContent);
    }
  }, [trackersWithRelatedContent, trackersWithRelatedContentData]);

  const contextValue = useMemo<TrackerContextType>(() => {
    function getFourWeekPlanIds(trackerType: TrackerType) {
      const tracker = trackersWithRelatedContent[trackerType];
      return tracker?.fourWeekPlans?.map((plan) => plan.id) ?? [];
    }

    function getLearningMaterialCategoryIds(trackerType: TrackerType) {
      const tracker = trackersWithRelatedContent[trackerType];
      return tracker?.learningMaterialCategories.map((category) => category.id) ?? [];
    }

    return {
      refreshTrackerValues: refreshTrackerValues,
      getCurrentValue: (trackerType) => trackerValues[trackerType].current,
      setCurrentValue: async (trackerType, value) => await setTrackerCurrentValue(trackerType, value),
      clearCurrentValue: (trackerType) => clearTrackerCurrentValue(trackerType),
      getGoal: (trackerType) => trackerValues[trackerType].goal,
      setGoal: async (trackerType, goal: number) => await setTrackerGoal(trackerType, goal),
      getFourWeekPlanIds: (trackerType) => getFourWeekPlanIds(trackerType),
      getLearningMaterialCategoryIds: (trackerType) => getLearningMaterialCategoryIds(trackerType),
      trackerValuesLoading: trackerValuesLoading,
      trackerValuesError: trackerValuesError,
      relatedContentLoading: trackersWithRelatedContentLoading,
      relatedContentError: !!trackersWithRelatedContentError,
    };
  }, [
    refreshTrackerValues,
    clearTrackerCurrentValue,
    setTrackerCurrentValue,
    setTrackerGoal,
    trackerValues,
    trackerValuesError,
    trackerValuesLoading,
    trackersWithRelatedContent,
    trackersWithRelatedContentError,
    trackersWithRelatedContentLoading,
  ]);

  return <TrackerContext.Provider value={contextValue}>{children}</TrackerContext.Provider>;
};
