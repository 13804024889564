import { t } from "i18next";
import { DateOnly, FourWeekPlanActivity, useGetFourWeekPlanActivitiesFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { ConfirmationModal } from "shared/UI/Modal/ConfirmationModal/ConfirmationModal";
import Checkbox from "@brighthr/component-checkbox";
import * as React from "react";
import { useEffect, useState } from "react";
import { daysSince } from "core/helpers/dates";
import "shared/extensions/date";
import { ViewStateWrapper } from "UIPalette/ViewStateWrapper/ViewStateWrapper";

interface SubmitActivitiesModalProps {
  datoFourWeekPlanId: string;
  onCancel: () => void;
  onConfirm: (activities: FourWeekPlanActivity[]) => void;
  startDate?: DateOnly;
}

export const SubmitActivitiesModal = ({ datoFourWeekPlanId, onCancel, onConfirm, startDate }: SubmitActivitiesModalProps) => {
  const { data, isLoading, error, mutate } = useGetFourWeekPlanActivitiesFunctionRequest({ datoFourWeekPlanId });

  const [activities, setActivities] = useState(data?.activities ?? []);
  const [activitiesInitialised, setActivitiesInitialised] = useState(false);

  useEffect(() => {
    async function updateActivites() {
      try {
        const response = await mutate();
        setActivities(response?.data.activities ?? []);
        setActivitiesInitialised(true);
      } catch {}
    }
    if (!activitiesInitialised && !!data?.activities) {
      updateActivites();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.activities]);

  function activitiesText() {
    if (startDate === undefined) {
      return "";
    }
    const day = daysSince(Date.fromDateOnly(startDate)!) + 1;
    const remainingDays = 28 - day;

    if (remainingDays <= 0) {
      return t("fourWeekPlans.submitActivitiesModal.zeroDaysRemainingText");
    }

    return t("fourWeekPlans.submitActivitiesModal.daysRemainingText", { day, remainingDays });
  }

  return (
    <ConfirmationModal
      title={t("fourWeekPlans.submitActivitiesModal.title")}
      cancelButtonText={t("fourWeekPlans.submitActivitiesModal.cancelButton")}
      confirmButtonText={t("fourWeekPlans.submitActivitiesModal.confirmButton")}
      confirmButtonDisabled={isLoading || !!error}
      width={"base"}
      onCancel={onCancel}
      onConfirm={() => onConfirm(activities)}
      headerSize={"xl"}
    >
      <ViewStateWrapper
        loading={isLoading}
        error={!!error}
        errorMessage={t("fourWeekPlans.resourceError")}
        onRetry={async () => {
          const response = await mutate();
          setActivities(response?.data.activities ?? []);
          setActivitiesInitialised(true);
        }}
      >
        <div>
          <p className={"pb-2"}>{activitiesText()}</p>
          {activities.map((activity, index) => {
            return (
              <div className={"hover:bg-l-blue hover:rounded-lg md:[&>*]:h-[3rem] transition-all duration-75 p-3 md:p-0"} key={activity.text}>
                <Checkbox
                  checked={activity.isCompleted}
                  onChange={(e) => {
                    const newActivities = [...activities];
                    newActivities[index].isCompleted = e.target.checked;
                    setActivities(newActivities);
                  }}
                  label={activity.text}
                />
              </div>
            );
          })}
        </div>
      </ViewStateWrapper>
    </ConfirmationModal>
  );
};
