import ReactPlayer from "react-player";
import { useRef, useState } from "react";
import { MediaPlayerState } from "./MediaPlayerState";
import { AudioPlayerControlBar } from "./AudioPlayerControlBar";

export type AudioPlayerProps = {
  link: string;
};

export const WebAudioPlayer = ({ link }: AudioPlayerProps) => {
  const playerRef = useRef<ReactPlayer>(null);
  const [playerState, setPlayerState] = useState<MediaPlayerState>({ playing: false, seeking: false, duration: 0, played: 0 });

  return (
    <>
      <div className="hidden">
        <ReactPlayer
          ref={playerRef}
          width="100%"
          height="100%"
          url={link}
          playing={playerState.playing}
          controls={true}
          onPlay={() => setPlayerState({ ...playerState, playing: true })}
          onPause={() => setPlayerState({ ...playerState, playing: false })}
          onEnded={() => setPlayerState({ ...playerState, playing: false })}
          onDuration={(duration) => setPlayerState({ ...playerState, duration })}
          onProgress={(progress) => {
            if (!playerState.seeking) {
              setPlayerState({ ...playerState, played: progress.played });
            }
          }}
          onError={(e) => console.log("onError", e)}
        />
      </div>

      <div className="pt-5">
        <AudioPlayerControlBar
          position={playerState.played}
          duration={playerState.duration}
          isPlaying={playerState.playing}
          play={() => setPlayerState({ ...playerState, playing: true })}
          pause={() => setPlayerState({ ...playerState, playing: false })}
          onSeekMouseDown={() => setPlayerState({ ...playerState, seeking: true })}
          onSeekMouseUp={() => setPlayerState({ ...playerState, seeking: false })}
          onSeekChange={(position) => {
            playerRef.current?.seekTo(position);
            setPlayerState({ ...playerState, played: position });
          }}
        />
      </div>
    </>
  );
};
