import { useTrackerContext } from "core/state/Trackers/TrackerContext";
import { ContextMenuListItem } from "@brighthr/component-contextmenu";
import { TrackerChart } from "../../../Trackers/components/Charts/TrackerChart";
import { ChartDayOffsets } from "../../../Trackers/components/Charts/helpers";
import { useEffect, useState } from "react";
import { useGetUserTrackerValuesFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { TrackerType } from "core/state/Trackers/TrackerType";
import { useTranslation } from "react-i18next";
import { MoodTrackerHealthHubId } from "UIPalette/MoodTrackerCard/MoodTrackerCard";
import { yAxisMoodImagePlugin } from "./yAxisMoodImagePlugin";

export const MoodChart = () => {
  const today = new Date();
  const { t } = useTranslation();
  const [dateFrom, setDateFrom] = useState<Date>(today.addDays(ChartDayOffsets.week));
  const { getCurrentValue, clearCurrentValue } = useTrackerContext();
  const {
    data: sleepTrackerData,
    mutate,
    isLoading,
    error,
  } = useGetUserTrackerValuesFunctionRequest({
    from: dateFrom.toDateOnlyString(),
    to: today.toDateOnlyString(),
    trackerType: TrackerType[TrackerType.Mood],
  });

  useEffect(() => {
    mutate();
  }, [getCurrentValue, mutate]);

  return (
    <div id={MoodTrackerHealthHubId}>
      <TrackerChart
        trackerType={TrackerType.Mood}
        heading={t("healthHub.moodChart.heading")}
        yAxisStepSize={1}
        yAxisMax={5.5}
        loading={isLoading}
        showError={error !== undefined}
        showYAxisTick={false}
        data={sleepTrackerData?.values ?? []}
        dateRange={{ startDate: dateFrom, endDate: today }}
        reload={() => mutate()}
        onChartViewOffsetChange={(offset) => setDateFrom(today.addDays(offset))}
        initialChartViewOffset={ChartDayOffsets.week}
        plugins={[yAxisMoodImagePlugin]}
        menuItems={[
          <ContextMenuListItem
            className="[&_span]:text-[14pt] [&_span]:font-bold py-2 pl-2"
            text={t("trackers.deleteLastRecord")}
            key={"delete-mood-button-1"}
            onClick={() => clearCurrentValue(TrackerType.Mood)}
          />,
        ]}
      />
    </div>
  );
};
