import { useListLearningMaterialsFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { Carousel } from "UIPalette/Carousel/Carousel";
import { LearningMaterialCard } from "UIPalette/LearningMaterials/components/LearningMaterialCard";
import { ViewStateWrapper } from "UIPalette/ViewStateWrapper/ViewStateWrapper";

export type LearningMaterialsProps = {
  categoryIds: string[];
  subCategoryIds?: string[];
  maxResults: number;
  resultsPerPage: number;
  navigateBasePath: string;
  errorMessage: string;
  ariaLabel: string;
};

export const LearningMaterials = ({
  categoryIds,
  subCategoryIds,
  maxResults,
  resultsPerPage,
  navigateBasePath,
  errorMessage,
  ariaLabel,
}: LearningMaterialsProps) => {
  const {
    data: learningMaterials = [],
    isLoading,
    error,
    mutate,
  } = useListLearningMaterialsFunctionRequest(
    {
      categories: categoryIds,
      subCategories: subCategoryIds,
      maxResults: maxResults,
    },
    { revalidateOnMountIfStale: false, showErrorIfStale: false }
  );

  return (
    <ViewStateWrapper loading={isLoading} error={!!error} errorMessage={errorMessage} onRetry={mutate}>
      <Carousel itemsPerSlide={resultsPerPage} ariaLabel={ariaLabel}>
        {learningMaterials.map((lm) => (
          <LearningMaterialCard key={lm.id} item={lm} navigateBasePath={navigateBasePath} />
        ))}
      </Carousel>
    </ViewStateWrapper>
  );
};

LearningMaterials.defaultProps = {
  maxResults: 15,
  resultsPerPage: 3,
  navigateBasePath: "/resources/",
};
