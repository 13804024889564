import { ReactElement } from "react";
import { Icon } from "../Icon/Icon";

export type IconButtonProps = {
  active: boolean;
  handleClick: Function;
  ariaLabel?: string;
};

type IconButtonPropsForIcon = {
  caption: string;
  ariaLabel?: string;
  active: boolean;
  icon: ReactElement;
  fgColour: string;
  bgColour: string;
  hasDropShadow?: boolean;
  handleClick: Function;
};

export const IconButton = ({ caption, ariaLabel, active, icon, fgColour, bgColour, handleClick, hasDropShadow }: IconButtonPropsForIcon) => {
  return (
    <button className="h-full" disabled={!active} aria-label={ariaLabel} onClick={() => (active ? handleClick() : {})}>
      <Icon
        caption={caption}
        icon={icon}
        fgColour={active ? fgColour : "text-d-grey"}
        bgColour={active ? bgColour : "bg-silver"}
        hasDropShadow={hasDropShadow ?? true}
      />
    </button>
  );
};

IconButton.defaultProps = { caption: "", fgColour: "text-primary-700", bgColour: "bg-white", handleClick: () => {} };
