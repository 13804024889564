import classNames from "classnames";
import { ValueCallback } from "shared/core/types/callbacks";
import TimeFormat from "./TimeFormat";
import { useTranslation } from "react-i18next";

type TimeFormatSelectorProps = {
  value: TimeFormat;
  onChange: ValueCallback<TimeFormat>;
};

export const TimeFormatSelector = ({ value, onChange }: TimeFormatSelectorProps) => {
  const { t } = useTranslation();
  return (
    <div className="border-2 border-brand rounded w-32 mb-4 flex flex-row text-[14pt]">
      <button
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={true}
        aria-label={t("timePicker.24HourFormatLabel")}
        className={classNames("basis-1/2 border-r-2 border-brand text-brand font-bold", {
          "bg-brand": value === TimeFormat["24H"],
          "text-white": value === TimeFormat["24H"],
        })}
        onClick={() => value !== TimeFormat["24H"] && onChange(TimeFormat["24H"])}
      >
        24H
      </button>
      <button
        aria-label={t("timePicker.12HourFormatLabel")}
        className={classNames(" basis-1/2 text-brand font-bold", {
          "bg-brand": value === TimeFormat["12H"],
          "text-white": value === TimeFormat["12H"],
        })}
        onClick={() => value !== TimeFormat["12H"] && onChange(TimeFormat["12H"])}
      >
        12H
      </button>
    </div>
  );
};
