import { UserInterestPreference } from "shared/request/myHealthyAdvantageApi";
import { trackAppEvent } from "./AppInsights";
import { trackGAEvent } from "./GoogleAnalytics";
import { EventNames } from "./types/enums";
import { appInsights } from "./AppInsights";
import { TrackerType } from "core/state/Trackers/TrackerType";

export const trackCardClicks = (value: string) => trackEvents(EventNames.CARD_ONCLICK, { value });

export const trackBtnClicks = (value: string) => trackEvents(EventNames.BTN_ONCLICK, { value });

export const trackChartFilter = (value: string) => trackEvents(EventNames.CHART_FILTER, { value });

export const trackEvents = (name: string, properties?: Record<string, string>) => {
  const appEvent = properties
    ? Object.entries(properties)
        .map(([key, value]) => `${key}=${value}`)
        .join("?")
    : "";
  trackGAEvent(name, properties);
  trackAppEvent(`${name}${appEvent && `?${appEvent}`}`);
};

export const trackFilteredInterests = (interestPreferences?: UserInterestPreference[], selectedInterests?: string[]) => {
  const categories = selectedInterests?.map((_category) => interestPreferences?.find((preference) => preference.categoryId === _category)?.name);
  categories?.forEach((category) => trackEvents(EventNames.RESOURCE_FILTER, { value: category ?? "" }));
};

export const trackFormSubmission = (name: string, values: Record<string, any>, fromForm?: boolean) => {
  appInsights.trackEvent({ name: `${EventNames.FORM_SUBMIT}?id=${name}` }, { formValues: values });
  !fromForm && trackGAEvent(EventNames.FORM_SUBMIT, { id: name, formValues: JSON.stringify(values) });
};

export const trackAddHealthData = (type: number) => trackBtnClicks(`click_add_${TrackerType[type]}`);

export const trackSaveHealthData = (type: number) => trackEvents(EventNames.HEALTH_DATA_SAVE, { value: TrackerType[type] });

export const trackCancelHealthData = (type: number) => trackBtnClicks(`cancel_add_${TrackerType[type]}`);
