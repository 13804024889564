import { useTranslation } from "react-i18next";
import { ChangeEventHandler } from "react";
import { Select } from "shared/UI/Inputs/Select/Select";

type GenderSelectProps = {
  onChange: ChangeEventHandler;
  label: string;
  value: string;
  ["aria-describedby"]?: string;
};

export const GenderSelect = (props: GenderSelectProps) => {
  const { t } = useTranslation();

  return (
    <Select {...props}>
      <option disabled={true}></option>
      <option value="prefer-not-to-say">{t("genderSelect.preferNotToSay")}</option>
      <option value="male">{t("genderSelect.male")}</option>
      <option value="female">{t("genderSelect.female")}</option>
      <option value="non-binary">{t("genderSelect.nonBinary")}</option>
    </Select>
  );
};
