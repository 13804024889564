import "shared/extensions/date";

import StylableButton from "shared/UI/Buttons/StylableButton";
import { t } from "i18next";
import { useState } from "react";

export type Duration = {
  value: number;
  unit: "week" | "month";
};

export type DurationPickerProps = {
  className: string;
  heading: string;
  durations: Duration[];
  initialSelection: number | undefined;
  onSelectionChanged: (value: number) => void;
};

export function calculateEndDate(startDate: Date, duration: Duration): Date {
  switch (duration.unit) {
    case "week":
      return startDate.addWeeks(duration.value);
    case "month":
      return startDate.addMonths(duration.value);
    default:
      throw new Error(`duration unit (${duration}) not supported`);
  }
}

export function getDurationText(duration: Duration): string {
  switch (duration.unit) {
    case "week":
      return t("leaderboards.create.weeks", { count: duration.value });
    case "month":
      return t("leaderboards.create.months", { count: duration.value });
    default:
      throw new Error(`Unsupported unit: ${duration.unit}`);
  }
}

export const DurationPicker = ({ className, heading, durations, initialSelection, onSelectionChanged }: DurationPickerProps) => {
  const [selected, setSelected] = useState<number | undefined>();

  if (selected === undefined) {
    setSelected(initialSelection ?? 0);
  }

  return (
    <div className={className}>
      <h4 className="my-0 p-0">{heading}</h4>
      <div className="grid grid-cols-2 mt-4  gap-4">
        {durations.map((duration, index) => {
          return (
            <StylableButton
              key={index}
              text={getDurationText(duration)}
              color="primary"
              outlineButton={selected !== index}
              fullWidth
              className="w-full"
              onClick={() => {
                setSelected(index);
                onSelectionChanged?.(index);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

DurationPicker.defaultProps = { initialSelection: undefined };
