import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState
} from "react";

export type BreadcrumbContextType = {
  breadcrumbTitle: string;
  setBreadcrumbTitle: Dispatch<SetStateAction<string>>;
}

export const BreadcrumbContext = createContext({} as BreadcrumbContextType);

export const useBreadcrumbContext = () => useContext(BreadcrumbContext);

export const BreadcrumbContextProvider = ({children}: PropsWithChildren) => {
  const [breadcrumbTitle, setBreadcrumbTitle] = useState("")
  const breadcrumbContext: BreadcrumbContextType = useMemo(() => (
          {
            breadcrumbTitle,
            setBreadcrumbTitle
          } as BreadcrumbContextType
      ),
      [breadcrumbTitle]
  )

  return (
      <BreadcrumbContext.Provider value={breadcrumbContext}>
        {children}
      </BreadcrumbContext.Provider>
  )
}