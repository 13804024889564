import { Modal, ModalBody } from "shared/UI/Modal/Modal";
import useLocale, { ManualLocaleState, ManualLocales } from "core/hooks/useLocale/useLocale";

import StylableButton from "shared/UI/Buttons/StylableButton";
import { t } from "i18next";
import { useEffect } from "react";
import { useModalContext } from "core/state/ModalContext/ModalContext";

export const LocaleOption = (props: { handleLocaleClick: (locale: ManualLocales) => void }) => {
  const { handleLocaleClick } = props;
  return (
    <div className="flex justify-center gap-4">
      <StylableButton color="primary" text={t("localeModel.buttons.first")} onClick={() => handleLocaleClick("en")} />
      <StylableButton color="primary" text={t("localeModel.buttons.second")} onClick={() => handleLocaleClick("en_AU")} />
    </div>
  );
};

export const LocaleModal = () => {
  const { registerModal, getModal, triggerModal } = useModalContext();
  const { updateLocale } = useLocale();

  useEffect(() => {
    registerModal({
      id: "localeModal",
    });
  }, [registerModal]);

  const dismissAction = (locale: ManualLocales = "en") => {
    updateLocale(locale);
    triggerModal("localeModal", false);
  };

  return (
    <>
      {getModal("localeModal")?.visible && !ManualLocaleState.get() && (
        <Modal title={t("localeModel.title")} width="xs" close={() => dismissAction("en")} hideDismiss={true} bgDismiss={false}>
          <ModalBody>
            <LocaleOption handleLocaleClick={dismissAction} />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
