import { DateOnly } from "../request/myHealthyAdvantageApi";

export {};

declare global {
  interface DateConstructor {
    fromDateOnly(value: DateOnly | undefined): Date | undefined;
    fromOffset(date: Date, offset: number): Date;
  }

  interface Date {
    toDateOnly(): DateOnly;
    toDateOnlyString(): string;
    isSameDateAs(dateOnly: DateOnly): boolean;
    addDays(days: number): Date;
    addWeeks(weeks: number): Date;
    addMonths(months: number): Date;
    isLeapYear(): boolean;
    getDaysInMonth(): number;
  }
}

Date.fromDateOnly = function (value: DateOnly | undefined): Date | undefined {
  if (value === undefined) {
    return undefined;
  }
  return new Date(value.year!, value.month! - 1, value.day);
};

Date.fromOffset = function (date: Date, offset: number): Date {
  const newDate = new Date();
  newDate.setDate(date.getDate() + offset);
  return newDate;
};

// eslint-disable-next-line no-extend-native
Date.prototype.toDateOnly = function (): DateOnly {
  return {
    year: this.getFullYear(),
    month: this.getMonth() + 1,
    day: this.getDate(),
  };
};

// eslint-disable-next-line no-extend-native
Date.prototype.toDateOnlyString = function (): string {
  const year = this.getFullYear();
  const month = (this.getMonth() + 1).toString().padStart(2, "0");
  const day = this.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

// eslint-disable-next-line no-extend-native
Date.prototype.isSameDateAs = function (dateOnly: DateOnly): boolean {
  return this.getFullYear() === dateOnly.year && this.getMonth() + 1 === dateOnly.month && this.getDate() === dateOnly.day;
};

// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function (days: number): Date {
  const date = new Date(this);
  date.setDate(date.getDate() + days);
  return date;
};

// eslint-disable-next-line no-extend-native
Date.prototype.addWeeks = function (weeks: number): Date {
  const date = new Date(this);
  date.setDate(date.getDate() + weeks * 7 - 1);
  return date;
};

// eslint-disable-next-line no-extend-native
Date.prototype.addMonths = function (months: number): Date {
  const date = new Date(this);
  const day = date.getDate();
  const daysInStartingMonth = date.getDaysInMonth();

  date.setDate(1);
  date.setMonth(date.getMonth() + months);

  switch (day) {
    case 1:
      date.setDate(date.getDate() - 1);
      break;

    case daysInStartingMonth:
      date.setDate(date.getDaysInMonth());
      break;

    default:
      date.setDate(Math.min(day - 1, date.getDaysInMonth()));
      break;
  }
  return date;
};

// eslint-disable-next-line no-extend-native
Date.prototype.isLeapYear = function (): boolean {
  const date = this;
  const year = date.getFullYear();
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

// eslint-disable-next-line no-extend-native
Date.prototype.getDaysInMonth = function (): number {
  const date = this;
  return [31, date.isLeapYear() ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][date.getMonth()];
};

export const isToday = (date: DateOnly | undefined) => date !== undefined && new Date().isSameDateAs(date);
