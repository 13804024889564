import { logoutMobileUser } from "core/auth/logoutMobileUser";
import { appInsightsLogger } from "core/monitoring/AppInsights";
import { useAuth } from "core/auth/useAuth";

export const useLogout = () => {
  appInsightsLogger.info("useAuth - useLogout.ts");
  const auth = useAuth();
  return () => {
    if (auth.isAuthenticated) {
      auth.signoutRedirect();
      logoutMobileUser();
    }
  };
};
