import { Modal, ModalBody } from "shared/UI/Modal/Modal";

import { Content } from "shared/request/myHealthyAdvantageApi";
import { DatoContent } from "UIPalette/Components/DatoContent/DatoContent";
import ViewLoading from "shared/UI/Spinners/ViewLoading";

export type InformationModalProps = {
  title: string;
  description: Content | undefined;
  isTemplateLoading: boolean;
  onClose: Function;
};

export const InformationModal = ({ title, isTemplateLoading, description, onClose }: InformationModalProps) => {
  return (
    <Modal title={title} headerSize="base" close={() => onClose()}>
      <ModalBody>{isTemplateLoading ? <ViewLoading heightClass="h-20" /> : <DatoContent content={description} />}</ModalBody>
    </Modal>
  );
};
