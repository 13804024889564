import { Route, Routes } from "react-router-dom";
import { HealthHubHome } from "./views/HealthHubHome/HealthHubHome";
import { WaterTracker } from "./views/Trackers/WaterTracker/WaterTracker";
import { StepsTracker } from "./views/Trackers/StepsTracker/StepsTracker";
import { SleepTracker } from "./views/Trackers/SleepTracker/SleepTracker";
import { ProtectedModuleRoute } from "core/auth/ProtectedModuleRoute";
import WisdomModules from "core/Modules";
import { GeneralActivityTracker } from "./views/Trackers/GeneralActivityTracker/GeneralActivityTracker";

export const HealthHub = () => (
  <Routes>
    <Route index element={<HealthHubHome />} />
    <Route element={<ProtectedModuleRoute wisdomModule={WisdomModules.trackers} />}>
      <Route path="water-tracker" element={<WaterTracker />} />
      <Route path="steps-tracker" element={<StepsTracker />} />
      <Route path="sleep-tracker" element={<SleepTracker />} />
      <Route path="general-activity-tracker" element={<GeneralActivityTracker />} />
    </Route>
  </Routes>
);
