import PageTitle from "UIPalette/PageTitle/PageTitle";
import { ErrorFallback } from "../ErrorFallback";
import { useTranslation } from "react-i18next";

export const NotFound = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle title={t("pageTitle.notFound")} />
      <ErrorFallback error={{ name: "404", message: t("generalErrors.notFound") }} resetErrorBoundary={() => {}} />
    </>
  );
};
