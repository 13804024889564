import { UserLeaderboardSummary } from "shared/request/myHealthyAdvantageApi";
import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";
import { ReactComponent as RightChevron } from "shared/assets/Icons/right.svg";
import { ReactComponent as Rosette } from "shared/assets/Icons/rosette.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "shared/extensions/string";
import { TitleCard } from "UIPalette/TitleCard/TitleCard";
import { ProgressBar } from "UIPalette/ProgressBar/ProgressBar";

export type LeaderboardsListProps = {
  title: string;
  leaderboards: UserLeaderboardSummary[];
  titleNavigatesToAllLeaderboards?: boolean;
};

export const LeaderboardsList = ({ title, leaderboards, titleNavigatesToAllLeaderboards }: LeaderboardsListProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const hasStarted = (leaderboard: UserLeaderboardSummary): boolean => {
    return Number.isInteger(leaderboard.daysRemaining);
  };

  const hasCompleted = (leaderboard: UserLeaderboardSummary): boolean => {
    return !Number.isInteger(leaderboard.daysBeforeStart) && !Number.isInteger(leaderboard.daysRemaining);
  };

  const getProgressLabel = (participantCount: number, daysBeforeStart?: number | null, daysRemaining?: number | null) => {
    if (daysBeforeStart) {
      if (daysBeforeStart === 1) {
        return t("leaderboards.list.progressOneDayToStart", { participants: participantCount });
      } else {
        return t("leaderboards.list.progressBeforeStart", { days: daysBeforeStart, participants: participantCount });
      }
    }
    if (daysRemaining) {
      if (daysRemaining === 1) {
        return t("leaderboards.list.progressLastDay", { participants: participantCount });
      } else {
        return t("leaderboards.list.progress", { days: daysRemaining, participants: participantCount });
      }
    }
    return "";
  };

  const onTitleClick: Function | undefined = titleNavigatesToAllLeaderboards === true ? () => navigate("/leaderboards") : undefined;

  return (
    <TitleCard title={title} onTitleClick={onTitleClick}>
      {leaderboards.map((leaderboard) => {
        return (
          <div key={`lb-${leaderboard.id}`} className="p-1">
            <button className="text-left w-full" onClick={() => navigate(`/leaderboards/${leaderboard.code}`)}>
              <RoundedCard>
                <h4 className="cardTitle flex flex-row">
                  <span className="flex-grow">{leaderboard.name}</span>
                  {leaderboard.userPosition && (
                    <>
                      <span className="flex items-center mr-1">
                        <Rosette className="h-[1em] m-0" />
                      </span>
                      <span aria-hidden={true} className="text-primary-700 font-bold text-[14pt]">
                        {leaderboard.userPosition.asOrdinal()}
                      </span>
                    </>
                  )}
                </h4>
                {!hasCompleted(leaderboard) && (
                  <div className="mb-3">
                    <div className="mb-2">
                      {getProgressLabel(leaderboard.participantCount, leaderboard.daysBeforeStart, leaderboard.daysRemaining)}
                    </div>
                    {hasStarted(leaderboard) && (
                      <ProgressBar percentageComplete={getPercentageComplete(leaderboard.daysRemaining!, leaderboard.durationInDays)} />
                    )}
                  </div>
                )}
                <div className="flex items-center mb-4">
                  <p className="mr-4 grow">
                    {leaderboard.daysBeforeStart && t("leaderboards.list.beforeStartDescription")}
                    {!leaderboard.daysBeforeStart && t("leaderboards.list.description")}
                  </p>
                  <div>
                    <RightChevron className="text-grey" height="24" width="12" />
                  </div>
                </div>
              </RoundedCard>
            </button>
          </div>
        );
      })}
    </TitleCard>
  );
};

export const getPercentageComplete = (daysRemaining: number, durationInDays: number) => {
  return 100 - daysRemaining.percentageOf(durationInDays);
};

LeaderboardsList.defaultProps = {
  showMenu: false,
};
