import { ReactComponent as BadgeBackgroundPositive } from "shared/assets/Icons/achieved-positive.svg";
import { ReactComponent as BadgeBackgroundNegative } from "shared/assets/Icons/achieved-negative.svg";
import { ReactComponent as WaterPositive } from "shared/assets/Icons/achievement-water-positive.svg";
import { ReactComponent as WaterNegative } from "shared/assets/Icons/achievement-water-negative.svg";
import { ReactComponent as Sleep } from "shared/assets/Icons/achievement-sleep.svg";
import { ReactComponent as StepsPositive } from "shared/assets/Icons/achievement-steps-positive.svg";
import { ReactComponent as StepsNegative } from "shared/assets/Icons/achievement-steps-negative.svg";
import { ReactComponent as ActivityPositive } from "shared/assets/Icons/achievement-activity-positive.svg";
import { ReactComponent as ActivityNegative } from "shared/assets/Icons/achievement-activity-negative.svg";
import { TrackerType } from "core/state/Trackers/TrackerType";

export type AchievementBadgeProps = {
  name: string;
  caption: string;
  achieved: boolean;
  trackerType: TrackerType;
};

export const AchievementBadge = ({ name, caption, achieved, trackerType }: AchievementBadgeProps) => {
  function getPositiveIcon() {
    switch (trackerType) {
      case TrackerType.Water:
        return <WaterPositive />;
      case TrackerType.Sleep:
        return <Sleep className="text-[#F9EED4] w-6 h-6 ml-[5px] mt-[5px]" />;
      case TrackerType.Steps:
        return <StepsPositive />;
      case TrackerType.Activity:
        return <ActivityPositive />;
    }
  }

  function getNegativeIcon() {
    switch (trackerType) {
      case TrackerType.Water:
        return <WaterNegative />;
      case TrackerType.Sleep:
        return <Sleep className="text-[#BBBBBB] w-6 h-6 ml-[5px] mt-[5px]" />;
      case TrackerType.Steps:
        return <StepsNegative />;
      case TrackerType.Activity:
        return <ActivityNegative className="" />;
    }
  }

  return (
    <div role="img" aria-label={name} className="flex flex-col gap-y-0.5 w-24 items-center">
      <div className="relative w-16">
        {achieved ? <BadgeBackgroundPositive /> : <BadgeBackgroundNegative />}
        <div className="absolute w-1/2 h-1/2 top-1/4 left-1/4">{achieved ? getPositiveIcon() : getNegativeIcon()}</div>
      </div>
      <span className="text-xs text-center text-d-grey">{caption}</span>
    </div>
  );
};
