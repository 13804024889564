import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PageTitle = (props: { title?: string }) => {
  const { title } = props;
  const location = useLocation();

  useEffect(() => {
    document.title = title ? title.replace(/\s+/g, "-") : "Wisdom";
  }, [location, title]);

  return null;
};

export default PageTitle;
