import { useTrackerContext } from "core/state/Trackers/TrackerContext";
import { TrackerType } from "core/state/Trackers/TrackerType";
import { TrackerChart } from "../../components/Charts/TrackerChart";
import { ChartDayOffsets } from "../../components/Charts/helpers";
import { useEffect, useState } from "react";
import { useGetUserTrackerValuesFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { t } from "i18next";

export const StepsTrackerChart = () => {
  const today = new Date();
  const [dateFrom, setDateFrom] = useState<Date>(today.addDays(ChartDayOffsets.week));
  const { getCurrentValue } = useTrackerContext();
  const {
    data: stepsTrackerData,
    mutate,
    isLoading,
    error,
  } = useGetUserTrackerValuesFunctionRequest({
    from: dateFrom.toDateOnlyString(),
    to: today.toDateOnlyString(),
    trackerType: TrackerType[TrackerType.Steps],
  });

  useEffect(() => {
    mutate();
  }, [getCurrentValue, mutate]);

  return (
    <TrackerChart
      trackerType={TrackerType.Steps}
      heading={t("healthHub.trackers.steps.chart.heading")}
      dateRange={{ startDate: dateFrom, endDate: today }}
      yAxisStepSize={100}
      yAxisLabel={t("healthHub.trackers.steps.chart.yLabel")}
      loading={isLoading}
      showError={error !== undefined}
      data={stepsTrackerData?.values ?? []}
      reload={() => mutate()}
      onChartViewOffsetChange={(offset) => setDateFrom(today.addDays(offset))}
      initialChartViewOffset={ChartDayOffsets.week}
    />
  );
};
