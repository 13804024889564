import { LearningMaterialSummary, useListLearningMaterialsFunctionRequest } from "shared/request/myHealthyAdvantageApi";
import { useEffect, useState } from "react";

import { AppRoutes } from "core/AppRoutes";
import { CurvedTopBanner } from "UIPalette/CurvedTopBanner/CurvedTopBanner";
import InfiniteScroll from "react-infinite-scroll-component";
import { LearningMaterialCard } from "UIPalette/LearningMaterials/components/LearningMaterialCard";
import { PageHeader } from "UIPalette/PageHeader/PageHeader";
import ViewLoading from "shared/UI/Spinners/ViewLoading";
import { WellKnownLearningMaterialCategory } from "UIPalette/LearningMaterials/wellKnownLearningMaterials";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ViewStateWrapper } from "UIPalette/ViewStateWrapper/ViewStateWrapper";
import { Hideable } from "shared/UI/Hideable/Hideable";
import { ArticleFilterMenu } from "./ArticleFilterMenu";
import classNames from "classnames";
import PageTitle from "UIPalette/PageTitle/PageTitle";
import { getSelectedFiltersFromUrl, selectedInterestPrefix } from "core/helpers/preferencesFilter";

export type ArticleCategoryLibraryProps = {
  category: WellKnownLearningMaterialCategory;
  hideInterests: boolean;
  pageTitle?: string;
};

type ListLearningMaterialsRequestParameters = Parameters<typeof useListLearningMaterialsFunctionRequest>[0];

const maxResults = 15;

const ArticleCategoryLibrary = ({ category, hideInterests, pageTitle }: ArticleCategoryLibraryProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [selectedInterests, setSelectedInterests] = useState<string[]>(getSelectedFiltersFromUrl(location));

  const [currentPage, setCurrentPage] = useState(1);
  const listLearningMaterialsRequestParams: ListLearningMaterialsRequestParameters = {
    page: currentPage,
    categories: [category.id],
    subCategories: selectedInterests,
    maxResults: maxResults,
  };

  const {
    data: learningMaterials = [],
    error: learningMaterialsError,
    isLoading: learningMaterialsLoading,
    mutate: refreshLearningMaterials,
  } = useListLearningMaterialsFunctionRequest(listLearningMaterialsRequestParams, { revalidateOnMountIfStale: false, showErrorIfStale: false });

  const { data: nextPageLearningMaterials = [] } = useListLearningMaterialsFunctionRequest({
    ...listLearningMaterialsRequestParams,
    page: currentPage + 1,
  });

  const [cachedLearningMaterials, setCachedLearningMaterials] = useState<LearningMaterialSummary[]>([]);
  const [hasInitialLoaded, setInitialLoaded] = useState(false);

  hideInterests = hideInterests || !!location.state?.hideInterests;
  const initialLoadComplete = () => !learningMaterialsLoading && hasInitialLoaded;
  const haveLearningMaterials = () => learningMaterials.length > 0;

  if (!initialLoadComplete() && haveLearningMaterials()) {
    setCachedLearningMaterials(cachedLearningMaterials.concat(learningMaterials));
    setInitialLoaded(true);
  }

  const resetPagination = () => {
    setInitialLoaded(false);
    setCachedLearningMaterials([]);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (initialLoadComplete() && currentPage !== 1) {
      if (haveLearningMaterials()) {
        setCachedLearningMaterials(cachedLearningMaterials.concat(learningMaterials));
      }
    }

    window.location.replace(window.location.pathname + "#" + selectedInterestPrefix + selectedInterests.join("&" + selectedInterestPrefix));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, learningMaterialsLoading, selectedInterests]);

  if (learningMaterialsError) {
    return (
      <>
        <PageTitle title={`Error-${pageTitle}`} />
        <CurvedTopBanner>
          <PageHeader title={t<string>(`resources.libraries.${category.path}.title`)} backRoute={AppRoutes.resources} />
        </CurvedTopBanner>
        <ViewStateWrapper
          loading={false}
          error={!!learningMaterialsError}
          errorMessage={t<string>(`resources.libraries.${category.path}.error`)}
          onRetry={refreshLearningMaterials}
          isFullScreenView={true}
        />
      </>
    );
  }

  return (
    <>
      <PageTitle title={pageTitle} />
      <CurvedTopBanner>
        <PageHeader title={t<string>(`resources.libraries.${category.path}.title`)} backRoute={AppRoutes.resources} />
      </CurvedTopBanner>

      <div className={classNames("mb-2", { "col-span-full": hideInterests, "flex justify-between items-center": !hideInterests })}>
        <p role="doc-subtitle">{t<string>(`resources.libraries.${category.path}.description`)}</p>
        <Hideable hidden={hideInterests}>
          <ArticleFilterMenu
            preSelectedFilters={selectedInterests}
            onApply={(filters) => {
              setSelectedInterests(filters);
              resetPagination();
            }}
          />
        </Hideable>
      </div>

      <div className="col-span-full">
        <InfiniteScroll
          dataLength={cachedLearningMaterials.length}
          next={() => setCurrentPage(currentPage + 1)}
          hasMore={nextPageLearningMaterials.length > 0}
          loader={learningMaterialsLoading && <ViewLoading heightClass="col-span-full h-[100px]" />}
          endMessage={<></>}
          className="grid grid-cols-1 md:grid-cols-2 md:gap-x-[24px]"
        >
          {cachedLearningMaterials?.map((item) => {
            return <LearningMaterialCard key={item.id} item={item} navigateBasePath={`/resources/${category.path}/`} />;
          })}
        </InfiniteScroll>
      </div>
    </>
  );
};

ArticleCategoryLibrary.defaultProps = { hideInterests: false };
export default ArticleCategoryLibrary;
