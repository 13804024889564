import { t } from "i18next";
import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";
import { Mood, moodBgColour, moodDescription, moodFace } from "UIPalette/MoodTrackerCard/moodFactories";
import { Icon } from "UIPalette/Icon/Icon";
import { ReactComponent as RightChevron } from "shared/assets/Icons/right.svg";
import { ReactComponent as MoreCircle } from "shared/assets/Icons/more-circle.svg";
import { useNavigate } from "react-router-dom";
import { ContextMenu, ContextMenuListItem } from "@brighthr/component-contextmenu";
import "shared/extensions/date";
import { useTrackerContext } from "core/state/Trackers/TrackerContext";
import { TrackerType } from "core/state/Trackers/TrackerType";
import { MoodTrackerHealthHubId } from "UIPalette/MoodTrackerCard/MoodTrackerCard";
import { ViewStateWrapper } from "UIPalette/ViewStateWrapper/ViewStateWrapper";

export const TodaysMoodCard = () => {
  const {
    getCurrentValue,
    clearCurrentValue,
    trackerValuesLoading: isLoading,
    trackerValuesError: hasError,
    refreshTrackerValues,
  } = useTrackerContext();
  const navigate = useNavigate();

  const currentValue = getCurrentValue(TrackerType.Mood);
  const currentMood = currentValue as Mood;

  return (
    <RoundedCard id={MoodTrackerHealthHubId}>
      <ViewStateWrapper loading={isLoading && !hasError} error={hasError && currentValue === 0} onRetry={refreshTrackerValues}></ViewStateWrapper>
      <h4 className="flex flex-row">
        <span className="flex-grow">{t("home.moodTracker.todaysMoodTitle")}</span>
        <ContextMenu
          menuPosition="left"
          contextName="actions"
          aria-label={t("trackers.actionsMenu")}
          customTriggerLayout={<MoreCircle height="20" width="20" />}
        >
          <ContextMenuListItem
            className="[&_span]:text-[14pt] [&_span]:font-bold py-2 pl-2"
            text={t("trackers.deleteLastRecord")}
            onClick={() => clearCurrentValue(TrackerType.Mood)}
          />
        </ContextMenu>
      </h4>
      <div className="flex flex-row items-center">
        <Icon icon={moodFace(currentMood)} hasDropShadow={false} bgColour={moodBgColour(currentMood)} />
        <p role="paragraph" className="ml-2 grow">
          {moodDescription(currentMood)}
        </p>
        <button
          aria-label={t("home.moodTracker.toHealthHubButtonText")}
          className="text-grey mx-2"
          onClick={() => navigate(`/health-hub#${MoodTrackerHealthHubId}`)}
        >
          <RightChevron height="24" width="12" />
        </button>
      </div>
    </RoundedCard>
  );
};
