import { Route, Routes } from "react-router-dom";

import ArticleCategoryLibrary from "./views/ArticleCategoryLibrary/ArticleCategoryLibrary";
import { BreathingExercises } from "./views/BreathingExercises/BreathingExercises";
import { CompleteMiniHealthCheck } from "./views/CompleteMiniHealthCheck/CompleteMiniHealthCheck";
import { FourWeekPlans } from "./views/FourWeekPlans/FourWeekPlans";
import { MiniHealthChecks } from "./views/MiniHealthChecks/MiniHealthChecks";
import { ProtectedModuleRoute } from "core/auth/ProtectedModuleRoute";
import { ResourcesHome } from "./views/ResourcesHome/ResourcesHome";
import { ViewArticle } from "./views/ViewArticle/ViewArticle";
import { ViewFourWeekPlan } from "./views/ViewFourWeekPlan/ViewFourWeekPlan";
import { WellKnownLearningMaterialCategories } from "UIPalette/LearningMaterials/wellKnownLearningMaterials";
import WisdomModules from "core/Modules";
import { ukLocale } from "shared/core/locale";
import { useUserContext } from "core/state/UserContext";
import { t } from "i18next";

export const Resources = () => {
  const { user } = useUserContext();
  return (
    <Routes>
      <Route index element={<ResourcesHome />} />
      <Route element={<ProtectedModuleRoute wisdomModule={WisdomModules.fourWeekPlan} />}>
        <Route path="4-week-plans" element={<FourWeekPlans />} />
        <Route path="4-week-plans/:fourWeekPlanId" element={<ViewFourWeekPlan />} />
      </Route>
      <Route element={<ProtectedModuleRoute wisdomModule={WisdomModules.miniHealthChecks} />}>
        <Route path="mini-health-checks" element={<MiniHealthChecks />} />
        <Route path="mini-health-checks/:miniHealthCheckId" element={<CompleteMiniHealthCheck />} />
      </Route>
      <Route path="breathing-exercises" element={<BreathingExercises />} />
      {user?.localeId === ukLocale && (
        <>
          <Route
            path="bright-tv"
            element={
              <ArticleCategoryLibrary
                category={WellKnownLearningMaterialCategories.brightTV}
                hideInterests={true}
                pageTitle={t("pageTitle.resources.brightTV.home")}
              />
            }
          />
          <Route path="bright-tv/:articleId" element={<ViewArticle pageTitle="pageTitle.resources.brightTV.article" />} />
          <Route
            path="podcasts"
            element={
              <ArticleCategoryLibrary category={WellKnownLearningMaterialCategories.podcasts} pageTitle={t("pageTitle.resources.podcasts.home")} />
            }
          />
          <Route path="podcasts/:articleId" element={<ViewArticle pageTitle="pageTitle.resources.podcasts.article" />} />
        </>
      )}
      <Route
        path="audio-and-video"
        element={
          <ArticleCategoryLibrary
            category={WellKnownLearningMaterialCategories.audioAndVideo}
            pageTitle={t("pageTitle.resources.audioAndVideoArticles.home")}
          />
        }
      />
      <Route path="audio-and-video/:articleId" element={<ViewArticle pageTitle="pageTitle.resources.audioAndVideoArticles.article" />} />
      <Route
        path="articles"
        element={
          <ArticleCategoryLibrary category={WellKnownLearningMaterialCategories.articles} pageTitle={t("pageTitle.resources.articles.home")} />
        }
      />
      <Route path="articles/:articleId" element={<ViewArticle pageTitle="pageTitle.resources.articles.article" />} />
      <Route
        path="newsletters"
        element={
          <ArticleCategoryLibrary
            category={WellKnownLearningMaterialCategories.newsletters}
            hideInterests={true}
            pageTitle={t("pageTitle.resources.newsletters.home")}
          />
        }
      />
      <Route path="newsletters/:articleId" element={<ViewArticle pageTitle="pageTitle.resources.newsletters.article" />} />
      <Route path="/:articleId" element={<ViewArticle pageTitle="pageTitle.resources.article" />} />
    </Routes>
  );
};
