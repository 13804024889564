import { User } from "oidc-client-ts";
import "../types/authStorage";

export abstract class AccessTokenState {
  public static get(): string | undefined {
    const authority = process.env.REACT_APP_AUTH_AUTHORITY;
    const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
    const userOidcStorageString = window.authStorage?.getItem(`oidc.user:${authority}:${clientId}`);
    if (!!userOidcStorageString) {
      const user = User.fromStorageString(userOidcStorageString);
      return user.access_token;
    }
    return undefined;
  }

  public static getUser() {
    const authority = process.env.REACT_APP_AUTH_AUTHORITY;
    const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
    const userOidcStorageString = window.authStorage?.getItem(`oidc.user:${authority}:${clientId}`);
    if (!!userOidcStorageString) {
      const user = User.fromStorageString(userOidcStorageString);
      return user;
    }
    return undefined;
  }
}
