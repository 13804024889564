import { Navigate, Route, Routes } from "react-router-dom";

import { Leaderboard } from "./views/Display/Leaderboard";
import { LeaderboardCreate } from "./views/Create/LeaderboardCreate";
import { LeaderboardJoin } from "./views/Join/LeaderboardJoin";
import { LeaderboardsHome } from "./views/Home/LeaderboardsHome";

export const Leaderboards = () => {
  return (
    <Routes>
      <Route index element={<LeaderboardsHome />} />
      <Route path="create" element={<LeaderboardCreate />} />
      <Route path=":code" element={<Leaderboard />} />
      <Route path="preview" element={<Navigate replace to="/leaderboards" />} />
      <Route path="preview/:code" element={<LeaderboardJoin />} />
    </Routes>
  );
};
