import { TrackerType } from "core/state/Trackers/TrackerType";
import { TrackerProgressCard } from "../../../components/TrackerProgressCard";
import { ReactComponent as Sleep } from "shared/assets/Icons/sleep.svg";
import { useTranslation } from "react-i18next";
import { useTrackerContext } from "core/state/Trackers/TrackerContext";
import { convertMinutesToHours } from "core/helpers/time";
import { TrackerThresholdPercentages } from "core/state/Trackers/TrackerThresholdPercentages";

export const SleepTrackerProgressCard = () => {
  const { t } = useTranslation();
  const { getGoal, getCurrentValue } = useTrackerContext();
  const goal = getGoal(TrackerType.Sleep);
  const current = convertMinutesToHours(getCurrentValue(TrackerType.Sleep));
  const percent = current.percentageOf(goal, 0);

  function getProgressMessage(percentage: number): string {
    switch (true) {
      case percentage >= 100:
        return t(`healthHub.trackers.progress.complete`);
      case percentage > TrackerThresholdPercentages.High:
        return t("healthHub.trackers.sleep.progress.high", { goal, current, percent });
      case percentage >= TrackerThresholdPercentages.Medium:
        return t("healthHub.trackers.sleep.progress.medium", { goal });
      default:
        return t("healthHub.trackers.sleep.progress.low", { goal });
    }
  }

  return (
    <TrackerProgressCard
      trackerType={TrackerType.Sleep}
      percentage={percent}
      heading={t("healthHub.trackers.sleep.progress.heading")}
      goalHeading={t("healthHub.trackers.sleep.goal.heading")}
      goalDescription={t("healthHub.trackers.sleep.goal.description")}
      goalIncrement={1}
      goalUnit={t("healthHub.trackers.sleep.goal.unit")}
      progressMessage={getProgressMessage(percent)}
      Icon={Sleep}
    />
  );
};
