import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "shared/UI/Inputs/Select/Select";
import { IntegerInput } from "shared/UI/Inputs/IntegerInput/IntegerInput";
import { ValueCallback } from "shared/core/types/callbacks";

export enum ActivityType {
  physical,
  mental,
}

export type ActivityEntry = {
  name: string;
  duration: number;
};

export const GeneralActivitySelector = ({
  activityEntry,
  onActivityChanged,
}: {
  activityEntry: ActivityEntry;
  onActivityChanged: ValueCallback<ActivityEntry>;
}) => {
  const { t } = useTranslation();
  const activities = useMemo(() => {
    const physicalActivities = t("healthHub.trackers.activity.addData.physicalActivities", { returnObjects: true });
    const mentalActivities = t("healthHub.trackers.activity.addData.mentalActivities", { returnObjects: true });

    return {
      [ActivityType.physical]: Object.values(physicalActivities),
      [ActivityType.mental]: Object.values(mentalActivities),
    };
  }, [t]);

  return (
    <div className="flex flex-row w-full gap-x-[4px] mb-1">
      <Select
        value={activityEntry.name}
        label={t("healthHub.trackers.activity.addData.activityLabel")}
        onChange={(event) => onActivityChanged({ name: event.target.value, duration: activityEntry.duration })}
      >
        <optgroup label={t("healthHub.trackers.activity.addData.mentalActivityType")}>
          {activities[ActivityType.mental].map((activity, index) => (
            <option key={`mental-${activity}-${index}`} value={activity}>
              {activity}
            </option>
          ))}
        </optgroup>
        <optgroup label={t("healthHub.trackers.activity.addData.physicalActivityType")}>
          {activities[ActivityType.physical].map((activity, index) => (
            <option key={`physical-${activity}-${index}`} value={activity}>
              {activity}
            </option>
          ))}
        </optgroup>
      </Select>
      <IntegerInput
        maxLength={3}
        label={t("healthHub.trackers.activity.addData.durationLabel")}
        suffix={t("healthHub.trackers.activity.addData.minutes")}
        defaultValue={activityEntry.duration.toString()}
        onChange={(e) => {
          onActivityChanged({ name: activityEntry.name, duration: Number(e.target.value) });
        }}
      />
    </div>
  );
};
