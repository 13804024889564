import { RoundedCard } from "shared/UI/RoundedCard/RoundedCard";
import { BreathingExercise } from "shared/request/myHealthyAdvantageApi";
import { Circles } from "../Circles/Circles";
import { StructuredText, StructuredTextDocument } from "react-datocms";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import StylableButton from "shared/UI/Buttons/StylableButton";

export type BreathingExerciseCardProps = {
  exercise: BreathingExercise;
  onStartExercise: (exercise: BreathingExercise, duration: number) => void;
};

export const BreathingExerciseCard = ({ exercise, onStartExercise }: BreathingExerciseCardProps) => {
  const { t } = useTranslation();
  const [duration, setDuration] = useState(3);
  return (
    <RoundedCard className="flex flex-col items-center h-full justify-around text-center">
      <div className="flex flex-col items-center">
        <Circles baseColour={exercise.animationColour.hex} className="w-20 pb-2" />
        <p className="text-xl pb-2">{exercise.name}</p>
      </div>

      <StructuredText data={exercise.textIntro as StructuredTextDocument} />

      <div className="px-10 w-full flex justify-between items-center text-center mt-auto ">
        <StylableButton
          className="rounded-button"
          text="&#8212;"
          color="primary"
          aria-label={t("breathingExercises.decreaseExerciseDuration")}
          onClick={() => {
            setDuration(Math.max(duration - 1, 1));
          }}
        />
        <span className="whitespace-pre-line">{t("breathingExercises.durationLabel", { duration, count: duration })}</span>
        <StylableButton
          className="rounded-button"
          text="+"
          color="primary"
          aria-label={t("breathingExercises.increaseExerciseDuration")}
          onClick={() => {
            setDuration(Math.min(duration + 1, 30));
          }}
        />
      </div>
      <StylableButton
        className="block w-full h-[4rem] items-end flex"
        fullWidth
        text={t("breathingExercises.start")}
        color="primary"
        onClick={() => onStartExercise(exercise, duration)}
      />
    </RoundedCard>
  );
};
