import React from "react";
import { RoundedCard } from "../RoundedCard/RoundedCard";
import { Hideable } from "../Hideable/Hideable";
import ClientLogoImage from "./ClientLogoImage";

export type ClientLogoProps = React.HTMLAttributes<HTMLDivElement> & {
  clientCode: string;
  imageFilename?: string;
  alt: string;
  imgClassNames?: string;
};

export const ClientLogo = ({ clientCode, imageFilename, alt, imgClassNames, ...props }: ClientLogoProps) => {
  return (
    <Hideable hidden={!imageFilename || imageFilename.length === 0}>
      <div {...props}>
        <RoundedCard>
          <ClientLogoImage className={imgClassNames} clientCode={clientCode} imageFilename={imageFilename!} alt={alt} />
        </RoundedCard>
      </div>
    </Hideable>
  );
};
