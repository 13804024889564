import { t } from "i18next";
import { ReactComponent as AccountIcon } from "shared/assets/Icons/account.svg";
import { ReactComponent as FilledAccountIcon } from "shared/assets/Icons/account-filled.svg";
import { useNavigate } from "react-router-dom";

export const BottomNavMyAccountButton = ({ active }: { active: boolean }) => {
  const navigateHook = useNavigate();

  return (
    <button
      className="flex flex-col items-center text-brand font-bold text-[14pt] mx-1"
      aria-label={t("navigation.accountNavigationLabel")}
      onClick={() => {
        navigateHook("/account");
      }}
    >
      {active ? <FilledAccountIcon /> : <AccountIcon />}
      <p className="mt-1">{t("navigation.accountButton")}</p>
    </button>
  );
};
