import { ComponentType, PropsWithChildren, SVGProps } from "react";

import { Donut } from "UIPalette/Donut/Donut";
import { TrackerProgressTileProps } from "../../Views/HealthHub/components/TrackerProgressTile";

export type TrackerProgressProps = {
  percentage: number;
  size: "sm" | "md" | "lg";
  TrackerIcon: ComponentType<SVGProps<SVGSVGElement>>;
};

export type TrackerProgressPropsWithChildren = PropsWithChildren & {
  percentage: number;
  size?: TrackerProgressProps["size"];
};

type SizeProperties = {
  [K in Exclude<TrackerProgressTileProps["size"], undefined>]: {
    thickness?: number;
  };
};

const sizeProperties: SizeProperties = {
  lg: {},
  sm: {},
  md: {
    thickness: 2,
  },
};

export const TrackerProgress = ({ children, percentage, size = "lg" }: TrackerProgressPropsWithChildren) => {
  return (
    <Donut percentage={percentage} thickness={sizeProperties[size].thickness}>
      {children}
    </Donut>
  );
};

export const TrackerProgressDonut = ({ percentage, size, TrackerIcon }: TrackerProgressProps) => {
  return (
    <TrackerProgress percentage={percentage} size={size}>
      <TrackerIcon width={"100%"} height={"100%"} className="text-brand-aa" />
    </TrackerProgress>
  );
};
