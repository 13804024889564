import { t } from "i18next";
import { VerySadFace, SadFace, NeutralFace, HappyFace, VeryHappyFace } from "./Faces";

export const Mood = {
  VerySad: 1,
  Sad: 2,
  Neutral: 3,
  Happy: 4,
  VeryHappy: 5,
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Mood = (typeof Mood)[keyof typeof Mood];

export const moodDescription = (mood: Mood): string => {
  switch (mood) {
    case 1:
      return t("home.moodTracker.moods.verySad");
    case 2:
      return t("home.moodTracker.moods.sad");
    case 3:
      return t("home.moodTracker.moods.neutral");
    case 4:
      return t("home.moodTracker.moods.happy");
    case 5:
      return t("home.moodTracker.moods.veryHappy");
  }
};

export const moodFace = (mood: Mood): JSX.Element => {
  switch (mood) {
    case 1:
      return <VerySadFace />;
    case 2:
      return <SadFace />;
    case 3:
      return <NeutralFace />;
    case 4:
      return <HappyFace />;
    case 5:
      return <VeryHappyFace />;
  }
};

export const moodBgColour = (mood: Mood): string => {
  switch (mood) {
    case 1:
      return "bg-l-red";
    case 2:
      return "bg-l-red";
    case 3:
      return "bg-l-yellow";
    case 4:
      return "bg-l-green";
    case 5:
      return "bg-l-green";
  }
};
